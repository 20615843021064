import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

export const useMemberCrud = () => {
  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const inviteMember = useMutation(communitiesClient.inviteMember, {
    mutationKey: [cacheKeys.inviteCommunityMember],
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getCommunity, variables.handle]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: variables.handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getCommunity, variables.communityId.toString()]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: variables.communityId.toString(),
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const removeMember = useMutation(communitiesClient.removeMember, {
    mutationKey: [cacheKeys.removeCommunityMember],
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getCommunity, variables.handle]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: variables.handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getCommunity, variables.communityId.toString()]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: variables.communityId.toString(),
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const promoteCommunityMemberToAdmin = useMutation((data: Communities.PromoteMember) => communitiesClient.promoteMember(data), {
    mutationKey: [cacheKeys.promoteCommunityMemberToAdmin],
    onSuccess: (_, { communityId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: communityId,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const demoteCommunityMemberFromAdmin = useMutation((data: Communities.DemoteMember) => communitiesClient.demoteMember(data), {
    mutationKey: [cacheKeys.demoteCommunityMemberFromAdmin],
    onSuccess: (_, { communityId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const approveCommunityJoinRequest = useMutation((data: Communities.CommunityJoinRequest) => communitiesClient.approveCommunityJoinRequest(data), {
    mutationKey: [cacheKeys.approveCommunityJoinRequest],
    onSuccess: (_, { communityId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const refuseCommunityJoinRequest = useMutation((data: Communities.CommunityJoinRequest) => communitiesClient.refuseCommunityJoinRequest(data), {
    mutationKey: [cacheKeys.refuseCommunityJoinRequest],
    onSuccess: (_, { communityId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    inviteMember: inviteMember.mutateAsync,
    removeMember: removeMember.mutateAsync,
    refuseCommunityJoinRequest: refuseCommunityJoinRequest.mutateAsync,
    approveCommunityJoinRequest: approveCommunityJoinRequest.mutateAsync,
    promoteCommunityMemberToAdmin: promoteCommunityMemberToAdmin.mutateAsync,
    demoteCommunityMemberFromAdmin: demoteCommunityMemberFromAdmin.mutateAsync,
  };
};
