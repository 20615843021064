import { QueryClientConfig } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 360000, // 6 minutes
      onError(error) {
        Sentry.captureException(error);
        return false;
      },
    },
    mutations: {
      onError(error) {
        Sentry.captureException(error);
        return false;
      },
    },
  },
};
