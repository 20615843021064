import { LicenseInfo } from '@mui/x-license';

import { App as CVTApp } from '@cvt/App';

import { AuthProvider } from '@modules/Auth/contexts';
import { UserProvider } from '@modules/Users/contexts';
import { TalkSession } from '@modules/Messaging/components/TalkSession';

import { LayoutContextProvider } from '@shared/contexts/LayoutContext/LayoutContext';
import { AppUrlListener } from '@shared/components/AppUrlListener';
import { MainLayout } from '@shared/components/MainLayout';
import { Dialogs } from '@shared/components/Dialogs';

import { Root } from '@pages/Root';
import { ContactsProvider } from '@modules/Contacts/contexts/ContactsContext';

try {
  if (import.meta.env.VITE__CVT_MUI_PRO_LICENSE) {
    LicenseInfo.setLicenseKey(import.meta.env.VITE__CVT_MUI_PRO_LICENSE);
  }
} catch (err) {
  console.log(err);
}

const App = () => {
  return (
    <CVTApp>
      <AuthProvider>
        <UserProvider>
          <ContactsProvider>
            <TalkSession>
              <LayoutContextProvider>
                <MainLayout>
                  <AppUrlListener/>
                  <Root />
                  <Dialogs />
                </MainLayout>
              </LayoutContextProvider>
            </TalkSession>
          </ContactsProvider>
        </UserProvider>
      </AuthProvider>
    </CVTApp>
  );
};

export default App;
