import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { useRouter } from '@cvt/hooks/useRouter';

import { useUserEvents } from '@modules/Events/hooks/useUserEvents';

import { EventCard, EventCardSkeleton } from '../components/EventCard';

export const DashboardEvents: React.FC = () => {
  const router = useRouter();

  const { userEvents, status } = useUserEvents();

  React.useEffect(() => {
    if (status === 'success' && userEvents.length < 10) {
      router.dashboard.events.swipe.go({}, true);
    }
  }, [status, userEvents, router]);

  if (status === 'loading') {
    return <EventCardSkeleton/>;
  }

  if (status === 'success' && !userEvents.length) {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Stack spacing={2} height="100%">
          {userEvents.map((event) => (
            <Box onClick={() => router.dashboard.events.swipe.go({ eventId: event.id })}>
              <EventCard key={event.id} event={event} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Container>
  );
};
