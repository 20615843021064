import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { camelizeObject } from '@cvt/helpers/object';

const locationsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;
const ipApiBaseUrl = import.meta.env.VITE__CVT_IP_LOCATION_API_URL;

const searchAddress = (q: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${locationsApiBaseUrl}/address-search/`,
      method: 'GET',
      params: {
        q,
      },
    },
  });
};

const getLocation = (params: { id: string | number }): Promise<AxiosResponse<Locations.Location>> => {
  return request({
    options: {
      url: `${locationsApiBaseUrl}/locations/${params.id}`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Locations.LocationApi>) => ({
    ...data,
    data: camelizeObject<Locations.Location>(data.data),
  }));
};

const getIpAddressGeoLocation = (): Promise<AxiosResponse<Locations.IPLocation>> => {
  return request({
    authenticate: false,
    options: {
      url: `${ipApiBaseUrl}/json`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Locations.IPLocationApi[]>) => ({
    ...data,
    data: camelizeObject<Locations.IPLocation>(data.data),
  }));
};

export const locationClient = {
  searchAddress,
  getIpAddressGeoLocation,
  getLocation,
};
