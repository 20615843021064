import React from 'react';
import noop from 'lodash/noop';
import { Chatbox } from '@talkjs/react';
import {
  Box,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
  useTheme,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { useRouter } from '@cvt/hooks/useRouter';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { useChatCrud } from '@modules/Messaging/hooks/useChatCrud';
import { useUser } from '@modules/Users/hooks/useUser';

import { UserAvatar } from '../components/UserAvatar';


type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    userId: string;
    onComplete?: () => void;
  }
}

export const UserChatDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const router = useRouter();
  const theme = useTheme();

  const [chatId, setChatId] = React.useState('');

  const { status, user } = useUser({
    id: data?.userId || '',
  }, {
    enabled: !!data?.userId,
    retry: false,
  });
  const { createChat } = useChatCrud();

  const initChat = React.useCallback(async () => {
    if (user) {
      const response = await createChat({
        users: [user.id],
      });

      setChatId(response.data.id);
    }
  }, [createChat, user]);

  const _onClose = React.useCallback(() => {
    onClose();
    data?.onComplete && data.onComplete();
  }, [onClose, data]);

  React.useEffect(() => {
    setChatId('');
  }, [open]);

  React.useEffect(() => {
    if (!!user && !chatId) {
      initChat();
    }
  }, [chatId, initChat, user]);

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={_onClose}
      PaperProps={{
        sx: theme => ({
          height: '100%',
          pb: 'env(safe-area-inset-bottom)',
          maxHeight: 'calc(100% - env(safe-area-inset-top))',
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      {status === 'loading' || !user ? (
        <BodyLoading />
      ) : (
        <React.Fragment>
          <Box
            position="absolute"
            top={8}
            left="50%"
            width={40}
            height={3}
            borderRadius={1}
            bgcolor="common.white"
            sx={{
              transform: 'translate(-50%)',
            }}
          />
          <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="center" sx={{ paddingLeft: 1 }}>
            <Box flex={1}>
              <IconButton
                aria-label="close"
                onClick={_onClose}
                color="secondary"
              >
                <Close/>
              </IconButton>
            </Box>
            <Stack width="100%" direction="row" alignItems="center" spacing={1} overflow="hidden">
              <UserAvatar user={user} />
              <Typography variant="h4" component="h1" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{user.displayName}</Typography>
            </Stack>
            <Box flex={1} textAlign="right">
            </Box>
          </DialogTitle>
          {chatId && (
            <Paper component={Box} flex="1 1 auto" width="100%" height="100%">
              <Chatbox
                theme={theme.palette.mode === 'light' ? 'default' : 'default_dark'}
                conversationId={chatId}
                showChatHeader={false}
                loadingComponent={<BodyLoading height="100%" />}
                style={{ width: '100%', height: '100%' }}
                onCustomMessageAction={event => {
                  if (event.action === 'goToProfile') {
                    router.users.view(event.params.userId).go();
                  }
                }}
              />
            </Paper>
          )}
        </React.Fragment>
      )}
    </SwipeableDrawer>
  );
};