import { AxiosResponse } from 'axios';
import { endOfDay } from 'date-fns';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';

import { mapCommunityCrudData, mapCommunityData, mapCommunityChannelData, mapExtendedCommunityUserData } from './communityClient.formatter';

const communitiesApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getCommunity = (params: { id: number | string }): Promise<AxiosResponse<Communities.Community>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Communities.CommunityApi>) => ({
    ...data,
    data: mapCommunityData(data.data),
  }));
};

const getCommunities = (params: Communities.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Communities.Community>>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Communities.CommunityApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapCommunityData),
    },
  }));
};

const createCommunity = (data: Communities.Create): Promise<AxiosResponse<Communities.Community>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${communitiesApiBaseUrl}/communities/`,
      method: 'POST',
      data: mapCommunityCrudData(data),
    },
  }).then((data: AxiosResponse<Communities.CommunityApi>) => ({
    ...data,
    data: mapCommunityData(data.data),
  }));
};

const editCommunity = (data: Communities.Edit): Promise<AxiosResponse<Communities.Community>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${communitiesApiBaseUrl}/communities/${data.id}/`,
      method: 'PUT',
      data: mapCommunityCrudData(data),
    },
  }).then((data: AxiosResponse<Communities.CommunityApi>) => ({
    ...data,
    data: mapCommunityData(data.data),
  }));
};

const deleteCommunity = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const joinCommunity = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.id}/join/`,
      method: 'POST',
    },
  });
};

const getCommunityJoinRequests = (params: { communityId: number | string }): Promise<AxiosResponse<Communities.JoinCommunityRequest[]>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.communityId}/community-join-requests/`,
      method: 'GET',
    },
  });
};

const approveCommunityJoinRequest = ({ membershipId, communityId }: Communities.CommunityJoinRequest): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/approve-community-join-request/`,
      method: 'POST',
      data: {
        membership: membershipId,
      },
    },
  });
};

const refuseCommunityJoinRequest = ({ membershipId, communityId }: Communities.CommunityJoinRequest): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/reject-community-join-request/`,
      method: 'POST',
      data: {
        membership: membershipId,
      },
    },
  });
};

const leaveCommunity = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.id}/leave/`,
      method: 'POST',
    },
  });
};

const getMembers = ({ communityId, ...params }: Communities.GetMembersParams): Promise<AxiosResponse<Communities.ExtendedCommunityUser[]>> => {
  const now = new Date();
  const eod = endOfDay(now);
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/users/`,
      method: 'GET',
      params: {
        ...snakizeObject(params),
        date_gte: params.dateGte ? params.dateGte.toISOString() : now.toISOString(),
        date_lte: params.dateLte ? params.dateLte.toISOString() : eod.toISOString(),
      },
    },
  }).then((data: AxiosResponse<Communities.ExtendedCommunityUserApi[]>) => ({
    ...data,
    data: data.data.map(mapExtendedCommunityUserData),
  }));
};

const inviteMember = ({ communityId, userId }: Communities.InviteUser): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/invite/`,
      method: 'POST',
      data: snakizeObject({ userId }),
    },
  });
};

const removeMember = ({ communityId, ...data }: Communities.InviteUser): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/invite/`,
      method: 'DELETE',
      data: snakizeObject(data),
    },
  });
};

const promoteMember = ({ communityId, user }: Communities.PromoteMember): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/promote-to-admin/`,
      method: 'POST',
      data: snakizeObject({ user }),
    },
  });
};

const demoteMember = ({ communityId, user }: Communities.DemoteMember): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/demote-from-admin/`,
      method: 'POST',
      data: snakizeObject({ user }),
    },
  });
};

const getChannels = ({ communityId, ...params }: Communities.Channels.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Communities.Channels.Channel>>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/community-channels/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<Communities.Channels.ChannelApi[]>) => ({
    ...data,
    data: {
      ...data.data,
      count: data.data.length,
      results: data.data.map(mapCommunityChannelData),
      next: null,
      previous: null,
    },
  }));
};

const getChannel = (params: { communityId: number | string; id: number | string }): Promise<AxiosResponse<Communities.Channels.Channel>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${params.communityId}/community-channels/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Communities.Channels.ChannelApi>) => ({
    ...data,
    data: mapCommunityChannelData(data.data),
  }));
};

const createChannel = (communityId: number | string, data: Communities.Channels.Create): Promise<AxiosResponse<Communities.Channels.Channel>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/create-channel/`,
      method: 'POST',
      data: {
        channel_name: data.name,
        private: data.private,
        event: data.event,
      },
    },
  }).then((data: AxiosResponse<Communities.Channels.ChannelApi>) => ({
    ...data,
    data: mapCommunityChannelData(data.data),
  }));
};

const deleteChannel = (channelId: string, communityId: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/delete-channel/`,
      method: 'POST',
      data: {
        id: channelId,
      },
    },
  });
};

const joinChannel = (communityId: number | string, channelId: string): Promise<unknown> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/join-channel/`,
      method: 'POST',
      data: {
        uuid: channelId,
      },
    },
  });
};

const leaveChannel = (communityId: number | string, channelId: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/leave-channel/`,
      method: 'POST',
      data: {
        uuid: channelId,
      },
    },
  });
};


const editChannel = (data: Communities.Channels.Edit): Promise<AxiosResponse<Communities.Channels.Channel>> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${data.communityId}/community-channels/${data.id}/`,
      method: 'POST',
      data: {
        name: data.name,
        event: data.event,
        private: data.private,
      },
    },
  }).then((data: AxiosResponse<Communities.Channels.ChannelApi>) => ({
    ...data,
    data: mapCommunityChannelData(data.data),
  }));
};

const acceptChannelMemberRequest = ({ id, communityId }: Communities.Channels.ChannelMemberEdit): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/accept-in-private-channel/`,
      method: 'POST',
      data: {
        channel_membership: id,
      },
    },
  });
};

const refuseChannelMemberRequest = ({ id, communityId }: Communities.Channels.ChannelMemberEdit): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/reject-from-private-channel/`,
      method: 'POST',
      data: {
        channel_membership: id,
      },
    },
  });
};

const removeChannelMember = ({ id, communityId }: Communities.Channels.ChannelMemberEdit): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/remove-from-channel/`,
      method: 'POST',
      data: {
        channel_membership: id,
      },
    },
  });
};

const inviteChannelMember = ({ channelId, communityId, userId }: Communities.Channels.InviteUser): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/add-to-channel/`,
      method: 'POST',
      data: snakizeObject({
        user: userId,
        channel: channelId,
      }),
    },
  });
};

const toggleChannelNotifications = (communityId: string, channelId: string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${communitiesApiBaseUrl}/communities/${communityId}/community-channels/${channelId}/mute/`,
      method: 'POST',
    },
  });
};

export const communitiesClient = {
  getCommunity,
  getCommunities,
  createCommunity,
  editCommunity,
  deleteCommunity,
  joinCommunity,
  getCommunityJoinRequests,
  approveCommunityJoinRequest,
  refuseCommunityJoinRequest,
  leaveCommunity,
  getMembers,
  inviteMember,
  removeMember,
  getChannels,
  getChannel,
  createChannel,
  deleteChannel,
  joinChannel,
  leaveChannel,
  promoteMember,
  demoteMember,
  editChannel,
  acceptChannelMemberRequest,
  refuseChannelMemberRequest,
  removeChannelMember,
  inviteChannelMember,
  toggleChannelNotifications,
};
