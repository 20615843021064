import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDictionary } from '@cvt/hooks/useDictionary';

export type FormCrud = Communities.Channels.Crud & {
  availability: 'private' | 'public';
};

type Fields = keyof (FormCrud & {
  submit: boolean;
  memberActions: boolean;
});


interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: FormCrud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
  community?: Communities.Community
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  name: '',
  availability: 'public',
};


export const ChannelForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields, community }) => {
  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty } } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest({
        ...data,
        private: data.availability === 'private',
      });
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Grid height="100%" container spacing={3}>
        {isFieldVisible('name') && (
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.communityChannel.fieldName}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('name')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('availability') && (
          <Grid item xs={12}>
            <Controller
              name="availability"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <>
                  <InputLabel htmlFor="availability-input">Availability</InputLabel>
                  <Box>
                    <ToggleButtonGroup
                      id="availability-input"
                      value={field.value}
                      onChange={(e, value) => value !== null && field.onChange(value)}
                      fullWidth
                      exclusive
                      size="small"
                      color="secondary"
                    >
                      <ToggleButton value="private">
                        Private
                      </ToggleButton>
                      <ToggleButton value="public">
                        Public
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  {fieldState.error && (
                    <FormHelperText error>{fieldState.error.message}</FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        )}

        {isFieldVisible('submit') && (
          <Grid mt="auto" item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
