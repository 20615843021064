import { Theme } from '@mui/material';

import { blue } from '@shared/theme/palette';

const Input = (theme: Theme) => {
  return {
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
        },
        outlined: {
          '&.Mui-focused': {
            color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.primary.light} !important`,
            borderWidth: 1,
          },
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': `0 0 0 100px ${theme.palette.mode === 'light' ? theme.palette.grey[300] : blue} inset`,
          },
        },
      },
    },
  };
};

export default Input;