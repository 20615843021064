import React from 'react';
import { useDebounce } from 'react-use';
import { Box, DialogContent, DialogTitle, Divider, Drawer, IconButton, InputAdornment, InputLabel, List, ListItemButton, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { Close, Delete, Search } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { UserGroupAvatar } from '@modules/Users/components/UserGroupAvatar';
import { UserAvatar } from '@modules/Users/components/UserAvatar';

import { ParticipantsList } from './ParticipantsList';
import { UserRow, ParticipantRowSkeleton } from './ParticipantRow';

export type User = Pick<Users.User, 'id' | 'firstName' | 'lastName' | 'displayName' | 'profilePicture' | 'profilePictureSizes'>;

export type Props<T extends User> = Omit<TextFieldProps, 'value' |'onChange'> & {
  users: T[];
  onChange: (users: T[]) => void;
  defaultUsers?: T[];
  filters?: Users.GetListParams;
  disableActions?: boolean;
};

export const ParticipantsField = <T extends User> ({ users = [], onChange, defaultUsers = [], label, filters, disableActions, ...props }: Props<T>) => {
  const dictionary = useDictionary();

  const [open, setOpen] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [search, setSearch] = React.useState('');

  useDebounce(
    () => setSearch(inputValue),
    400,
    [inputValue],
  );

  const newUsers = React.useMemo(() => {
    return users.filter(user => !defaultUsers.some(it => it.id === user.id));
  }, [users, defaultUsers]);

  const toggleUser = React.useCallback((user: Users.User, selected: boolean) => {
    let list: T[] = [];

    if (selected) {
      if (!users.some(member => member.id === user.id)) {
        list = [...users, { ...user } as unknown as T];
      }
    } else {
      list = users.filter(member => member.id !== user.id);
    }
    onChange(list);
  }, [users, onChange]);

  React.useEffect(() => {
    const selectedCount = users.length;
    if (selectedCount === 0) {
      setOpen(false);
    }
  }, [users]);

  return (
    <React.Fragment>
      <InputLabel>{label}</InputLabel>
      <TextField
        {...props}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        autoComplete="off"
        InputProps={{
          ...props?.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {newUsers.length > 0 && (
                <IconButton size="small" onClick={() => setOpen(true)}>
                  <UserGroupAvatar users={newUsers} count={newUsers.length} size={30} />
                </IconButton>
              )}
              <Search color="inherit" sx={{ ml: 0.5 }} />
            </InputAdornment>
          ),
        }}
      />
      <Box minHeight={80} pt={1}>
        <ParticipantsList
          renderUser={user => (
            <UserRow
              user={user}
              selected={users.some(member => member.id === user.id)}
              onChange={selected => toggleUser(user, selected)}
            />
          )}
          renderSkeleton={() => <ParticipantRowSkeleton />}
          filters={{
            ...filters,
            exclude: defaultUsers.map(user => user.id),
            search,
          }}
          slotProps={{
            list: {
              disablePadding: true,
            },
            listItem: {
              disableGutters: true,
            },
          }}
        />
      </Box>
      <Drawer
        keepMounted={false}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: theme => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            maxWidth: theme.breakpoints.values.sm,
            maxHeight: '43vh',
            margin: 'auto',
          }),
        }}
      >
        <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">{dictionary.communities.create.selectedMembersTitle}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box pb="env(safe-area-inset-bottom)">
            <List disablePadding>
              {newUsers.map((user) => (
                <React.Fragment key={user.id}>
                  <ListItemButton disableGutters onClick={() => toggleUser(user as unknown as Users.User, false)}>
                    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <UserAvatar user={{ id: user.id, profilePictureSizes: user.profilePictureSizes } as Users.User} sx={{ width: 30, height: 30 }} />
                        <Typography variant="body1" component="span">{user.displayName}</Typography>
                      </Stack>
                      <Delete />
                    </Stack>
                  </ListItemButton>
                  <Divider component="li"/>
                </React.Fragment>
              ))}
            </List>
          </Box>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
};
