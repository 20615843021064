import React from 'react';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';

import { UserContext } from '@modules/Users/contexts';
import { useUserIntegrations } from '@modules/Integrations/hooks/useUserIntegrations';

import { isNative } from '@shared/helpers/environment';

import { CreateTrip } from '../partials/CreateTrip';

type Props = {
  open: boolean;
  onClose: () => void;
}

export const CreateTripDialog: React.FC<Props> = ({ open, onClose }) => {

  const dictionary = useDictionary();
  const router = useRouter();

  const [visible, setVisible] = React.useState(true);

  const { user } = React.useContext(UserContext);

  const { integrations } = useUserIntegrations({
    userId: user?.id || '',
  }, {
    enabled: !!user?.id,
  });

  React.useEffect(() => {
    setVisible(true);
  }, [open]);

  if (!visible) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: `calc(100% - env(safe-area-inset-top) - ${isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(3)})`,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.trips.create.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!integrations?.google && (
          <Box width="100%" component={Paper} bgcolor="background.default" py={1} pl={2} pr={1} mb={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="h5" component="h1">Add trips automatically</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to={router.integrations.path}
                  endIcon={<ArrowForward/>}
                >
                  Connect
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
        <Box pt={1}>
          {/* Need it because SwipeableDrawer always mounts */}
          {
            open && <CreateTrip
              onSuccess={trip => [onClose(), router.home.go({ tab: 'there', trip: trip.id })]}
            />
          }
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
