import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';

import { EditEvent } from '../partials/EditEvent';
import { useEvent } from '../hooks/useEvent';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    event: Events.Event;
    onClose: () => void;
  }
}

export const EditEventDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const dictionary = useDictionary();

  const { event, status } = useEvent({
    id: data?.event.id as number,
  }, {
    enabled: !!data?.event.id,
  });

  const onSuccess = () => {
    onClose();
    data?.onClose && data.onClose();
  };

  if (!data?.event.id) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: `calc(100% - env(safe-area-inset-top) - ${isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(3)})`,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.events.edit.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          {[status].includes('loading') &&  <BodyLoading height={300}/>}
          {[status].every(value => value === 'success') && event && (
            <EditEvent
              event={event}
              onSuccess={onSuccess}
            />
          )}
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
