import React from 'react';
import { useNavigate } from 'react-router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

export const AppUrlListener: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Example url: https://app.com/page/page
      // slug = /page/page
      let slug = null;

      if (event.url.includes(import.meta.env.VITE__CVT_REACT_APP_BASE_URL)) {
        slug = event.url.split(import.meta.env.VITE__CVT_REACT_APP_BASE_URL).pop();
      } else if(event.url.includes('com.there.app')) {
        slug = event.url.split('com.there.app:/').pop();
      } else if(event.url.includes('app://app.thr-app.com')) {
        slug = event.url.split('app://app.thr-app.com:/').pop();
      }

      if (slug) {
        navigate(slug, {
          replace: true,
        });
      }
    });
  }, [navigate]);

  return null;
};
