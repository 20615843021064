import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { useAuthState } from 'react-firebase-hooks/auth';

import { firebaseClient } from '@cvt/clients/firebaseClient';
import { PermissionContext, RoutingContext } from '@cvt/contexts';

import { isBaseLocationSet } from '@modules/Users/helpers/user';

import { initUserTracking } from '@shared/tracking';
import { isNative } from '@shared/helpers/environment';

import { useMe } from '../../hooks/useMe';
import { cacheKeys } from '../../config';

import { ContextProps } from './types';

export const defaultContext: ContextProps = {
  firebaseUser: undefined,
  user: undefined,
  login: () => {},
  impersonate: () => {},
  resetPassword: () => {},
  // @ts-ignore
  ssoLogin: () => {},
  logout: () => {},
  isLoggedIn: false,
  loading: false,
  error: undefined,
  isUserVerified: false,
};

export const AuthContext = React.createContext(defaultContext);

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

  const { resetPermissions } = React.useContext(PermissionContext);

  const [firebaseUser, loading, error] = useAuthState(firebaseClient.getAuth());
  const isUserVerified = React.useMemo(() => !!firebaseUser?.phoneNumber, [firebaseUser?.phoneNumber]);

  const queryClient = useQueryClient();

  const { router, location } = React.useContext(RoutingContext);

  const { user, status } = useMe({
    enabled: !!firebaseUser && !firebaseUser.isAnonymous,
  });

  const isLoggedIn = React.useMemo(() => !!firebaseUser, [firebaseUser]);

  const isPWA = React.useMemo(() => {
    return window.hasOwnProperty('matchMedia') && window?.matchMedia('(display-mode: standalone)').matches;
  }, []);

  React.useEffect(() => {
    const isOnOnboardingPage = (location?.pathname || '').includes(router.onboarding.path);
    const isCommunityPage = (location?.pathname || '').includes(router.communities.path);

    if (!!user) {
      if ((user.onboarded && !isUserVerified)) {
        router.phoneVerification.go({ isRequiredVerification: true });
      } else if ((!isBaseLocationSet(user) || !user.onboarded) && !isOnOnboardingPage && !isCommunityPage) {
        router.onboarding.step('1').go({}, true);
      }
    }
  }, [router, user, location?.pathname, isUserVerified]);

  const ssoLogin = React.useCallback(async (provider: string) => {
    try {
      let ssoProvider: any = new firebaseClient.auth.GoogleAuthProvider();
      if(provider === 'twitter') {
        ssoProvider = new firebaseClient.auth.TwitterAuthProvider();
      }
      if(provider === 'microsoft') {
        ssoProvider = new firebaseClient.auth.OAuthProvider('microsoft.com');
      }
      if(provider === 'facebook') {
        ssoProvider = new firebaseClient.auth.FacebookAuthProvider();
      }
      if(provider === 'apple') {
        ssoProvider = new firebaseClient.auth.OAuthProvider('apple.com');
        ssoProvider.addScope('email');
        ssoProvider.addScope('name');
      }
      if(provider === 'google') {
        ssoProvider.addScope('email');
        ssoProvider.addScope('profile');
        ssoProvider.addScope('https://www.googleapis.com/auth/calendar.events.readonly');
        ssoProvider.addScope('https://www.googleapis.com/auth/gmail.readonly');
        ssoProvider.setCustomParameters({ access_type: 'offline', prompt: 'select_account' });
      }
      try {
        if (isNative()) {
          const userCredential = await firebaseClient.auth.signInWithNative(ssoProvider);

          return Promise.resolve({
            userCredential,
            success: true,
          });
        }
        if(isPWA) {
          await firebaseClient.auth.signInWithRedirect(ssoProvider);
          return Promise.resolve({
            success: true,
          });
        }

        const response = await firebaseClient.auth.signInWithPopup(ssoProvider);
        if (response?.user) {
          return Promise.resolve({
            success: true,
          });
        } else {
          return Promise.reject({
            success: false,
            message: 'Wrong Email',
          });
        }
      } catch(error: any) {
        return Promise.reject({
          success: false,
          error: error.code,
          message: error.message,
        });
      }
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, [isPWA]);

  const phoneLogin = React.useCallback(async (number: string) => {
    try {
      const confirmationResult = await firebaseClient.auth.signInWithPhoneNumber(number);
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      // @ts-ignore
      window.confirmationResult = confirmationResult;

      return Promise.resolve({
        confirmationResult,
        success: true,
      });
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  const login = React.useCallback(async (email: string, password: string) => {
    try {
      const response = await firebaseClient.auth.signInWithEmailAndPassword(email, password);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      console.log({
        error,
      });
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  const resetPassword = React.useCallback(async (email: string) => {
    try {
      await firebaseClient.auth.sendPasswordResetEmail(email);
      return Promise.resolve({
        success: true,
      });
    } catch (error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  const impersonate = React.useCallback(async (token: string) => {
    try {
      queryClient.clear();
      const response = await firebaseClient.auth.signInWithCustomToken(token);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, [queryClient]);

  const logout = React.useCallback(async () => {
    await firebaseClient.auth.signOut();
    resetPermissions();
    queryClient.removeQueries([cacheKeys.getMe]);
    queryClient.clear();
    router.auth.login.go();
  }, [queryClient, resetPermissions, router]);

  const linkWithPhone = React.useCallback(async (number: string) => {
    try {
      const confirmationResult = await firebaseClient.auth.linkWithPhoneNumber(number);

      // SMS sent. Prompt user to type the code from the message.
      return Promise.resolve({
        confirmationResult,
        success: true,
      });
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  const verifyPhone = React.useCallback(async (number: string) => {
    try {
      const verificationId = await firebaseClient.auth.verifyPhoneNumber(number);

      // SMS sent. Prompt user to type the code from the message.
      return Promise.resolve({
        verificationId,
        success: true,
      });
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  const updateLinkedPhone = React.useCallback(async (verificationId: string, verificationCode: string) => {
    try {
      await firebaseClient.auth.updateLinkedPhoneNumber(verificationId, verificationCode);

      return Promise.resolve({
        success: true,
      });
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  }, []);

  React.useEffect(() => {
    if (status !== 'loading' &&  !!firebaseUser) {
      initUserTracking(user);
    }
  }, [status, user, firebaseUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        firebaseUser,
        logout,
        login,
        ssoLogin,
        phoneLogin,
        resetPassword,
        impersonate,
        linkWithPhone,
        verifyPhone,
        updateLinkedPhone,
        isUserVerified,
        isLoggedIn: status === 'success' && isLoggedIn,
        loading: loading || !!(status === 'loading' && firebaseUser),
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
