import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Stack, Button, IconButton, Divider } from '@mui/material';
import { Block, HighlightOff, Public, InsertInvitationTwoTone, EmailTwoTone, CallTwoTone, Telegram } from '@mui/icons-material';

import { DialogContext, FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { AuthContext } from '@modules/Auth/contexts';
import { formatTripDates } from '@modules/Trips/helpers/date';
import { useConnectionChange } from '@modules/Connections/hooks/useConnectionChange';
import { useChatCrud } from '@modules/Messaging/hooks/useChatCrud';
import { sendSms } from '@modules/Contacts/helpers/mobile';
import { ConnectionButtonSelect } from '@modules/Connections/components/ConnectionButtonSelect';

import { SOCIAL_ICONS, SOCIAL_PROFILES, getHandle, getLink } from '@shared/helpers/social';
import { copyToClipboard } from '@shared/helpers/navigator';
import { isNative } from '@shared/helpers/environment';

import { blue } from '@shared/theme/palette';
import { StackedTrips } from '@pages/Home/views/StackedTrips';
import { useUser } from '../hooks/useUser';

const ViewUserView: React.FC<Users.User> = user => {
  const { isLoggedIn, user: me } = React.useContext(AuthContext);
  const { asyncConfirmation, openDialog } = React.useContext(DialogContext);
  const { triggerFeedback } = React.useContext(FeedbackContext);
  const dictionary = useDictionary();
  const router = useRouter();

  const { createChat } = useChatCrud();

  const { onConnectionTypeChange } = useConnectionChange({
    user,
  });
  
  const onChatRequest = React.useCallback(async () => {
    const response = await createChat({
      users: [user.id],
    });
    router.inbox.view(response.data.id).go();
  }, [createChat, user, router]);

  const onBlockRequest = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.users.connection.deleteConfirmation, content: ' ', confirmLabel: dictionary.users.connection.buttonBlock });
    if (!userConfirmed) {
      return false;
    }
    return onConnectionTypeChange('block');
  }, [dictionary, asyncConfirmation, onConnectionTypeChange]);

  const handleCopyToClipboard = async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
  };

  const onSendMessage = React.useCallback(async () => {
    sendSms(`${user?.phone}`);
  }, [user?.phone]);

  const isCurrentUserView = React.useMemo(() => user.id === me?.id, [user, me]);
  const isUserConnected = React.useMemo(() => (user.connection?.type === 'general' || user.connection?.type === 'close') && user.connection?.status === 'active', [user]);
  const isUserSetMeetLink = React.useMemo(() => isUserConnected && !!user.bookMeetingLink, [isUserConnected, user.bookMeetingLink]);

  return (
    <Stack direction="column">
      {isCurrentUserView && (
        <Box p={2}>
          <Typography variant="body2">You're viewing your own profile</Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        height="60vh"
        sx={theme => ({
          backgroundColor: blue,
          color: 'common.white',
          backgroundImage: `url(${user.profilePictureSizes?.[500]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        })}
      >
        <Stack px={3} p={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1} direction="row">
            {user.thrUsername && (
              <Button color="primary" variant="contained" size="small" onClick={() => openDialog('userQrCode', { path: router.users.view(user?.thrUsername || user?.id || '').path })}>
                @{user.thrUsername}
              </Button>
            )}
            {isCurrentUserView && (
              <Button color="primary" variant="contained" size="small" onClick={() => router.profile.tab('profile').go()}>
                Settings
              </Button>
            )}
          </Stack>
          {isLoggedIn && !isCurrentUserView && (
            <ConnectionButtonSelect user={user} canToggle={isUserConnected}/>
          )}
        </Stack>
        <Box mt="auto" width="100%" p={3} pt={6}>
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Stack mt="auto" sx={{ textShadow: '0 0 4px rgba(0, 0, 0, 0.8)' }}>
              <Typography variant="h1" fontSize={32} fontWeight={600}>
                {user.displayName}
              </Typography>
              <Stack spacing={1} direction="column">
                Currently in {user.trip ? user.trip.location.title : user.baseLocation?.title || 'Unknown'}
                {user.trip ? ` - ${formatTripDates(user.trip.arrivalDatetime, user.trip.departureDatetime)}` : null}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box pt={3} pb={2}>
        {user.bio && (
          <Box
            px={2}
            pb={3}
          >
            <Typography variant="h5" color="text.secondary">About</Typography>
            <Typography variant="body1" fontSize={20}>{user.bio}</Typography>
          </Box>
        )}
        <Box
          px={2}
          pb={3}
        >
          <Typography variant="h5" color="text.secondary" mb={1}>Contact info</Typography>
          <Box display="flex" gap={1} flexDirection="row" flexWrap="wrap">
            {isLoggedIn && !isCurrentUserView && isUserConnected && (
              <Button variant="contained" color="secondary" onClick={onChatRequest} startIcon={<Telegram/>}>
                Message
              </Button>
            )}
            {isUserSetMeetLink && <Button href={user.bookMeetingLink || ''} target="_blank" rel="noreferrer noopener" variant="contained" color="secondary" startIcon={<InsertInvitationTwoTone/>}>Book a meeting</Button>}
            {user.email && (
              <Button onClick={() => handleCopyToClipboard(user.email, dictionary.users.profile.clipboardSaved('Email'))} variant="contained" color="secondary" startIcon={<EmailTwoTone/>}>Email</Button>
            )}
            {user.phone && (
              <Button onClick={() => isNative() ? onSendMessage() : handleCopyToClipboard(user?.phone || '', dictionary.users.profile.clipboardSaved('Phone number'))} variant="contained" color="secondary" startIcon={<CallTwoTone/>}>Phone</Button>
            )}
          </Box>
        </Box>
        <Box
          px={2}
          pb={1}
        >
          <StackedTrips
            showSubtitle={false}
            params={{
              user: user.id,
            }}
          />
        </Box>
        {SOCIAL_PROFILES.some(social => user[`${social}Url`]) && (
          <Box px={2}>
            <Typography variant="h5" color="text.secondary">Socials</Typography>
            <Stack direction="row" spacing={2}>
              {SOCIAL_PROFILES.map(social => {
                const input = user[`${social}Url`];

                if (!input) {
                  return null;
                }
                return (
                  <IconButton
                    component={Link}
                    to={getLink(getHandle(input), social)}
                    color="secondary"
                    size="small"
                  >
                    {SOCIAL_ICONS[social]}
                  </IconButton>
                );
              })}
            </Stack>
          </Box>
        )}
        {!isCurrentUserView && (
          <Stack px={2} mt={3} spacing={2}>
            <Divider/>
            <Stack direction="row" justifyContent="space-around">
              {user.connection?.type !== 'block' && (
                <Button startIcon={<Block/>} variant="text" color="inherit" onClick={onBlockRequest}>
                  Block user
                </Button>
              )}
              {user.connection?.type === 'block' && (
                <Button startIcon={<Public/>} variant="text" color="inherit" onClick={() => onConnectionTypeChange('general')}>
                  Unblock user
                </Button>
              )}
              {['general', 'close'].includes(user.connection?.type || 'public') && (
                <Button startIcon={<HighlightOff/>} variant="text" color="inherit" onClick={() => onConnectionTypeChange('public')}>
                  Remove from Network
                </Button>
              )}
            </Stack>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export const ViewUser: React.FC<{ id: string }> = ({ id }) => {

  const { isLoggedIn } = React.useContext(AuthContext);

  const { user, status } = useUser({
    id,
    authenticate: isLoggedIn,
  });
  
  if (status === 'loading') {
    return <BodyLoading height="100%" />;
  }
  
  if (status === 'error' || !user) {
    return <MessageFeedbackView height="100%" />;
  }

  return <ViewUserView {...user} />;
};
