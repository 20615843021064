import React from 'react';
import noop from 'lodash/noop';

import { LocalizationContext } from '@cvt/contexts';

import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { ChannelForm } from './ChannelForm';

export interface Props {
  communityId: number;
  onSuccess?: (channel: Communities.Channels.Channel) => void;
}
export const CreateChannel: React.FC<Props> = ({ communityId, onSuccess = noop }) => {

  const { dictionary } = React.useContext(LocalizationContext);

  const { createChannel } = useCommunityCrud();

  const onSubmit = React.useCallback((data: Communities.Channels.Crud) => {
    return createChannel({
      communityId,
      ...data,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [communityId, createChannel, onSuccess]);

  return (
    <ChannelForm
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.channels.create.buttonCreate}
    />
  );
};
