import React from 'react';
import { noop } from 'lodash';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';

import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { ChannelForm, FormCrud as ChannelFormData } from './ChannelForm';

export interface Props {
  channel: Communities.Channels.Channel;
  community: Communities.Community;
  onSuccess?: (channel: Communities.Channels.Channel) => void;
}

export const EditChannel: React.FC<Props> = ({ channel, community, onSuccess = noop }) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { editChannel } = useCommunityCrud();

  const onSubmit = React.useCallback(async (data: ChannelFormData) => {
    try {
      const response = await editChannel({
        id: channel.id,
        name: data.name,
        private: data.private,
        handle: community.handle,
        communityId: community.id.toString(),
      });

      onSuccess(response.data);

      return true;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [channel.id, community, editChannel, onSuccess]);

  return (
    <ChannelForm
      community={community}
      defaultValues={{
        name: channel.name,
        availability: channel.private ? 'private' : 'public',
        private: channel.private,
      }}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.edit.buttonEdit}
    />
  );
};