import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

const Menu = (theme: Theme) => {
  return {
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.mode === 'light' ? 'common.white' : theme.palette.secondary.dark,
          color: theme.palette.secondary.main,
          height: 'auto',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: theme.palette.mode === 'light' ? grey[600] : grey[100],
          minWidth: 50,
          '&.Mui-selected': {
            color: theme.palette.mode === 'light' ? theme.palette.primary.dark : theme.palette.primary.light,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // backgroundColor: theme.palette.mode === 'light' ? grey[600] : grey[100],
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),
        },
      },
    },
  };
};

export default Menu;
