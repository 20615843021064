import React from 'react';
import { Session } from '@talkjs/react';

import { UserContext } from '@modules/Users/contexts';

export const TalkSession: React.FC<React.PropsWithChildren> = ({ children }) => {

  const { user, setUnreadMessages } = React.useContext(UserContext);

  if (user) {
    return (
      <Session
        appId={import.meta.env.VITE__CVT_TALKJS_APP_ID}
        userId={user.id.toString()}
        signature={user.signature}
        onUnreadsChange={setUnreadMessages}
      >
        {children}
      </Session>
    );
  }

  return <>{children}</>;
};
