import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Skeleton,
  Stack,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';

import { UserAvatar } from '@modules/Users/components/UserAvatar';

interface Props {
  selected: boolean;
  onChange: (selected: boolean) => void;
  canChange?: boolean;
}

export const UserRow: React.FC<Props & { user: Users.User }> = ({ user, selected, onChange, canChange = true }) => {
  return (
    <Paper component={Box} width="100%" sx={{ cursor: 'pointer' }} onClick={() => canChange && onChange(!selected)}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <UserAvatar user={user} sx={{ width: 30, height: 30 }} />
          <Typography variant="body1" component="h1">{user.displayName}</Typography>
        </Stack>
        {canChange && (
          <React.Fragment>
            {selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </React.Fragment>
        )}
      </Stack>
    </Paper>
  );
};

export const ParticipantRowSkeleton: React.FC = () => {
  return (
    <Paper component={Box} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="circular" width={30} height={30} />
          <Typography variant="body1" component="h1"><Skeleton variant="text" width={180} /></Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
