type Participant = Pick<Users.User, 'displayName' | 'firstName' | 'lastName'>;

export const formatParticipantDisplayNames = (users: Participant[], key: keyof Participant = 'displayName') => {
  const numUsers = users.length;
  if (numUsers === 0) {
    return ''; // Handle the case of no users, if needed.
  } else if (numUsers === 1) {
    return `${users[0][key]}`;
  } else if (numUsers === 2) {
    return `${users[0][key]} and ${users[1][key]}`;
  } else if (numUsers === 3) {
    return `${users[0][key]}, ${users[1][key]} and ${users[2][key]}`;
  } else {
    return `${users[0][key]}, ${users[1][key]} and ${numUsers - 2} others`;
  }
};
