import { useInfiniteQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { contactsClient } from '../client/contactClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

const defaultPageSize = 10;

export const useInfiniteContacts = (params: Contacts.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { pages = [] } = {}, ...rest } = useInfiniteQuery({
    queryKey: [cacheKeys.getContacts, Object.values(params)],
    queryFn: ({ pageParam }) => contactsClient.getContacts({
      ...params,
      limit: params.limit || defaultPageSize,
      offset: (pageParam - 1) * defaultPageSize,
    }),
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil((lastPage?.data.count || 0) / (params.limit || defaultPageSize));
      const currentPage = allPages.length;
      if (currentPage < totalPages) {
        return currentPage + 1;
      }
      return undefined;
    },
    ...options,
  });

  return {
    ...rest,
    count: pages[0]?.data.count || 0,
    contacts: pages?.filter(p => p !== undefined).map(p => p?.data.results).flat(),
  };
};
