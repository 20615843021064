import { Theme, lighten } from '@mui/material';

import { darkBlue } from '@shared/theme/palette';

const ToggleButton = (theme: Theme) => {
  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.85),
          },
        },
      },
      variants: [{
        props: { color: 'secondary' },
        style: {
          border: '1px solid rgba(255, 255, 255, 0.03)',
          textTransform: 'none',
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.secondary.dark,
          color: theme.palette.mode === 'light' ? theme.palette.secondary.contrastText : theme.palette.secondary.contrastText,
          '&:hover': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.secondary.main,
            color: theme.palette.mode === 'light' ? theme.palette.secondary.main : theme.palette.secondary.contrastText,
          },
          '&:focus': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.secondary.dark,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.secondary.main,
            color: theme.palette.mode === 'light' ? theme.palette.primary.contrastText : theme.palette.secondary.contrastText,
            '&:hover': {
              backgroundColor: theme.palette.mode === 'light' ? darkBlue : theme.palette.secondary.main,
            },
            '& .MuiSvgIcon-root': {
              color: theme.palette.grey[100],
            },
          },
        },
      },
      {
        props: { color: 'standard' },
        style: {
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[200],
          color: theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
          },
          '&.Mui-selected': {
            backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.secondary.main, 0.8) : theme.palette.primary.main,
            color: theme.palette.mode === 'light' ?  theme.palette.secondary.main : theme.palette.common.white,
            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              color: theme.palette.common.white,
            },
          },
        },
      }],
    },
  };
};

export default ToggleButton;
