import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { PermissionContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';

import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';
import { UserContext } from '@modules/Users/contexts';
import { ContactsContext } from '@modules/Contacts/contexts/ContactsContext';

import { CreateContact } from '../partials/CreateContact';
import { useMobileContactsImport } from '../hooks/useMobileContactsImport';
import { useContacts } from '../hooks/useContacts';

type Props = {
  open: boolean;
  onClose: () => void;
}

export const CreateContactDialog: React.FC<Props> = ({ open, onClose }) => {
  const { permissions } = React.useContext(PermissionContext);
  const dictionary = useDictionary();
  const router = useRouter();
  const { user } = React.useContext(UserContext);
  const { importContacts } = React.useContext(ContactsContext);
  const [isImportingContacts, setIsImportingContacts] = React.useState(false);

  const { count: numberOfContacts, isFetching } = useContacts({
    user: user?.id,
    limit: 1,
  });

  useMobileContactsImport();

  if (isFetching) {
    return <BodyLoading />;
  }

  const showImportContacts = (!permissions.contacts.import && isNative() && numberOfContacts < 1) || isImportingContacts;

  const onContactsImport = async () => {
    setIsImportingContacts(true);

    const isImported = await importContacts(true);

    setIsImportingContacts(false);

    if (isImported) {
      router.spheres.tab('contacts').go();
    }
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))',
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.contacts.create.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {showImportContacts && (
          <Box width="100%" component={Paper} bgcolor="background.default" py={1} pl={2} pr={1} mb={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="h5" component="h1">Import your contacts</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <LoadingButton
                  color="secondary"
                  variant="contained"
                  onClick={onContactsImport}
                  endIcon={<ArrowForward/>}
                  loading={isImportingContacts}
                >
                  Import
                </LoadingButton>
              </Stack>
            </Stack>
          </Box>
        )}
        <Box pt={1}>
          {/* Need it because SwipeableDrawer always mounts */}
          {
            open && <CreateContact
              onSuccess={onClose}
            />
          }
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
