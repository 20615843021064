import { Theme } from '@mui/material';

const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      variants: [{
        props: { color: 'secondary.light' },
        style: {
          color: `${theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.secondary.light} !important`,
        },
      }],
    },
  };
};

export default Typography;
