import React from 'react';
import { Box, Container } from '@mui/material';
import remove from 'lodash/remove';
import concat from 'lodash/concat';

import { useQueryState } from '@cvt/hooks/useQueryState';
import { DialogContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';

import { useUserEvents } from '@modules/Events/hooks/useUserEvents';

import { EventLargeCard } from '../components/EventLargeCard';
import { SwipableCard } from '../components/SwipableCard';
import { SwipableCardStack } from '../components/SwipableCardStack';

export const SwipableEvents: React.FC = () => {
  const { openDialog } = React.useContext(DialogContext);

  const router = useRouter();
  
  const [eventId] = useQueryState('eventId');
  
  const { userEvents, status } = useUserEvents();

  const onOpenEventViewModal = React.useCallback((event: Events.Event, resolve?: () => void) => {
    if (event?.communityId && event?.channelId) {
      openDialog('eventView', { communityId: event?.communityId, channelId: event?.channelId, onComplete: resolve, replace: true });
    }
  }, [openDialog]);

  const filteredUserEvents: Events.Event[] = React.useMemo(() => {
    if (!eventId) {
      return userEvents;
    }
    const currUserEvents = [...userEvents];

    // Remove the event with the matching eventId
    const matchedEvent = remove(currUserEvents, event => event.id.toString() === eventId);

    // Concatenate the matched event at the beginning
    return concat(matchedEvent, currUserEvents);
  }, [userEvents, eventId]);

  if (status !== 'success') {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%', overflow: 'hidden' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <SwipableCardStack onFinish={() => router.dashboard.go()}>
          {filteredUserEvents.map(event => (
            <SwipableCard
              key={event.id}
              // TODO: Add specific view for event
              // onClick={() => openDialog('editEvent', { eventId: event.id })}
              onSwipeRight={() => {
                return new Promise<void>((resolve) => {
                  onOpenEventViewModal(event, resolve);
                });
              }}
            >
              <EventLargeCard event={event} />
            </SwipableCard>
          ))}
        </SwipableCardStack>
      </Box>
    </Container>
  );
};
