import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys } from '../config';
import { notificationsClient } from '../client/notificationsClient';

export const useNotificationCrud = () => {

  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const editNotification = useMutation(({ id, ...data }: Notifications.Edit) => notificationsClient.editNotification({ id, ...data }), {
    mutationKey: [cacheKeys.editNotification],
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteNotification = useMutation((id: number) => notificationsClient.deleteNotification({ id }), {
    mutationKey: [cacheKeys.deleteNotification],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getNotifications]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const clearNotifications = useMutation(() => notificationsClient.clearNotifications(), {
    mutationKey: [cacheKeys.clearNotifications],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getNotifications]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const readNotification = React.useCallback((notification: Notifications.Notification) => {
    return editNotification.mutateAsync({
      id: notification.id,
      readAt: new Date(),
    });
  }, [editNotification]);
  
  const openNotification = React.useCallback((notification: Notifications.Notification) => {
    return editNotification.mutateAsync({
      id: notification.id,
      openedAt: new Date(),
    }).then(response => {
      queryClient.invalidateQueries([cacheKeys.getNotifications]);

      return response;
    });
  }, [editNotification, queryClient]);

  return {
    readNotification,
    openNotification,
    deleteNotification: deleteNotification.mutateAsync,
    clearNotifications: clearNotifications.mutateAsync,
  };
};
