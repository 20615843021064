import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useMembers = (params: Communities.GetMembersParams, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getMembers, params],
    () => communitiesClient.getMembers({
      ...params,
    }),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    members: data || [],
  };
};
