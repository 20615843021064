import React from 'react';
import noop from 'lodash/noop';
import { Button, Grid } from '@mui/material';

import { DialogContext, LocalizationContext } from '@cvt/contexts';

import { useEventCrud } from '../hooks/useEventCrud';
import { EventForm } from './EventForm';

export interface Props {
  event: Events.Event;
  onSuccess?: (event: Events.Event) => void;
}

export const EditEvent: React.FC<Props> = ({ event, onSuccess = noop }) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const { asyncConfirmation } = React.useContext(DialogContext);

  const { editEvent, deleteEvent } = useEventCrud();

  const onSubmit = React.useCallback(async (data: Events.Crud) => {
    return editEvent({
      id: event.id,
      ...data,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [event, editEvent, onSuccess]);

  const onDelete = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.events.edit.deleteConfirmation });

    if (!event || !userConfirmed) {
      return false;
    }

    return deleteEvent(event.id).then(onSuccess);
  }, [asyncConfirmation, dictionary, event, deleteEvent, onSuccess]);

  return (
    <React.Fragment>
      <EventForm
        defaultValues={{
          name: event.name,
          description: event.description,
          dates: [event.startDatetime, event.endDatetime],
          location: {
            id: event.location.id,
            name: event.location.title,
            location: {
              lat: event.location.latitude,
              lng: event.location.longitude,
            },
          },
        }}
        onSubmitRequest={onSubmit}
        onSubmitButtonText={dictionary.events.edit.buttonEdit}
      />

      <Grid item xs={12} mt={1}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          size="large"
          onClick={onDelete}
        >
          {dictionary.events.edit.buttonDelete}
        </Button>
      </Grid>
    </React.Fragment>
  );
};
