import { Theme } from '@mui/material';
import { lightBlue } from '@shared/theme/palette';

const Table = (theme: Theme) => {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: lightBlue,
          },
        },
      },
    },
  };
};

export default Table;
