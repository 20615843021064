import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
} from '@mui/material';

import { useRouter } from '@cvt/hooks/useRouter';
import { formatTripDates } from '@modules/Trips/helpers/date';
import { blue, darkBlue } from '@shared/theme/palette';
import { StackedUsers } from '../views/StackedUsers';

interface Props extends BoxProps {
  event: Events.Event;
}

export const EventLargeCard: React.FC<Props> = ({ event, ...props }) => {
  const router = useRouter();
  const [photoUrl, setPhotoUrl] = React.useState('');

  React.useEffect(() => {
    const fetchPlacePhoto = () => {
      try {
        const mapElement = document.createElement('div');
        // @ts-ignore
        const service = new google.maps.places.PlacesService(mapElement);

        const request = {
          query: event?.location?.title,
          fields: ['photos'],
          locationBias: { lat: event?.location?.latitude, lng: event?.location?.longitude },
        };

        service.findPlaceFromQuery(request, (results: any, status: any) => {
          // @ts-ignore
          if (status === google.maps.places.PlacesServiceStatus.OK && results[0]?.photos) {
            const photo = results[0].photos[0];
            const photoUrl = photo.getUrl({ maxWidth: 1600, maxHeight: 900 });
            setPhotoUrl(photoUrl);
          }
        });
      } catch(err) {
        console.log(err);
      }
    };

    // @ts-ignore
    if (window.google && window.google.maps) {
      fetchPlacePhoto();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE__CVT_GOOGLE_MAPS_KEY}&libraries=places`;
      script.async = true;
      script.onload = fetchPlacePhoto;
      document.head.appendChild(script);
    }
  }, [event.location]);


  return (
    <Box
      {...props}
      display="flex"
      flexDirection="column"
      height="100%"
      borderRadius={4}
      sx={theme => ({
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: photoUrl ? `url(${photoUrl})` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
      })}
    >
      <Box
        width="100%"
        p={2}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          router.dashboard.users.go({ dateGte: event.startDatetime, dateLte: event.endDatetime, locationId: event.location.id });
          return null;
        }}
      >
        <StackedUsers
          params={{
            dateGte: event.startDatetime,
            dateLte: event.endDatetime,
            location: event.location.id.toString(),
            eventId: event.id.toString(),
          }}
        />
      </Box>
      <Box mt="auto" width="100%" p={2} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
        <Stack mt="auto" justifyContent="space-between">
          <Typography variant="h1" fontWeight={600}>
            {event?.location?.title}
          </Typography>
          <Typography variant="body1" fontWeight={600}>
            {event?.startDatetime && event?.endDatetime && `${formatTripDates(event?.startDatetime, event?.endDatetime)}`}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};