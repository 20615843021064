import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { tripsClient } from '../client/tripClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useTrips = (params: Trips.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getTrips, params],
    () => tripsClient.getTrips(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    trips: data?.results || [],
  };
};
