import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { contactsClient } from '../client/contactClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useContacts = (params: Contacts.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error, isFetching } = useQuery(
    [cacheKeys.getContacts, ...Object.values(params)],
    () => contactsClient.getContacts({
      ...params,
    }),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    isFetching,
    error,
    count: data?.count || 0,
    contacts: data?.results || [],
  };
};
