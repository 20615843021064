import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import { useRouter } from '@cvt/hooks/useRouter';

import { useNotifications } from '@modules/Notifications/hooks/useNotifications';
import { getProximityAlertList } from '@modules/Notifications/helpers/notification';
import { AlertLargeCard, AlertLargeCardSkeleton } from '../components/AlertLargeCard';

interface Props {
}

export const Alerts: React.FC<Props> = () => {
  const router = useRouter();
  const { notifications, status } = useNotifications({ limit: 100 });

  const alerts: Notifications.Notification[] = React.useMemo(() => {
    return getProximityAlertList(notifications);
  }, [notifications]);

  React.useEffect(() => {
    if (status === 'success' && alerts.length < 10) {
      router.dashboard.alerts.swipe.go(undefined, true);
    }
  }, [status, alerts, router]);

  if (status === 'loading') {
    return <AlertLargeCardSkeleton/>;
  }

  if (status === 'success' && !alerts.length) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Grid container spacing={2} height="100%">
          {alerts.map((alert) => (
            <Grid item xs={6} key={alert.id} onClick={() => router.dashboard.alerts.swipe.go({ alertId: alert.id })}>
              <AlertLargeCard
                notification={alert}
                height={250}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};