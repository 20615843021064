import React from 'react';
import {
  Typography,
  Paper,
  Box,
  Skeleton,
  Stack,
  IconButton,
} from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Sms } from '@mui/icons-material';

import { sendSms } from '@modules/Contacts/helpers/mobile';

interface Props {
  selected: boolean;
  onChange: (selected: boolean) => void;
  canChange?: boolean;
}

export const ContactRow: React.FC<Props & { contact: Contacts.Contact } > = ({ contact, selected, onChange }) => {
  const onSendMessage = React.useCallback(async () => {
    sendSms(`${contact?.cel}`);
  }, [contact?.cel]);

  return (
    <Paper component={Box} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ cursor: 'pointer' }} onClick={() => onChange(!selected)}>
          {selected ? <CheckBox /> : <CheckBoxOutlineBlank />}
          <Typography variant="body1" component="h1">{contact.displayName}</Typography>
        </Stack>
        {!!contact?.cel && (
          <IconButton color="secondary" onClick={onSendMessage} sx={{ width: 24, height: 24 }}>
            <Sms/>
          </IconButton>
        )}
      </Stack>
    </Paper>
  );
};

export const ParticipantRowSkeleton: React.FC = () => {
  return (
    <Paper component={Box} width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton variant="circular" width={30} height={30} />
          <Typography variant="body1" component="h1"><Skeleton variant="text" width={180} /></Typography>
        </Stack>
      </Stack>
    </Paper>
  );
};
