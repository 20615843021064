import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItemButtonProps, ListItemIcon, ListItemText, MenuItem, SwipeableDrawer, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';

import { PermissionContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { NavLink } from '@cvt/components/NavLink';
import { useRouter } from '@cvt/hooks/useRouter';
import useNetworkSearchParams from '@shared/hooks/useNetworkSearchParams';

import { Authenticated, NotAuthenticated } from '@modules/Auth/components';

import config from '@shared/config';
import { navigation } from '@shared/routes';
import { SettingsContext } from '@shared/contexts';
import { Logo } from '@shared/components/Logo';
import { isNative } from '@shared/helpers/environment';
import { UserContext } from '@modules/Users/contexts';
import { useNotifications } from '@modules/Notifications/hooks/useNotifications';
import { AuthContext } from '@modules/Auth/contexts';

type NavigationItemProps = {
  item: CVT.Navigation.NavigationItem;
  onCloseRequest: () => void;
  ListItemButtonProps?: Pick<ListItemButtonProps, 'sx'>;
}

type Props = React.PropsWithChildren<{
  open: boolean;
  onOpenRequest: () => void;
  onCloseRequest: () => void;
}>;

const NavigationItem: React.FC<NavigationItemProps> = ({ item, onCloseRequest, ListItemButtonProps }) => {
  const { sidebar } = React.useContext(SettingsContext);
  const [open, setOpen] = React.useState(false);

  const { getPermission } = React.useContext(PermissionContext);
  const location = useLocation();

  const canView = React.useMemo(() => {
    return item.permission ? getPermission(item.permission) : true;
  }, [item, getPermission]);

  const allowedChildren = React.useMemo(() => {
    return item.children?.filter(it => it.permission ? getPermission(it.permission) : true);
  }, [item, getPermission]);

  const handleClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const handleChangeNav = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, route: string) => {
    // Prevent rerender component on the same page navigation
    if (route === location.pathname) {
      event.preventDefault();
    }
    onCloseRequest();
  };

  const itemContent = (
    <React.Fragment>
      {item.icon && <ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>}
      <ListItemText primary={item.text} secondary={item.secondaryText} primaryTypographyProps={{ variant: 'body2' }} />
      {allowedChildren && allowedChildren.length > 0 && (
        <IconButton onClick={handleClick} size="small">
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      )}
    </React.Fragment>
  );
  const itemChildren = (
    <React.Fragment>
      {allowedChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {allowedChildren.map((child, idx) => (
              <NavigationItem
                key={idx}
                item={child}
                onCloseRequest={onCloseRequest}
                ListItemButtonProps={{
                  sx: {
                    pl: child.icon ? 5 : 9,
                  },
                }}
              />
            ))}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );

  if (!canView) {
    return null;
  }
  if (item.divider) {
    return <Divider component="li" variant="middle" sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }}/>;
  }
  if (!!item.route) {
    return (
      <React.Fragment>
        <Tooltip title={item.disabled ? 'Coming Soon' : (sidebar === 'open' ? '' : item.text)} placement="right">
          <li>
            <MenuItem
              {...ListItemButtonProps}
              component={NavLink}
              to={item.route}
              onClick={(event) => item.route && handleChangeNav(event, item.route)}
              disabled={item.disabled}
              sx={{
                py: 1,
                pr: 1,
                pl: 2,
                my: 0.5,
                '&.Mui-selected': {
                  color: 'primary.main',
                },
              }}
            >
              {itemContent}
            </MenuItem>
          </li>
        </Tooltip>
        {itemChildren}
      </React.Fragment>
    );
  }
  if (!!item.onClick) {
    return (
      <React.Fragment>
        <MenuItem
          {...ListItemButtonProps}
          component="li"
          disabled={item.disabled}
          onClick={() => [item.onClick && item.onClick(), onCloseRequest()]}
        >
          {itemContent}
        </MenuItem>
        {itemChildren}
      </React.Fragment>
    );
  }
  return null;
};

export const NavigationSidebar: React.FC<Props> = ({ open, onOpenRequest, onCloseRequest }) => {
  const { sidebar, toggleSidebar } = React.useContext(SettingsContext);
  const { user, unreadMessages } = React.useContext(UserContext);
  const { isLoggedIn } = React.useContext(AuthContext);
  const dictionary = useDictionary();
  const router = useRouter();
  const networkSearchParams = useNetworkSearchParams();

  const { notifications } = useNotifications({ limit: 20 }, {
    enabled: isLoggedIn,
  });

  const newNotificationsCounter = React.useMemo(() => notifications.filter(notification => !notification.readAt).length, [notifications]);

  const sidebarMenuRoutes = React.useMemo(() => {
    return (navigation(router, dictionary, config, unreadMessages, newNotificationsCounter, user?.id).sidebar || []).filter(it => sidebar === 'closed' ? !it.divider : true);
  }, [router, dictionary, sidebar, user?.id, unreadMessages, newNotificationsCounter]);

  const authenticatedNavRoutes = React.useMemo(() => {
    return sidebarMenuRoutes.filter(it =>  it.requiresAuth || it.requiresAuth !== false);
  }, [sidebarMenuRoutes]);

  const notAuthenticatedNavRoutes = React.useMemo(() => {
    return sidebarMenuRoutes.filter(it => !it.requiresAuth);
  }, [sidebarMenuRoutes]);

  const drawer = (
    <React.Fragment>
      <List>
        <Authenticated>
          {authenticatedNavRoutes.map((item, idx) => <NavigationItem key={idx} item={item} onCloseRequest={onCloseRequest} ListItemButtonProps={{ sx: { my: 1 } }} />)}
        </Authenticated>
        <NotAuthenticated>
          {notAuthenticatedNavRoutes.map((item, idx) => <NavigationItem key={idx} item={item} onCloseRequest={onCloseRequest} ListItemButtonProps={{ sx: { my: 1 } }} />)}
        </NotAuthenticated>
      </List>
    </React.Fragment>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{
        width: { sm: sidebar === 'open' ? config.theme.drawerWidth : 55 },
        transition: 'width ease-in-out 0.4s',
        flexShrink: { sm: 0 },
        '&:hover #sidebar-toggle': {
          opacity: 1,
        },
      }}
      position="relative"
    >
      <SwipeableDrawer
        container={container}
        variant="temporary"
        open={open}
        onOpen={onOpenRequest}
        onClose={onCloseRequest}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        PaperProps={{
          sx: {
            backgroundColor: 'secondary.main',
            color: 'common.white',
            paddingTop: isNative() ? 'env(safe-area-inset-top)' : 0,
            paddingBottom: isNative() ? 'env(safe-area-inset-bottom)' : 0,
            boxSizing: 'border-box',
            width: config.theme.drawerWidth,
          },
        }}
        sx={{
          display: { xs: 'none', sm: 'none' },
        }}
      >
        <Box
          component={Link}
          to={{
            pathname: router.home.path,
            search: networkSearchParams,
          }}
          py={2}
          px={sidebar === 'open' ? 2 : 1 }
        >
          <Logo sx={{ display: 'block', width: 'auto', height: 24 }} />
        </Box>
        {drawer}
      </SwipeableDrawer>
      <Drawer
        variant="permanent"
        PaperProps={{
          variant: 'elevation',
          sx: theme => ({
            backgroundColor: theme.palette.mode === 'light' ? 'common.white' : 'secondary.main',
            color: theme.palette.text.primary,
            boxSizing: 'border-box',
            width: sidebar === 'open' ? config.theme.drawerWidth : 55,
            overflowX: 'hidden',
            transition: 'width ease-in-out 0.4s',
            border: 0,
            boxShadow: theme.palette.mode === 'light' ? theme.shadows[1] : theme.shadows[5],
          }),
        }}
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
        open
      >
        <Box
          component={Link}
          to={{
            pathname: router.home.path,
            search: networkSearchParams,
          }}
          py={2}
          px={sidebar === 'open' ? 2 : 1 }
        >
          <Logo sx={{ display: 'block', width: 'auto', height: 24 }} />
        </Box>
        {drawer}
      </Drawer>
      <IconButton
        id="sidebar-toggle"
        aria-label="sidebar toggle"
        color="primary"
        size="small"
        sx={{
          display: { xs: 'none', sm: 'flex' },
          opacity: 0,
          transition: 'all ease-in 0.2s',
          position: 'fixed',
          top: theme => theme.spacing(2),
          left: sidebar === 'open' ? config.theme.drawerWidth : 55,
          transform: 'translateX(-50%)',
          backgroundColor: 'common.white',
          zIndex: theme => theme.zIndex.drawer + 1,
          boxShadow: theme => theme.shadows[3],
          color: 'primary.main',
          '&:hover': {
            color: 'secondary.main',
          },
          '&:hover, &:focus': {
            backgroundColor: 'common.white',
          },
        }}
        onClick={toggleSidebar}
      >
        {sidebar === 'open' ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
    </Box>
  );
};
