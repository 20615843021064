import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { eventsClient } from '../client/eventClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useUserEvents = (options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getUserEvents],
    () => eventsClient.getUserEvents(),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    userEvents: data || [],
  };
};
