import React from 'react';
import { Divider, List, ListItem, ListItemProps, ListProps, Stack } from '@mui/material';

import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { useInfiniteContacts } from '../hooks/useInfiniteContacts';

export interface Props {
  renderContact: (contact: Contacts.Contact) => React.ReactNode;
  renderSkeleton?: () => React.ReactNode;
  filters?: Contacts.GetListParams;
  slotProps?: {
    list?: ListProps;
    listItem?: ListItemProps;
  },
  showDivider?: boolean;
}

export const ParticipantsList: React.FC<Props> = ({ filters = {}, renderContact, renderSkeleton, slotProps, showDivider = false }) => {
  const { contacts, status: contactsStatus } = useInfiniteContacts(filters);

  if ([contactsStatus].includes('loading') || !contacts) {
    if (renderSkeleton) {
      return (
        <React.Fragment>
          <List {...slotProps?.list}>
            {Array.from({ length: 3 }).map((v, idx) => (
              <React.Fragment key={idx}>
                {showDivider && (<Divider component="li"/>)}
                <ListItem {...slotProps?.listItem}>{renderSkeleton()}</ListItem>
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      );
    }

    return <BodyLoading height="100%"/>;
  }

  return (
    <Stack spacing={1}>
      <List {...slotProps?.list}>
        {contacts.map(contact => (
          <React.Fragment key={contact.id}>
            {showDivider && (<Divider component="li"/>)}
            <ListItem {...slotProps?.listItem}>{renderContact(contact)}</ListItem>
          </React.Fragment>
        ))}
      </List>
    </Stack>
  );
};