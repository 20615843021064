import React from 'react';
import { Avatar, AvatarProps, Box } from '@mui/material';

interface Props extends Omit<AvatarProps, 'src'> {
  user: Pick<Users.User, 'id' | 'profilePictureSizes' | 'connection'>;
}
export const UserAvatar: React.FC<Props> = ({ user, ...props }) => {
  const color = React.useMemo(() => {
    if (user.connection?.type === 'close') {
      return 'secondary.light';
    }
    if (user.connection?.type === 'general') {
      return 'secondary.main';
    }
    if (user.connection?.type === 'block') {
      return 'error.main';
    }
    return 'transparent';
  }, [user.connection]);

  return (
    <Box p={0.25} bgcolor={color} borderRadius="50%">
      <Avatar src={user.profilePictureSizes?.[100]} {...props} />
    </Box>
  );
};
