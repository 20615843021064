import React from 'react';
import noop from 'lodash/noop';
import { Alert, Box, Button, Grid } from '@mui/material';

import { DialogContext, LocalizationContext } from '@cvt/contexts';

import { useTripCrud } from '../hooks/useTripCrud';
import { TripForm } from './TripForm';

export interface Props {
  trip: Trips.Trip;
  onEditRequest?: (trip: Trips.Crud) => Promise<Trips.Crud>;
  onSuccess?: (trip: Trips.Trip) => void;
}

export const EditTrip: React.FC<Props> = ({ trip, onSuccess = noop, onEditRequest }) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const { asyncConfirmation } = React.useContext(DialogContext);

  const { editTrip, deleteTrip } = useTripCrud();

  const onSubmit = React.useCallback(async (data: Trips.Crud) => {
    let tripData = data;

    if (onEditRequest) {
      tripData = await onEditRequest(data);
    }

    return editTrip({
      id: trip.id,
      ...tripData,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [trip, onEditRequest, editTrip, onSuccess]);

  const onDelete = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.trips.edit.deleteConfirmation });

    if (!trip || !userConfirmed) {
      return false;
    }

    return deleteTrip(trip.id).then(onSuccess);
  }, [asyncConfirmation, dictionary, trip, deleteTrip, onSuccess]);

  return (
    <React.Fragment>
      {trip.status === 'from_gmail' && (
        <Box mb={3}>
          <Alert color="info" icon={false}>{dictionary.trips.generatedAlert}</Alert>
        </Box>
      )}
      <TripForm
        defaultValues={{
          status: trip.status,
          description: trip.description,
          dates: [trip.arrivalDatetime, trip.departureDatetime],
          location: {
            id: trip.location.id,
            name: trip.location.title,
            location: {
              lat: trip.location.latitude,
              lng: trip.location.longitude,
            },
          },
          visibility: trip.visibility,
          visibilityExclusions: trip.visibilityExclusions,
        }}
        onSubmitRequest={onSubmit}
        onSubmitButtonText={trip.status === 'from_gmail' ? dictionary.trips.edit.buttonConfirm : dictionary.trips.edit.buttonEdit}
      />

      <Grid item xs={12} mt={1}>
        <Button
          fullWidth
          variant="text"
          color="primary"
          size="large"
          onClick={onDelete}
        >
          {dictionary.trips.edit.buttonDelete}
        </Button>
      </Grid>
    </React.Fragment>
  );
};
