import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
} from '@mui/material';

import { useRouter } from '@cvt/hooks/useRouter';
import { formatTripDates } from '@modules/Trips/helpers/date';
import { blue, darkBlue } from '@shared/theme/palette';
import { StackedUsers } from '../views/StackedUsers';

interface Props extends BoxProps {
  trip: Trips.Trip;
}

export const TripLargeCard: React.FC<Props> = ({ trip, ...props }) => {
  const router = useRouter();
  const [photoUrl, setPhotoUrl] = React.useState('');

  React.useEffect(() => {
    const fetchPlacePhoto = () => {
      try {
        const mapElement = document.createElement('div');
        // @ts-ignore
        const service = new google.maps.places.PlacesService(mapElement);
  
        const request = {
          query: trip.location.title,
          fields: ['photos'],
          locationBias: { lat: trip.location.latitude, lng: trip.location.longitude },
        };
  
        service.findPlaceFromQuery(request, (results: any, status: any) => {
          // @ts-ignore
          if (status === google.maps.places.PlacesServiceStatus.OK && results[0]?.photos) {
            const photo = results[0].photos[0];
            const photoUrl = photo.getUrl({ maxWidth: 1600, maxHeight: 900 });
            setPhotoUrl(photoUrl);
          }
        });
      } catch(err) {
        console.log(err);
      }
    };

    // @ts-ignore
    if (window.google && window.google.maps) {
      fetchPlacePhoto();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE__CVT_GOOGLE_MAPS_KEY}&libraries=places`;
      script.async = true;
      script.onload = fetchPlacePhoto;
      document.head.appendChild(script);
    }
  }, [trip.location]);

  return (
    <Box
      {...props}
      display="flex"
      flexDirection="column"
      height="100%"
      borderRadius={4}
      sx={theme => ({
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: photoUrl ? `url(${photoUrl})` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
      })}
    >
      <Box
        width="100%"
        p={2}
      >
        <StackedUsers
          params={{ dateGte: trip.arrivalDatetime, dateLte: trip.departureDatetime, location: trip.location.id.toString() }}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            router.dashboard.users.go({ dateGte: trip.arrivalDatetime, dateLte: trip.departureDatetime, locationId: trip.location.id });
            return null;
          }}
        />
      </Box>
      <Box mt="auto" width="100%" p={2} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
        <Stack mt="auto" justifyContent="space-between">
          <Typography variant="h1" fontSize={32} fontWeight={600} noWrap>
            {trip?.location.title}
          </Typography>
          <Typography variant="body1" fontWeight={500} noWrap>
            {trip?.departureDatetime && trip?.arrivalDatetime && `${formatTripDates(trip?.arrivalDatetime, trip?.departureDatetime)}`}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

export const TripCardSkeleton: React.FC = () => {
  return null;
};
