import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys as authCacheKeys } from '@modules/Auth/config';
import { cacheKeys } from '../config';
import { tripsClient } from '../client/tripClient';

export const useTripCrud = () => {
  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const createTrip = useMutation(tripsClient.createTrip, {
    mutationKey: [cacheKeys.createTrip],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getTrips]);
      queryClient.removeQueries([authCacheKeys.getMe]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const editTrip = useMutation(({ id, ...data }: Trips.Edit) => tripsClient.editTrip({ id, ...data }), {
    mutationKey: [cacheKeys.editTrip],
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getTrips]);
      queryClient.invalidateQueries([cacheKeys.getTrip, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const notifyContacts = useMutation(({ id, ...data }: Trips.NotifyContacts) => tripsClient.notifyContacts({ id, ...data }), {
    mutationKey: [cacheKeys.notifyContacts],
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getTrip, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteTrip = useMutation((id: number) => tripsClient.deleteTrip({ id }), {
    mutationKey: [cacheKeys.deleteTrip],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getTrips]);
      queryClient.removeQueries([cacheKeys.getTrip, {
        id,
      }]);
      queryClient.removeQueries([authCacheKeys.getMe]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createTrip: createTrip.mutateAsync,
    editTrip: editTrip.mutateAsync,
    deleteTrip: deleteTrip.mutateAsync,
    notifyContacts: notifyContacts.mutateAsync,
  };
};
