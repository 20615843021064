import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  setCount?: (count: number) => void;
  onFinish?: () => void;
}

export const SwipableCardStack: React.FC<Props> = ({ children, onFinish, setCount, ...props }) => {
  const [stack, setStack] = React.useState(React.Children.toArray(children));

  const _stack = React.useMemo(() => {
    return stack.slice(0, 3);
  }, [stack]);

  const handleSwiped = () => {
    setStack(prevCards => {
      const newCards = [...prevCards];
      newCards.shift();
      return newCards;
    });
  };

  React.useEffect(() => {
    if (stack.length > 0 && setCount) {
      setCount && setCount(stack.length);
    }
  }, [stack, setCount]);

  React.useEffect(() => {
    if (_stack.length === 0 && onFinish) {
      onFinish();
    }
  }, [_stack, onFinish]);

  return (
    <Box position="relative" height="100%" {...props}>
      {_stack.map((card, index) => (
        <Box
          key={index}
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          zIndex={100 - index}
        >
          {React.cloneElement(card as React.ReactElement<any>, {
            onSwiped: handleSwiped,
          })}
        </Box>
      ))}
    </Box>
  );
};