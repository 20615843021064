import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';
import { mapConnectionData } from './connectionClient.formatter';

const usersApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getConnections = (params: Connections.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Connections.Connection>>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/connections/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Connections.ConnectionApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapConnectionData),
    },
  }));
};

const createConnection = (data: Connections.Create): Promise<AxiosResponse<Connections.Connection>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/connections/`,
      method: 'POST',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Connections.ConnectionApi>) => ({
    ...data,
    data: mapConnectionData(data.data),
  }));
};

const editConnection = (data: Partial<Connections.Edit>): Promise<AxiosResponse<Connections.Connection>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/connections/${data.id}/`,
      method: 'PUT',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Connections.ConnectionApi>) => ({
    ...data,
    data: mapConnectionData(data.data),
  }));
};

const deleteConnection = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/connections/${params.id}/`,
      method: 'DELETE',
    },
  });
};

export const connectionsClient = {
  getConnections,
  createConnection,
  editConnection,
  deleteConnection,
};
