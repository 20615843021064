import { PaletteMode, ThemeOptions } from '@mui/material';
import { grey as MuiGrey } from '@mui/material/colors';

const grey = {
  ...MuiGrey,
};

export const darkBlue = '#0B2545';
export const mediumBlue = '#13315C';
export const blue = '#134074';
export const lightBlue = '#8DA9C4';
export const primary = '#88cdf0';

const palette = (mode: PaletteMode) => {
  const palette: ThemeOptions['palette'] = {
    mode,
    grey,
    background: {
      default: mode === 'light' ? grey[100] : darkBlue,
      paper: mode === 'light' ? '#FFFFFF' : mediumBlue,
    },
    primary: {
      main: mode === 'light' ? darkBlue : grey[100],
      contrastText: mode === 'light' ? '#FFFFFF' : '#111111',
    },
    secondary: {
      dark: mode === 'light' ? grey[300] : darkBlue,
      main: mode === 'light' ? grey[200] : mediumBlue,
      light: mode === 'light' ? grey[100] : lightBlue,
      contrastText: mode === 'light' ? darkBlue : '#FFF',
    },
    text: {
      // primary: mode === 'dark' ? foreground : secondary,
    },
  };
  return palette;
};

export default palette;
