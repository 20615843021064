import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

export type Params = {
  id: string;
  authenticate?: boolean;
};

type Options = {
  enabled: boolean
  retry: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCommunity = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: community } = {}, status, error } = useQuery(
    [cacheKeys.getCommunity, params.id],
    () => communitiesClient.getCommunity({ id: params.id }),
    options,
  );

  return {
    status,
    error,
    community,
  };
};
