import React from 'react';
import noop from 'lodash/noop';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { trackEvent } from '@shared/tracking';
import { useTripCrud } from '../hooks/useTripCrud';
import { TripForm } from './TripForm';

export interface Props {
  onSuccess?: (trip: Trips.Trip) => void;
}
export const CreateTrip: React.FC<Props> = ({ onSuccess = noop }) => {

  const dictionary = useDictionary();

  const { createTrip } = useTripCrud();

  const onSubmit = React.useCallback(async (data: Trips.Crud) => {
    trackEvent('trip-creation');

    return createTrip({
      ...data,
      notify: true,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [createTrip, onSuccess]);

  return (
    <TripForm
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.trips.create.buttonCreate}
    />
  );
};
