import { browserName } from 'react-device-detect';
// ** Capacitor
import { Capacitor } from '@capacitor/core';

export const isBrowser = () => typeof window !== 'undefined';

export const isNative = () => Capacitor.isNativePlatform();
export const isIos = () => isNative() && Capacitor.getPlatform() === 'ios';
export const isAndroid = () => isNative() && Capacitor.getPlatform() === 'android';

export const isOpera = browserName.includes('Opera');
export const isFirefox = browserName.includes('Firefox');
export const isSafari = browserName.includes('Safari');
export const isEdge = browserName.includes('Edge');
export const isChrome = browserName.includes('Chrome');
export const isBrave = browserName.includes('Brave');
export const isSamsung = browserName.includes('Samsung');

export const isBeta = import.meta.env.VITE__CVT_ENV === 'beta';
