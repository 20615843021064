import React from 'react';

import { sendSms } from '@modules/Contacts/helpers/mobile';

export const useContactMessage = () => {
  const messageContactBySms = React.useCallback(async (contact: Contacts.Contact, message?: string) => {
    const phoneNumber = contact.cel || contact.tel;

    if (!phoneNumber) {
      return Promise.reject('No phone number');
    }

    return sendSms(phoneNumber, message);
  }, []);

  return {
    messageContactBySms,
  };
};
