import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Divider, List, ListItem, ListItemProps, ListProps, Paper, Stack, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import { useRouter } from '@cvt/hooks/useRouter';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';

import { useConnections } from '@modules/Connections/hooks/useConnections';

import { useInView } from '@shared/hooks/useInView';

import { useSearchUsers } from '../hooks/useSearchUsers';

export interface Props {
  renderItem: (user: Users.User) => React.ReactNode;
  renderSkeleton?: () => React.ReactNode;
  filters?: Users.GetSearchListParams;
  slotProps?: {
    list?: ListProps;
    listItem?: ListItemProps;
  },
  showDivider?: boolean;
  showFetchNextPage?: boolean;
}

export const SearchUsersList: React.FC<Props> = ({ filters = {}, renderItem, renderSkeleton, slotProps, showDivider = false, showFetchNextPage = true }) => {

  const router = useRouter();
  const { users, status, fetchNextPage, hasNextPage, isFetching } = useSearchUsers(filters);
  const { connections } = useConnections();

  const loadNextRef = React.useRef(null);

  const isInView = useInView(loadNextRef);

  React.useEffect(() => {
    if (isInView && !isFetching && showFetchNextPage) {
      fetchNextPage();
    }
  }, [isInView, isFetching, showFetchNextPage, fetchNextPage]);
  

  const _renderSkeleton = React.useCallback(() => {
    if (renderSkeleton) {
      return (
        <React.Fragment>
          <List {...slotProps?.list}>
            {Array.from({ length: 3 }).map((v, idx) => (
              <React.Fragment key={idx}>
                {showDivider && (<Divider component="li"/>)}
                <ListItem {...slotProps?.listItem}>{renderSkeleton()}</ListItem>
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      );

    }
    return null;
  }, [renderSkeleton, showDivider, slotProps]);

  const renderLoading = React.useCallback(() => {
    if (status === 'loading') {
      if (_renderSkeleton) {
        return _renderSkeleton();
      }
  
      return <BodyLoading height="100%"/>;
    }
  }, [status, _renderSkeleton]);

  return (
    <React.Fragment>
      {status === 'success' && connections.length === 0 && (
        <Box width="100%" component={Paper} bgcolor="secondary.main" color="secondary.contrastText" py={1} pl={2} pr={1} mb={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="h5" component="h1">Invite your contacts</Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                color="info"
                variant="contained"
                component={Link}
                to={router.contacts.path}
                endIcon={<ArrowForward/>}
              >
                Contacts
              </Button>
            </Stack>
          </Stack>
        </Box>
      )}
      {status === 'success' && users.length === 0 ? (
        <MessageFeedbackView height="100%" message="No users" />
      ) : (
        <List {...slotProps?.list}>
          {users.map(user => (
            <React.Fragment key={user.id}>
              {showDivider && (<Divider component="li"/>)}
              <ListItem {...slotProps?.listItem}>{renderItem(user)}</ListItem>
            </React.Fragment>
          ))}
        </List>
      )}
      {renderLoading()}
      <Box ref={loadNextRef} />
      {showFetchNextPage && hasNextPage && _renderSkeleton()}
    </React.Fragment>
  );
};
