import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys as userCacheKeys } from '@modules/Users/config';

import { trackEvent } from '@shared/tracking';
import { cacheKeys } from '../config';
import { connectionsClient } from '../client/connectionClient';

export const useConnectionCrud = () => {

  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const createConnection = useMutation((data: Connections.Crud) => {
    trackEvent('new-connection-invitation');

    return connectionsClient.createConnection(data);
  }, {
    mutationKey: [cacheKeys.createConnection],
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([userCacheKeys.getUsers]);
      queryClient.invalidateQueries([userCacheKeys.getSearchUsers]);
      queryClient.invalidateQueries([userCacheKeys.getUser, variables.receiver.toString()]);
      queryClient.invalidateQueries([cacheKeys.getConnections]);
      queryClient.invalidateQueries([cacheKeys.getConnection, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const editConnection = useMutation(connectionsClient.editConnection, {
    mutationKey: [cacheKeys.editConnection],
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([userCacheKeys.getUsers]);
      queryClient.invalidateQueries([userCacheKeys.getSearchUsers]);
      queryClient.invalidateQueries([userCacheKeys.getUser, variables.receiver?.toString()]);
      queryClient.invalidateQueries([cacheKeys.getConnections]);
      queryClient.invalidateQueries([cacheKeys.getConnection, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteConnection = useMutation((data: Connections.Connection) => connectionsClient.deleteConnection({ id: data.id }), {
    mutationKey: [cacheKeys.deleteConnection],
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([userCacheKeys.getUsers]);
      queryClient.invalidateQueries([userCacheKeys.getSearchUsers]);
      queryClient.invalidateQueries([userCacheKeys.getUser, variables.receiver.toString()]);
      queryClient.invalidateQueries([cacheKeys.getConnections]);
      queryClient.removeQueries([cacheKeys.getConnection, {
        id: variables.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createConnection: createConnection.mutateAsync,
    editConnection: editConnection.mutateAsync,
    deleteConnection: deleteConnection.mutateAsync,
  };
};
