import React from 'react';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
// import { FacebookEvents } from 'capacitor-facebook-events';

import { PermissionContext } from '@cvt/contexts';
import { isIos } from '@shared/helpers/environment';


export const useTrackingPermission = () => {
  const { permissions, updatePermissions } = React.useContext(PermissionContext);

  const checkPermission = React.useCallback(() => {
    if (isIos()) {
      AppTrackingTransparency.getStatus().then(value => {
        updatePermissions(['users.tracking'], value.status === 'authorized');
      });
    } else {
      updatePermissions(['users.tracking'], true);
    }
  }, [updatePermissions]);

  const verifyPermission = React.useCallback(async () => {
    try {
      if (isIos()) {
        if (!permissions.users.tracking) {
          const response = await AppTrackingTransparency.requestPermission();
  
          if (response.status === 'authorized') {
            updatePermissions(['users.tracking'], true);
            // FacebookEvents.setAdvertiserTrackingEnabled({
            //   enabled: true,
            // });
            return true;
          }
          return false;
        }
        return true;
      }
      return true;
    } catch(err) {
      console.error(err);
      return true;
    }
  }, [permissions.users.tracking, updatePermissions]);

  React.useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  return {
    verifyPermission,
  };
};
