import { isBefore, parseISO } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

export const mapTripsData = (data: Trips.TripApi[]): Trips.Trip[] => {
  const filteredTrips = data.filter((trip) => isBefore(new Date(trip.arrival_datetime), new Date(trip.departure_datetime)));

  return filteredTrips.map(mapTripData);
};

export const mapTripData = (data: Trips.TripApi): Trips.Trip => {
  const arrivalDatetime = utcToZonedTime(parseISO(data.arrival_datetime), data.trip_tz);
  const departureDatetime = utcToZonedTime(parseISO(data.departure_datetime), data.trip_tz);

  return {
    ...camelizeObject<Trips.Trip>(data),
    arrivalDatetime,
    departureDatetime,
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
  };
};

export const mapTripCrudData = (data: Trips.Crud): Trips.CrudApi => ({
  ...snakizeObject(data),
  arrival_datetime: format(data.arrivalDatetime, 'yyyy-MM-dd') + 'T00:00:00.00Z',
  departure_datetime: format(data.departureDatetime, 'yyyy-MM-dd') + 'T23:59:00.00Z',
});
