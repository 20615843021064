import { Theme } from '@mui/material';

const Progress = (theme: Theme) => {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        bar: {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  };
};

export default Progress;
