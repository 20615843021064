import React from 'react';
import { Geolocation } from '@capacitor/geolocation';
import { NativeSettings, IOSSettings, AndroidSettings } from 'capacitor-native-settings';

import { DialogContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { isNative } from '@shared/helpers/environment';


export const useGeolocation = () => {
  const dictionary = useDictionary();
  const { asyncConfirmation } = React.useContext(DialogContext);
  const [hasRequested, setHasRequested] = React.useState(false);

  const promptLocationSettings = React.useCallback(async () => {
    try {
      if (!hasRequested) {
        const isWeb = !isNative();
        const userConfirmed = await asyncConfirmation({
          title: dictionary.home.map.geolocation.geolocationPermissionConfirmation,
          content: ' ',
          confirmLabel: isWeb ? dictionary.home.map.geolocation.geolocationPermissionButtonOk : dictionary.home.map.geolocation.geolocationPermissionButtonConfirm,
          cancelLabel: isWeb ? false: undefined,
        });
    
        setHasRequested(true);
  
        if (userConfirmed && !isWeb) {
          NativeSettings.open({
            optionAndroid: AndroidSettings.ApplicationDetails,
            optionIOS: IOSSettings.App,
          });
        }
      }
    } catch(err) {
      console.error(err);
    }
  }, [hasRequested, asyncConfirmation, dictionary]);

  const hasPermissionGranted = React.useCallback(async () => {
    try {
      const { location } = await Geolocation.checkPermissions();
  
      return location === 'granted';
    } catch (err) {
      return false;
    }
  }, []);

  const verifyPermission = React.useCallback(async () => {
    try {
      const { location } = await Geolocation.checkPermissions();
  
      if (location === 'denied') {
        return Promise.reject();
      }
      return Promise.resolve(true);
    } catch(err) {
      return Promise.reject(err);
    }
  }, []);

  const getCurrentPosition = React.useCallback(async () => {
    if (isNative()) {
      try {
        const hasPermission = await verifyPermission();
        if (hasPermission) {
          const location = await Geolocation.getCurrentPosition();
          return location;
        } else {
          promptLocationSettings();
        }
      } catch(err) {
        console.error('No permission to access Location API');
        promptLocationSettings();
      }
    }
  }, [promptLocationSettings, verifyPermission]);


  return {
    verifyPermission,
    getCurrentPosition,
    hasPermissionGranted,
  };
};
