import { Theme } from '@mui/material';

const Icon = (theme: Theme) => {
  return {
    MuiSvgIcon: {
      defaultProps: {
        color: 'primary',
      },
    },
  };
};

export default Icon;
