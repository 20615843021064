import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  SwipeableDrawer,
} from '@mui/material';

import { ViewUser } from '../views/ViewUser';


type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    userId: string;
  }
}

export const UserDialog: React.FC<Props> = ({ open, onClose, data }) => {

  if (!data?.userId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          height: '100%',
          pb: 'env(safe-area-inset-bottom)',
          maxHeight: 'calc(100% - env(safe-area-inset-top))',
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <Box
        position="absolute"
        top={8}
        left="50%"
        width={40}
        height={3}
        borderRadius={1}
        bgcolor="common.white"
        sx={{
          transform: 'translate(-50%)',
        }}
      />
      <ViewUser id={data?.userId}/>
    </SwipeableDrawer>
  );
};
