import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { ParticipantsField } from '@modules/Contacts/components/ParticipantsField';
import { useDictionary } from '@cvt/hooks/useDictionary';

export type ContactsFieldProps = {
  filters?: Contacts.GetListParams;
};

export type FormCrud = {
  contacts: Contacts.Contact[];
};

export type Fields = keyof (FormCrud & {
  submit: boolean;
});


export interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: FormCrud) => Promise<void>;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
  fieldProps?: Partial<{ contacts: ContactsFieldProps }>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  contacts: [],
};

export const TripNotificationForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields, fieldProps }) => {
  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty } } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {

    try {
      await onSubmitRequest(data);
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Grid height="100%" container position="relative" width="100%" ml={0} flexDirection="column">
        {isFieldVisible('contacts') && (
          <Grid item xs={12} pl={0}>
            <Controller
              name="contacts"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <ParticipantsField
                  {...field}
                  {...fieldProps?.contacts}
                  contacts={field.value}
                  defaultContacts={defaultValues.contacts}
                  placeholder={dictionary.forms.contact.fieldAddMembersPlaceholder}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('contacts')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}

        {isFieldVisible('submit') && (
          <Grid mt="auto" item xs={12} sx={{
            py: 3,
            bottom: 0,
            zIndex: 100,
            position: 'sticky',
            backgroundColor: 'background.default',
          }}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
