import React from 'react';
import { addDays } from 'date-fns';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useRouter } from '@cvt/hooks/useRouter';

import { useQueryState } from '@cvt/hooks/useQueryState';

import { UserContext } from '@modules/Users/contexts';
import { useUsers } from '@modules/Users/hooks/useUsers';
import { useLocation } from '@modules/Locations/hooks/useLocation';
import { formatTripDates } from '@modules/Trips/helpers/date';

import { UserGridCard, UserGridCardSkeleton } from '../components/UserGridCard';

export const DashboardUsers: React.FC = () => {
  const { user: me } = React.useContext(UserContext);
  const router = useRouter();

  const [dateLte] = useQueryState('dateLte');
  const [dateGte] = useQueryState('dateGte');
  const [locationId] = useQueryState('locationId');
  const [userId] = useQueryState('userId');


  const params = React.useMemo(() => ({
    dateLte: dateLte ? new Date(dateLte) : new Date(),
    dateGte: dateGte ? new Date(dateGte) : addDays(new Date(), 7),
    locationId: locationId || me?.trip?.location.id || me?.baseLocation?.id,
    userId,
  }), [dateLte, dateGte, locationId, userId, me]);

  const { location } = useLocation({
    // @ts-ignore
    id: params.locationId,
  }, {
    enabled: !!params.locationId,
  });

  const { status, users } = useUsers({
    dateGte: params.dateGte,
    dateLte: params.dateLte,
    location: params.locationId,
    limit: 100,
  });

  React.useEffect(() => {
    if (userId) {
      router.dashboard.users.swipe.go(params, true);
    } else if (status === 'success' && users.length < 10) {
      router.dashboard.users.swipe.go(params, true);
    }
  }, [status, users, params, router, userId]);

  if (status === 'loading') {
    return <UserGridCardSkeleton/>;
  }

  if (status === 'success' && !users.length) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Box textAlign="center" mb={2}>
          <Typography variant="h1" fontWeight={500}>People Around</Typography>
          <Typography variant="body1">{location?.title} - {formatTripDates(params.dateGte, params.dateLte)}</Typography>
        </Box>
        <Grid container spacing={2} height="100%">
          {users.map((user) => (
            <Grid item xs={6} key={user.id} onClick={() => router.dashboard.users.swipe.go({ ...params, userId: user.id })}>
              <UserGridCard
                user={user}
                height={250}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
