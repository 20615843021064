import React from 'react';
import { useDebounce } from 'react-use';
import { Box, DialogContent, DialogTitle, Divider, Drawer, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemButton, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { Close, Delete, Search } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { UserContext } from '@modules/Users/contexts';
import { SearchUsersList } from '@modules/Users/partials/SearchUsersList';
import { UserGroupAvatar } from '@modules/Users/components/UserGroupAvatar';
import { UserAvatar } from '@modules/Users/components/UserAvatar';

import { ParticipantRow, ParticipantRowSkeleton } from './ParticipantRow';

export type UserAutocompleteProps<T> = Omit<TextFieldProps, 'value' |'onChange'> & {
  value: T[];
  onChange: (members: Users.User[]) => void;
  defaultValue?: T[];
  filters?: Users.GetListParams;
  disabledDelete?: boolean;
  renderMemberInfo?: (member: T) => React.ReactNode;
};

export const ParticipantsField = <T extends Users.User> ({ value = [], onChange, defaultValue = [], label, filters, disabledDelete, renderMemberInfo, ...props }: UserAutocompleteProps<T>) => {

  const dictionary = useDictionary();

  const { user: me } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);

  const [inputValue, setInputValue] = React.useState('');
  const [search, setSearch] = React.useState('');

  useDebounce(
    () => setSearch(inputValue),
    400,
    [inputValue],
  );

  const existingMembers = React.useMemo(() => {
    return value.filter(user => defaultValue.some(it => it.id === user.id));
  }, [value, defaultValue]);

  const newMembers = React.useMemo(() => {
    return value.filter(user => !defaultValue.some(it => it.id === user.id));
  }, [value, defaultValue]);

  const toggleMember = React.useCallback((user: Users.User, selected: boolean) => {
    let list: Users.User[] = [];
    if (selected) {
      if (!value.some(member => member.id === user.id)) {
        list = [...value, user];
      }
    } else {
      list = value.filter(member => member.id !== user.id);
    }
    onChange(list);
  }, [value, onChange]);

  React.useEffect(() => {
    if (value.length === 0) {
      setOpen(false);
    }
  }, [value]);

  return (
    <React.Fragment>
      {existingMembers && existingMembers.length > 0 && (
        <Box width="100%">
          <InputLabel>{dictionary.forms.community.fieldMembers}</InputLabel>
          <List>
            {existingMembers?.map(user => (
              <ListItem disableGutters>
                <Box component={Box} width="100%">
                  <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Stack width="100%" direction="row" alignItems="center" spacing={1}>
                      <UserAvatar user={user} sx={{ width: 30, height: 30 }} />
                      <Typography variant="body1" component="h1">{user.displayName}</Typography>
                      {renderMemberInfo && renderMemberInfo(user)}
                    </Stack>
                    {user.id !== me?.id && !disabledDelete && (
                      <IconButton onClick={() => toggleMember(user, false)}>
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <InputLabel>{label}</InputLabel>
      <TextField
        {...props}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        autoComplete="off"
        InputProps={{
          ...props?.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {newMembers.length > 0 && (
                <IconButton size="small" onClick={() => setOpen(true)} sx={{ mr: 0.5 }}>
                  <UserGroupAvatar users={newMembers} count={newMembers.length} size={30} />
                </IconButton>
              )}
              <Search color="inherit" />
            </InputAdornment>
          ),
        }}
      />
      <Box minHeight={80} pt={1}>
        <SearchUsersList
          renderItem={user => (
            <ParticipantRow
              user={user}
              selected={value.some(member => member.id === user.id)}
              onChange={selected => toggleMember(user, selected)}
              canChange={user.id !== me?.id && (!disabledDelete || !existingMembers.some(member => member.id === user.id))}
            />
          )}
          renderSkeleton={() => <ParticipantRowSkeleton />}
          filters={{
            ...filters,
            search,
            limit: 6,
          }}
          slotProps={{
            list: {
              disablePadding: true,
            },
            listItem: {
              disableGutters: true,
              dense: true,
            },
          }}
          showFetchNextPage={false}
        />
      </Box>
      <Drawer
        keepMounted={false}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: theme => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            maxWidth: theme.breakpoints.values.sm,
            maxHeight: '43vh',
            margin: 'auto',
          }),
        }}
      >
        <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">{dictionary.communities.create.selectedMembersTitle}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <List disablePadding>
            {newMembers.map((user) => (
              <React.Fragment key={user.id}>
                <ListItemButton disableGutters onClick={() => toggleMember(user, false)}>
                  <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <UserAvatar user={user} sx={{ width: 30, height: 30 }} />
                      <Typography variant="body1" component="span">{user.displayName}</Typography>
                    </Stack>
                    <Delete />
                  </Stack>
                </ListItemButton>
                <Divider component="li"/>
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
};
