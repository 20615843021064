import { Theme } from '@mui/material';

const Alert = (theme: Theme) => {
  return {
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        action: {
          paddingTop: 0,
        },
      },
    },
  };
};

export default Alert;
