import { useState, useEffect, useRef, RefObject } from 'react';

export function useInView(ref: RefObject<HTMLElement>) {
  const [isInView, setIsInView] = useState<boolean>(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsInView(entry.isIntersecting),
    );
  }, []);

  useEffect(() => {
    if (ref.current) {
      observerRef.current?.observe(ref.current);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [ref]);

  return isInView;
};
