import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys } from '../config';
import { messagingClient } from '../client/messagingClient';

export const useChatCrud = () => {
  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const createChat = useMutation(messagingClient.createChat, {
    mutationKey: [cacheKeys.createChat],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getChats]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const editChat = useMutation(({ id, ...data }: Messaging.Edit) => messagingClient.editChat({ id, ...data }), {
    mutationKey: [cacheKeys.editChat],
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getChats]);
      queryClient.invalidateQueries([cacheKeys.getChat, data.data.id.toString()]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteChat = useMutation((id: string) => messagingClient.deleteChat({ id }), {
    mutationKey: [cacheKeys.deleteChat],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getChats]);
      queryClient.removeQueries([cacheKeys.getChat, id.toString()]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const archiveChat = useMutation((id: string) => messagingClient.archiveChat({ id }), {
    mutationKey: [cacheKeys.archiveChat],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getChats]);
      queryClient.removeQueries([cacheKeys.getChat, id.toString()]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const unarchiveChat = useMutation((id: string) => messagingClient.unarchiveChat({ id }), {
    mutationKey: [cacheKeys.unarchiveChat],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getChats]);
      queryClient.removeQueries([cacheKeys.getChat, id.toString()]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createChat: createChat.mutateAsync,
    editChat: editChat.mutateAsync,
    deleteChat: deleteChat.mutateAsync,
    archiveChat: archiveChat.mutateAsync,
    unarchiveChat: unarchiveChat.mutateAsync,
  };
};
