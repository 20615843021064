import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

import { mapUserData } from '@modules/Users/client/userClient.formatter';

export const mapChatData = (data: Messaging.ChatApi): Messaging.Chat => ({
  ...camelizeObject<Messaging.Chat>(data),
  users: data.users.map(mapUserData),
  lastMessageDate: data.last_message_date ? new Date(data.last_message_date) : null,
});

export const mapCommunityCrudData = (data: Communities.Edit): Communities.CommunityApi => ({
  ...snakizeObject(data),
});
