import { isBeta } from '@shared/helpers/environment';

export const getPictures = (url?: string, fake: boolean = false) => {
  if (url && !fake) {
    const parts = url.match(/(.*\/)(.*)(\..*)/);

    if(parts) {
      const getUrl = (size: number) => `${parts[1]}${parts[2]}_${size}${parts[3]}`;
      return {
        30: getUrl(30),
        100: getUrl(100),
        500: getUrl(500),
      };
    }
  } else {
    if (isBeta || fake) {
      return {
        30: url || 'https://picsum.photos/30',
        100: url || 'https://picsum.photos/100',
        500: url || 'https://picsum.photos/500',
      };
    }
  }

  return undefined;
};
