import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { emailRegex, phoneRegex } from '@cvt/helpers/validation';

import { SearchAddressAutocomplete } from '@modules/Locations/components/SearchAddressAutocomplete';

type FormCrud = Omit<Contacts.Crud, 'location'> & {
  location: any;
};

type Fields = keyof (FormCrud & {
  submit: boolean;
});


interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: Contacts.Crud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  firstName: '',
  lastName: '',
  email: '',
};



export const ContactForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields }) => {

  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty } } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest({
        ...data,
        location: data.location ? {
          title: data.location.name,
          latitude: data.location.location.lat,
          longitude: data.location.location.lng,
        } : null,
      });
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {isFieldVisible('firstName') && (
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.contact.fieldName}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('firstName')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('lastName') && (
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldLastName}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('lastName')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('cel') && (
          <Grid item xs={6}>
            <Controller
              name="cel"
              control={control}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: dictionary.forms.validations.invalidPhone,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldMobilePhone}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('cel')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('tel') && (
          <Grid item xs={6}>
            <Controller
              name="tel"
              control={control}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: dictionary.forms.validations.invalidPhone,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.fieldPhone}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('tel')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('email') && (
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.fieldEmail}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('email') || !!defaultValues.email}
                  inputProps={{
                    autoCapitalize: 'none',
                  }}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('location') && (
          <Grid item xs={12}>
            <Controller
              name="location"
              control={control}
              render={({ field, fieldState }) => (
                <SearchAddressAutocomplete
                  {...field}
                  label={(
                    <React.Fragment>
                      <Typography component="span" variant="inherit">{dictionary.forms.contact.fieldLocation}</Typography>
                      <Typography component="span" variant="body2" ml={1}>{dictionary.forms.labelOptional}</Typography>
                    </React.Fragment>
                  )}
                  TextFieldProps={{
                    size: 'small',
                    error: !!fieldState.error,
                    helperText: fieldState.error?.message,
                  }}
                  disabled={isFieldDisabled('location')}
                />
              )}
            />
          </Grid>
        )}

        {isFieldVisible('submit') && (
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
