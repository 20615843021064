import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { FeedbackContext } from '@cvt/contexts';
import { cacheKeys as notificationCacheKeys } from '@modules/Notifications/config';
import { UserContext } from '@modules/Users/contexts';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

export const useCommunityCrud = () => {

  const { genericErrorFeedback, triggerFeedback } = React.useContext(FeedbackContext);
  const { user: me } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  const createCommunity = useMutation(communitiesClient.createCommunity, {
    mutationKey: [cacheKeys.createCommunity],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
    },
    onError: (err: any ) => {
      // If we have handle error - show BE error message
      if (err.response?.data?.handle[0]) {
        triggerFeedback({
          severity: 'error',
          message: err.response?.data?.handle[0],
        });
      } else {
        genericErrorFeedback();
      }
    },
  });

  const editCommunity = useMutation(({ id, ...data }: Communities.Edit) => communitiesClient.editCommunity({ id, ...data }), {
    mutationKey: [cacheKeys.editCommunity],
    onMutate: async (newCommunity) => {
      // This is most useful when performing optimistic updates since you will likely need to cancel any outgoing query refetches so they don't clobber your optimistic update when they resolve.
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunities] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, newCommunity.handle] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, newCommunity.id] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, newCommunity.id.toString()] });
      // Get all matching queries
      const prevListCaches = queryClient.getQueriesData<AxiosResponse<CVT.Query.PaginatedResults<Communities.Community>>>([cacheKeys.getCommunities]);
      prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, ({ ...cache, data: { ...cache?.data, results: cache?.data.results.map(community => {
        if (community.id === newCommunity.id) {
          return {
            ...community,
            ...newCommunity,
          };
        }
        return community;
      }) } })));

      const prevCache =
        (newCommunity.handle && queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, newCommunity.handle])) ||
        queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, newCommunity.id.toString()]);

      if (prevCache) {
        const newCache: AxiosResponse<Communities.Community> = {
          ...prevCache,
          data: {
            ...prevCache.data,
            name: newCommunity.name,
            description: newCommunity.description,
            communityPicture: newCommunity.communityPicture,
            communityPictureSizes: newCommunity.communityPicture ? {
              30: newCommunity.communityPicture,
              100: newCommunity.communityPicture,
              500: newCommunity.communityPicture,
            } : undefined,
          },
        };
        queryClient.setQueryData([cacheKeys.getCommunity, newCommunity.id], newCache);
        queryClient.setQueryData([cacheKeys.getCommunity, newCommunity.id.toString()], newCache);
      }
        
      return { prevListCaches, prevCache, newCommunity };
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (data.data.handle) {
        queryClient.invalidateQueries([cacheKeys.getCommunity, data.data.handle]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: data.data.handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getCommunity, data.data.id.toString()]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: data.data.id.toString(),
      }]);
    },
    onError: (err: any, newTodo, context) => {
      context?.prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, cache));
      queryClient.setQueryData([cacheKeys.getCommunity, context?.newCommunity.id], context?.prevCache);
      queryClient.setQueryData([cacheKeys.getCommunity, context?.newCommunity.id.toString()], context?.prevCache);

      genericErrorFeedback();
    },
  });

  const deleteCommunity = useMutation(({ id }: { id: number, handle: string }) => communitiesClient.deleteCommunity({ id }), {
    mutationKey: [cacheKeys.deleteCommunity],
    onMutate: async ({ id: communityId }) => {
      // This is most useful when performing optimistic updates since you will likely need to cancel any outgoing query refetches so they don't clobber your optimistic update when they resolve.
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunities] });
      // Get all matching queries
      const prevCaches = queryClient.getQueriesData<AxiosResponse<CVT.Query.PaginatedResults<Communities.Community>>>([cacheKeys.getCommunities]);
      prevCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, ({ ...cache, data: { ...cache?.data, results: cache?.data.results.filter(community => community.id !== communityId) } })));
        
      return { prevCaches };
    },
    onSuccess: (data, { id, handle }) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (handle) {
        queryClient.removeQueries([cacheKeys.getCommunity, handle]);
        queryClient.removeQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.removeQueries([cacheKeys.getCommunity, id.toString()]);
      queryClient.removeQueries([cacheKeys.getMembers, {
        communityId: id.toString(),
      }]);
    },
    onError: (err, newTodo, context) => {
      context?.prevCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, cache));

      genericErrorFeedback();
    },
  });

  const joinCommunity = useMutation(({ id }: {id: number, handle: string}) => communitiesClient.joinCommunity({ id }), {
    mutationKey: [cacheKeys.joinCommunity],
    onMutate: async ({ id: communityId, handle }) => {
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunities] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getMembers] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, handle] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, communityId] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, communityId.toString()] });

      const getUpdatedCommunity = (community: Communities.Community) => ({
        ...community,
        isMember: true,
        usersCount: community.usersCount + 1,
        users: [...community.users, {
          ...me,
          isOwner: false,
          type: 'member',
          status: community.private ? 'pending_acceptance' : 'active',
        }],
      });

      // Get all matching queries
      const prevListCaches = queryClient.getQueriesData<AxiosResponse<CVT.Query.PaginatedResults<Communities.Community>>>([cacheKeys.getCommunities]);
      prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, ({ ...cache, data: { ...cache?.data, results: cache?.data.results.map(community => {
        if (community.id === communityId) {
          return getUpdatedCommunity(community);
        }
        return community;
      }) } })));

      const prevCache =
        (handle && queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, handle])) ||
        queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, communityId.toString()]);

      if (prevCache) {
        const newCache: AxiosResponse<Communities.Community> = {
          ...prevCache,
          // @ts-ignore
          data: getUpdatedCommunity(prevCache.data),
        };
        queryClient.setQueryData([cacheKeys.getCommunity, communityId], newCache);
        queryClient.setQueryData([cacheKeys.getCommunity, communityId.toString()], newCache);
      }

      const prevMembersCache =
        (handle && queryClient.getQueryData<AxiosResponse<Communities.ExtendedCommunityUser[]>>([cacheKeys.getMembers, { communityId: handle }])) ||
        queryClient.getQueryData<AxiosResponse<Communities.ExtendedCommunityUser[]>>([cacheKeys.getMembers, { communityId: communityId.toString() }]);

      if (prevCache) {
        const newCache: AxiosResponse<Communities.ExtendedCommunityUser[]> = {
          ...prevMembersCache,
          // @ts-ignore
          data: prevMembersCache ? [...prevMembersCache?.data, {
            ...me,
            isOwner: false,
            type: 'member',
            status: 'active',
          }] : [],
        };
        queryClient.setQueryData([cacheKeys.getMembers, { communityId: communityId.toString() }], newCache);
      }
        
      return { prevListCaches, prevCache, prevMembersCache };
    },
    onSuccess: (data, { id, handle }) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getCommunity, handle]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getCommunity, id.toString()]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: id.toString(),
      }]);
    },
    onError: (err, { id: communityId }, context) => {
      context?.prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, cache));
      queryClient.setQueryData([cacheKeys.getCommunity, communityId], context?.prevCache);
      queryClient.setQueryData([cacheKeys.getCommunity, communityId.toString()], context?.prevCache);
      queryClient.setQueryData([cacheKeys.getMembers, { communityId: communityId.toString() }], context?.prevMembersCache);

      genericErrorFeedback();
    },
  });

  const leaveCommunity = useMutation(({ id }: {id: number, handle: string}) => communitiesClient.leaveCommunity({ id }), {
    mutationKey: [cacheKeys.leaveCommunity],
    onMutate: async ({ id: communityId, handle }) => {
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunities] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getMembers] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, handle] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, communityId] });
      await queryClient.cancelQueries({ queryKey: [cacheKeys.getCommunity, communityId.toString()] });

      const getUpdatedCommunity = (community: Communities.Community) => ({
        ...community,
        isMember: false,
        usersCount: community.usersCount - 1,
        users: community.users.filter(user => user.id !== me?.id),
      });

      // Get all matching queries
      const prevListCaches = queryClient.getQueriesData<AxiosResponse<CVT.Query.PaginatedResults<Communities.Community>>>([cacheKeys.getCommunities]);
      prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, ({ ...cache, data: { ...cache?.data, results: cache?.data.results.map(community => {
        if (community.id === communityId) {
          return getUpdatedCommunity(community);
        }
        return community;
      }) } })));

      const prevCache =
        (handle && queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, handle])) ||
        queryClient.getQueryData<AxiosResponse<Communities.Community>>([cacheKeys.getCommunity, communityId.toString()]);

      if (prevCache) {
        const newCache: AxiosResponse<Communities.Community> = {
          ...prevCache,
          // @ts-ignore
          data: getUpdatedCommunity(prevCache.data),
        };
        queryClient.setQueryData([cacheKeys.getCommunity, communityId], newCache);
        queryClient.setQueryData([cacheKeys.getCommunity, communityId.toString()], newCache);
      }

      const prevMembersCache =
        (handle && queryClient.getQueryData<AxiosResponse<Communities.ExtendedCommunityUser[]>>([cacheKeys.getMembers, { communityId: handle }])) ||
        queryClient.getQueryData<AxiosResponse<Communities.ExtendedCommunityUser[]>>([cacheKeys.getMembers, { communityId: communityId.toString() }]);
      if (prevCache) {
        // @ts-ignore
        const newCache: AxiosResponse<Communities.ExtendedCommunityUser[]> = {
          ...prevMembersCache,
          data: prevMembersCache?.data.filter(user => user.id !== me?.id) || [],
        };
        queryClient.setQueryData([cacheKeys.getMembers, { communityId: communityId.toString() }], newCache);
      }
        
      return { prevListCaches, prevCache, prevMembersCache };
    },
    onSuccess: (data, { id, handle }) => {
      queryClient.invalidateQueries([cacheKeys.getCommunities]);
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getCommunity, handle]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getCommunity, id.toString()]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: id.toString(),
      }]);
    },
    onError: (err, { id: communityId }, context) => {
      context?.prevListCaches.forEach(([queryKey, cache]) => queryClient.setQueryData(queryKey, cache));
      queryClient.setQueryData([cacheKeys.getCommunity, communityId], context?.prevCache);
      queryClient.setQueryData([cacheKeys.getCommunity, communityId.toString()], context?.prevCache);
      queryClient.setQueryData([cacheKeys.getMembers, { communityId: communityId.toString() }], context?.prevMembersCache);

      genericErrorFeedback();
    },
  });

  const createChannel = useMutation(({ communityId, ...data }: Communities.Channels.Create & { communityId: number | string }) => communitiesClient.createChannel(communityId, data), {
    mutationKey: [cacheKeys.createChannel],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteChannel = useMutation(({ id, communityId }: { id: string, communityId: string, handle: string }) => communitiesClient.deleteChannel(id, communityId), {
    mutationKey: [cacheKeys.deleteChannel],
    onSuccess: (_, { id, communityId, handle }) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (handle) {
        queryClient.removeQueries([cacheKeys.getChannel, handle, id]);
      }
      queryClient.removeQueries([cacheKeys.getChannel, communityId, id]);
      queryClient.invalidateQueries([cacheKeys.getCommunity, communityId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const joinChannel = useMutation(({ communityId, channelId }: { communityId: string; handle: string; channelId: string }) => communitiesClient.joinChannel(communityId, channelId), {
    mutationKey: [cacheKeys.joinChannel],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, variables.handle, variables.channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, variables.communityId, variables.channelId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const leaveChannel = useMutation(({ communityId, channelId }: { communityId: string; handle: string; channelId: string }) => communitiesClient.leaveChannel(communityId, channelId), {
    mutationKey: [cacheKeys.leaveChannel],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, variables.handle, variables.channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, variables.communityId, variables.channelId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const editChannel = useMutation((data: Communities.Channels.Edit) => communitiesClient.editChannel(data), {
    mutationKey: [cacheKeys.editChannel],
    onSuccess: (data, { id, communityId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, handle, id]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, communityId, id]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const toggleChannelNotifications = useMutation(({ communityId, channelId }: { communityId: string; handle: string; channelId: string }) => communitiesClient.toggleChannelNotifications(communityId, channelId), {
    mutationKey: [cacheKeys.toggleChannelNotifications],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, variables.handle, variables.channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, variables.communityId, variables.channelId]);
      queryClient.invalidateQueries([notificationCacheKeys.getNotifications]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createCommunity: createCommunity.mutateAsync,
    editCommunity: editCommunity.mutateAsync,
    deleteCommunity: deleteCommunity.mutateAsync,
    deleteCommunityLoading: deleteCommunity.isLoading,
    joinCommunity: joinCommunity.mutateAsync,
    joinCommunityLoading: joinCommunity.isLoading,
    leaveCommunity: leaveCommunity.mutateAsync,
    leaveCommunityLoading: leaveCommunity.isLoading,
    createChannel: createChannel.mutateAsync,
    deleteChannel: deleteChannel.mutateAsync,
    deleteChannelLoading: deleteChannel.isLoading,
    joinChannel: joinChannel.mutateAsync,
    joinChannelLoading: joinChannel.isLoading,
    leaveChannel: leaveChannel.mutateAsync,
    leaveChannelLoading: leaveChannel.isLoading,
    editChannel: editChannel.mutateAsync,
    toggleChannelNotifications: toggleChannelNotifications.mutateAsync,
  };
};
