import React from 'react';
import {
  Typography,
  Box,
  Stack,
  BoxProps,
} from '@mui/material';
import { getMonth, getYear } from 'date-fns';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { useUser } from '@modules/Users/hooks/useUser';
import { useTrip } from '@modules/Trips/hooks/useTrip';
import { formatTripDate } from '@modules/Trips/helpers/date';
import { NOTIFICATION_TYPE } from '@modules/Notifications/helpers/notification';

import { blue, darkBlue } from '@shared/theme/palette';


interface Props extends BoxProps {
  notification: Notifications.Notification;
}

export const AlertLargeCard: React.FC<Props> = ({ notification, ...props }) => {
  const dictionary = useDictionary();

  // TODO: Move getting ProximityAlert info to separate hook/component
  const userId = React.useMemo(() => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.ConnectionBackInTown:
    case NOTIFICATION_TYPE.PeerInLocationSoon:
      return notification.meta.user;
    case NOTIFICATION_TYPE.NewConfirmedTrip:
      return notification.meta.relatedUser;
    default:
      return null;
    }
  }, [notification]);

  const tripId = React.useMemo(() => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.NewConfirmedTrip:
    case NOTIFICATION_TYPE.PeerInLocationSoon:
      return notification.meta.relatedTrip;
    default:
      return null;
    }
  }, [notification]);

  const location = React.useMemo(() => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.ConnectionBackInTown:
      return notification.meta?.location;
    default:
      return null;
    }
  }, [notification]);

  const { status: userStatus, user } = useUser({
    id: userId?.toString(),
  }, {
    enabled: !!userId,
    retry: false,
  });

  const { status: tripStatus, trip } = useTrip({
    id: tripId,
  }, {
    enabled: !!tripId,
    retry: false,
  });

  const isLoading = React.useMemo(() => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.ConnectionBackInTown:
      return userStatus === 'loading';
    case NOTIFICATION_TYPE.NewConfirmedTrip:
    case NOTIFICATION_TYPE.PeerInLocationSoon:
      return userStatus === 'loading' || tripStatus === 'loading';
    default:
      return false;
    }
  }, [notification.type, userStatus, tripStatus]);

  const title = React.useMemo(() => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.NewConfirmedTrip:
      if (user && trip) {
        return dictionary.notifications.typeTitles.newConfirmedTrip(
          user?.displayName || '',
          trip?.location.title || '',
          formatTripDate(trip.arrivalDatetime, true, getYear(trip.arrivalDatetime) !== getYear(trip.departureDatetime)),
          formatTripDate(trip.departureDatetime, getMonth(trip.arrivalDatetime) !== getMonth(trip.departureDatetime) || getYear(trip.arrivalDatetime) !== getYear(trip.departureDatetime), getYear(trip.arrivalDatetime) !== getYear(trip.departureDatetime)),
        );
      }
      return null;
    case NOTIFICATION_TYPE.ConnectionBackInTown:
      if (user) {
        return dictionary.notifications.typeTitles.connectionBackInTown(user?.displayName || '', location);
      }
      return null;
    case NOTIFICATION_TYPE.PeerInLocationSoon:
      if (user && trip) {
        return dictionary.notifications.typeTitles.peerInLocationSoon(user.displayName, trip.location.title);
      }
      return null;
    default:
      return null;
    }
  }, [notification, user, trip, dictionary, location]);

  if (isLoading) {
    return <AlertLargeCardSkeleton/>;
  }

  return (
    <Box
      height="100%"
      borderRadius={4}
      display="flex"
      sx={{
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: `url(${user?.profilePictureSizes?.[500]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        overflow: 'hidden',
      }}
      {...props}
    >
      <Box mt="auto" width="100%" p={2} pt={6} sx={{ background: `linear-gradient(to bottom, transparent, ${darkBlue})` }}>
        <Stack mt="auto" justifyContent="space-between">
          <Typography variant="h3" fontWeight={400}>
            {title}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

interface SkeletonProps extends BoxProps {}

export const AlertLargeCardSkeleton: React.FC<SkeletonProps> = ({ sx, ...props }) => {
  return null;
};

