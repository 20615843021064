import { ContactPayload, Contacts, PhonePayload, PhoneType } from '@capacitor-community/contacts';
import { SmsManager } from '@byteowls/capacitor-sms';
import * as Sentry from '@sentry/react';

const getPhone = (phones: PhonePayload[] = [], type: PhoneType, useFallback: boolean = false) => {
  const phone = phones.find(it => it.type === type);
  if (phone) {
    return phone.number;
  }

  if (useFallback) {
    return phones[0]?.number;
  }

  return null;
};

export const mapContact = (contact: ContactPayload): Omit<Contacts.Crud, 'owner'> => {
  try {
    return {
      firstName: contact.name?.given || contact.name?.display || 'Unknown',
      lastName: contact.name?.family || null,
      location: null,
      image: null,
      invitedAt: null,
      email: (contact.emails && contact.emails[0]?.address) || null,
      tel: getPhone(contact.phones, PhoneType.Home),
      cel: getPhone(contact.phones?.filter(it => it.type !== PhoneType.Home), PhoneType.Mobile, true),
      externalId: contact.contactId,
    };
  } catch (err) {
    Sentry.captureException(err);
    return {
      firstName: 'Unknown',
      lastName: null,
      location: null,
      image: null,
      invitedAt: null,
      email: null,
      tel: null,
      cel: null,
      externalId: contact.contactId,
    };
  }
};

export const mapContactPickerContact = (contact: Contacts.ContactPickerContact): Omit<Contacts.Crud, 'owner'> => {
  try {
    return {
      firstName: contact.name[0] || 'Unknown',
      lastName: null,
      location: null,
      image: null,
      invitedAt: null,
      email: contact.email[0] || null,
      tel: contact.tel[1] || contact.tel[2] || contact.tel[3] || '',
      cel: contact.tel[0] || '',
      externalId: '',
    };
  } catch (err) {
    Sentry.captureException(err);
    return {
      firstName: 'Unknown',
      lastName: null,
      location: null,
      image: null,
      invitedAt: null,
      email: null,
      tel: null,
      cel: null,
      externalId: '',
    };
  }
};

export const sendSms = (smsNumber: string, smsText: string = ' ') => {
  return SmsManager.send({
    numbers: [smsNumber],
    text: smsText,
  });
};