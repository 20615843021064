import React from 'react';
import { Box, FormControl, FormHelperText, InputLabel, TextFieldProps, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Diversity1, Groups, Public, VisibilityOff } from '@mui/icons-material';

type VisibilityOptions = 'hidden' | 'close-network' | 'general-network' | 'public';

export type Props = Pick<TextFieldProps, 'error' | 'label' | 'helperText'> & {
  value: string;
  onChange: (value: string) => void;
  hiddenFields?: VisibilityOptions[]
};

export const VisibilityToggleField: React.FC<Props> = ({ value, label, error, helperText, onChange, hiddenFields }) => {
  const isFieldVisible = React.useCallback((field: VisibilityOptions) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  return (
    <FormControl error={!!error} fullWidth>
      <InputLabel htmlFor="visibility-input">{label}</InputLabel>
      <Box pt={1}>
        <ToggleButtonGroup
          id="visibility-input"
          value={value}
          onChange={(e, value) => value !== null && onChange(value)}
          fullWidth
          exclusive
          color={error ? 'error' : 'secondary'}
          size="small"
        >
          {isFieldVisible('public') && (
            <ToggleButton value="public">
              <Public/>
            </ToggleButton>
          )}
          {isFieldVisible('general-network') && (
            <ToggleButton value="general-network">
              <Groups/>
            </ToggleButton>
          )}
          {isFieldVisible('close-network') && (
            <ToggleButton value="close-network">
              <Diversity1/>
            </ToggleButton>
          )}
          {isFieldVisible('hidden') && (
            <ToggleButton value="hidden">
              <VisibilityOff/>
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
      {error && (
        <FormHelperText error>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
