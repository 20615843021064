import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys as authCacheKeys } from '@modules/Auth/config';
import { cacheKeys } from '../config';
import { eventsClient } from '../client/eventClient';

export const useEventCrud = () => {
  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const createEvent = useMutation(eventsClient.createEvent, {
    mutationKey: [cacheKeys.createEvent],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getEvents]);
      queryClient.removeQueries([authCacheKeys.getMe]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const editEvent = useMutation(({ id, ...data }: Events.Edit) => eventsClient.editEvent({ id, ...data }), {
    mutationKey: [cacheKeys.editEvent],
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getEvents]);
      queryClient.invalidateQueries([cacheKeys.getEvent, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteEvent = useMutation((id: number) => eventsClient.deleteEvent({ id }), {
    mutationKey: [cacheKeys.deleteEvent],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getEvents]);
      queryClient.removeQueries([cacheKeys.getEvent, {
        id,
      }]);
      queryClient.removeQueries([authCacheKeys.getMe]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createEvent: createEvent.mutateAsync,
    editEvent: editEvent.mutateAsync,
    deleteEvent: deleteEvent.mutateAsync,
  };
};
