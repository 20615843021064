import { useInfiniteQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { userClient } from '../client/userClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

const defaultPageSize = 100;


export const useSearchUsers = (params: Users.GetListParams = {}, options: Partial<Options> = defaultOptions) => {

  const { data: { pages = [] } = {}, ...rest } = useInfiniteQuery({
    queryKey: [cacheKeys.getSearchUsers, Object.values(params)],
    queryFn: ({ pageParam = 1 }) => userClient.searchUsers({
      limit: params.limit || defaultPageSize,
      offset: (pageParam - 1) * (params.limit || defaultPageSize),
      ...params,
    }),
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil((lastPage.data.count || 0) / (params.limit || defaultPageSize));
      const currentPage = allPages.length;
      if (currentPage < totalPages) {
        return currentPage + 1;
      }
      return undefined;
    },
    enabled: options.enabled,
    ...options,
  });

  return {
    ...rest,
    count: pages[0]?.data.count || 0,
    users: pages?.filter(p => p !== undefined).map(p => p?.data.results).flat(),
  };
};

