import { Instagram, LinkedIn, X } from '@mui/icons-material';

export const SOCIAL_PROFILES = ['instagram', 'twitter', 'linkedin'] as const;

export const SOCIAL_ICONS = {
  twitter: <X fontSize="large"/>,
  instagram: <Instagram fontSize="large"/>,
  linkedin: <LinkedIn fontSize="large"/>,
};

export const SOCIAL_START_ADORNMENT = {
  twitter: '@',
  instagram: '@',
  linkedin: 'in/',
};

export const getLink = (handle: string, social: typeof SOCIAL_PROFILES[number]) => {
  if (!handle) {
    return '';
  }
  switch (social) {
  case 'instagram':
    return `https://www.instagram.com/${getHandle(handle)}`;
  case 'twitter':
    return `https://www.twitter.com/${getHandle(handle)}`;
  case 'linkedin':
    return `https://www.linkedin.com/in/${getHandle(handle)}`;
  default:
    return handle;
  }
};

export const getHandle = (input?: CVT.MaybeNull<string>): string => {
  if (!input) {
    return '';
  }

  const patterns = [
    /https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_-]+)/i, // Instagram pattern
    /https?:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_-]+)/i, // Twitter pattern
    /https?:\/\/(www\.)?linkedin\.com\/in\/([a-zA-Z0-9_-]+)/i, // LinkedIn pattern
  ];

  // Iterate over each pattern to find a match
  for (const pattern of patterns) {
    const match = input.match(pattern);
    if (match && match.length > 2) {
      // The handle is in the second capture group (index 2)
      return match[2];
    }
  }

  // If no patterns match, return the original input
  return input.replaceAll('@', '');
};