import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { useSession } from '@talkjs/react';

import { userClient } from '@modules/Users/client/userClient';
import { cacheKeys } from '@modules/Notifications/config';

import { isIos, isNative } from '@shared/helpers/environment';
import { useRouter } from '@cvt/hooks/useRouter';
import { trackEvent } from '@shared/tracking';

export const useMobileNotifications = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const session = useSession();

  const [hasPermission, setHasPermission] = React.useState(false);

  const initNotifications = React.useCallback(async () => {
    try {
      if (isNative()) {
        const permission = await PushNotifications.requestPermissions();
        
        if (permission.receive === 'granted') {    
          setHasPermission(true);
          // On success, we should be able to receive notifications
          PushNotifications.addListener('registration',
            (token: Token) => {
              try {
                userClient.setDeviceInfo(token.value);
                session?.setPushRegistration({ provider: isIos() ? 'apns' : 'fcm', pushRegistrationId: token.value });
              } catch(err) {
                Sentry.captureException(err);
                console.log(err);
              }
            },
          );

          PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          });
    
          // Show us the notification payload if the app is open on our device
          PushNotifications.addListener('pushNotificationReceived',
            (notification: PushNotificationSchema) => {
              queryClient.invalidateQueries([cacheKeys.getNotifications]);
            },
          );

          // Method called when tapping on a notification
          PushNotifications.addListener('pushNotificationActionPerformed',
            (notification: ActionPerformed) => {
              trackEvent('push-notification-click');
  
              queryClient.invalidateQueries([cacheKeys.getNotifications]);
  
              const { type, conversation_id } = notification?.notification?.data || {};
  
              // Check notification type
              switch (type) {
              case 'new_dm':
                if (conversation_id) {
                  // Route to the conversation page using the ID
                  router.inbox.view(conversation_id).go();
                }
                break;
              default:
                router.notifications.go();
              }
            },
          );

          PushNotifications.register();
        }
      }
    } catch(err) {
      console.error(err);
    }
  }, [queryClient, router, session]);

  return {
    hasPermission,
    initNotifications,
  };
};
