import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import { useRouter } from '@cvt/hooks/useRouter';

import { useQueryState } from '@cvt/hooks/useQueryState';

import { useTrips } from '@modules/Trips/hooks/useTrips';

import { TripCard, TripCardSkeleton } from '../components/TripCard';

export const DashboardTrips: React.FC = () => {
  const router = useRouter();

  const [ownTrips] = useQueryState('ownTrips');
  const [user] = useQueryState('user');
  const [tripId] = useQueryState('tripId');

  const params = React.useMemo(() => ({
    ownTrips: Boolean(ownTrips),
    user: user ? parseInt(user, 10) : undefined,
    tripId,
  }), [ownTrips, tripId, user]);

  const { trips, status } = useTrips({
    limit: 500,
    ownTrips: params.ownTrips,
    user: params.user,
  });


  React.useEffect(() => {
    if (tripId) {
      router.dashboard.trips.swipe.go(params, true);
    } else if (status === 'success' && trips.length < 10) {
      router.dashboard.trips.swipe.go(params, true);
    }
  }, [status, trips, params, router, tripId]);

  if (status === 'loading') {
    return <TripCardSkeleton/>;
  }

  if (status === 'success' && !trips.length) {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Stack spacing={2} height="100%">
          {trips.map((trip) => (
            <Box onClick={() => router.dashboard.trips.swipe.go({ ...params, tripId: trip.id })}>
              <TripCard key={trip.id} trip={trip} />
            </Box>
          ))}
        </Stack>
      </Box>
    </Container>
  );
};
