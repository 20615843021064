import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';

import { mapCommunityInvitationData, mapInviteData, mapUserAuthData } from './authClient.formatter';

const authApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const signupWithEmailAndPassword = (data: Auth.SignupWithEmailAndPassword) => {
  return request({
    authenticate: false,
    options: {
      url: `${authApiBaseUrl}/signup/`,
      method: 'POST',
      data: snakizeObject<Auth.SignupWithEmailAndPasswordApi>(data),
    },
  });
};

const signupWithSSO = ({ invitationToken, channelId }: Auth.SignupWithSSO = {}) => {
  return request({
    options: {
      url: `${authApiBaseUrl}/signup-with-sso/`,
      method: 'POST',
      data: {
        channel_id: channelId,
        user_invitation_id: invitationToken,
      },
    },
  });
};

const getMe = (): Promise<AxiosResponse<Auth.Me>> => {
  return request({
    options: {
      url: `${authApiBaseUrl}/users/me`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Auth.MeApi>) => ({
    ...data,
    data: mapUserAuthData(data.data),
  }));
};

const deleteMe = (): Promise<AxiosResponse<Users.User>> => {
  return request({
    options: {
      url: `${authApiBaseUrl}/users/me`,
      method: 'DELETE',
    },
  });
};

const getInvite = (params: { id: string }): Promise<AxiosResponse<Auth.UserInvite>> => {
  return request({
    options: {
      url: `${authApiBaseUrl}/user-invitations/${params.id}/`,
      method: 'GET',
    },
    authenticate: false,
  }).then((data: AxiosResponse<Auth.UserInviteApi>) => ({
    ...data,
    data: mapInviteData(data.data),
  }));
};

const getCommunityInvitation = (params: { id: string }): Promise<AxiosResponse<Auth.CommunityInvitation>> => {
  return request({
    options: {
      url: `${authApiBaseUrl}/community-invitations/${params.id}/`,
      method: 'GET',
    },
    authenticate: false,
  }).then((data: AxiosResponse<Auth.CommunityInvitationApi>) => ({
    ...data,
    data: mapCommunityInvitationData(data.data),
  }));
};

export const authClient = {
  getMe,
  deleteMe,
  getInvite,
  signupWithSSO,
  getCommunityInvitation,
  signupWithEmailAndPassword,
};
