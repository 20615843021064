import { Theme } from '@mui/material';
import { grey } from '@mui/material/colors';

const Autocomplete = (theme: Theme) => {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          border: theme.palette.mode === 'dark' ? `.5px solid ${grey[600]}` : 'none',
          borderTopWidth: 0,
        },
      },
    },
  };
};

export default Autocomplete;
