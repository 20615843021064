import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
} from '@mui/material';
import { Circle } from '@mui/icons-material';

import { formatTripDates } from '@modules/Trips/helpers/date';

import { blue, darkBlue } from '@shared/theme/palette';
import { UserContext } from '@modules/Users/contexts';
import { getDateDotColor } from '@shared/helpers/date';

interface Props extends BoxProps {
  user: Users.User;
}

export const UserLargeCard: React.FC<Props> = ({ user, ...props }) => {

  const { user: me } = React.useContext(UserContext);

  const isTripHere = React.useMemo(() => {
    if (user.trip && me?.todayLocation) {
      return user.trip.location.id.toString() === me.todayLocation?.id.toString();
    }
    return false;
  }, [user.trip, me]);

  const circleColor = React.useMemo(() => {
    return user.trip ? getDateDotColor(user.trip.arrivalDatetime, user.trip.departureDatetime) : undefined;
  }, [user.trip]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      borderRadius={4}
      sx={{
        backgroundColor: blue,
        color: 'common.white',
        backgroundImage: `url(${user.profilePictureSizes?.[500]})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        overflow: 'hidden',
      }}
      {...props}
    >
      <Box mt="auto" width="100%" p={2} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
        <Stack mt="auto" justifyContent="space-between">
          <Typography variant="h1" fontSize={32} fontWeight={600}>
            {user.displayName}
          </Typography>
          {user.trip?.arrivalDatetime && user.trip?.departureDatetime && (
            <React.Fragment>
              {isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
                    {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
              {!isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body1" fontWeight={500} color="primary.light" noWrap>
                    Not here between {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
            </React.Fragment>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export const UserLargeCardSkeleton: React.FC = () => {
  return null;
};

