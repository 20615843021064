import { camelizeObject } from '@cvt/helpers/object';

export const mapNotificationData = (data: Notifications.NotificationApi): Notifications.Notification => ({
  ...camelizeObject<Notifications.Notification>(data),
  readAt: data.read_at ? new Date(data.read_at) : undefined,
  openedAt: data.opened_at ? new Date(data.opened_at) : undefined,
  deletedAt: data.deleted_at ? new Date(data.deleted_at) : undefined,
  createdAt: new Date(data.created_at),
  updatedAt: new Date(data.updated_at),
});

export const mapNotificationCrudData = (data: Notifications.Edit): Notifications.EditApi => ({
  id: data.id,
  // @ts-ignore
  read_at: data.readAt && data.readAt.toISOString(),
  // @ts-ignore
  opened_at: data.openedAt && data.openedAt.toISOString(),
});
