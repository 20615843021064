import React from 'react';
import noop from 'lodash/noop';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';

import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { CommunityForm, FormCrud as CommunityFormData } from './CommunityForm';

export interface Props {
  onSuccess?: (community: Communities.Community) => void;
}
export const CreateCommunity: React.FC<Props> = ({ onSuccess = noop }) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { createCommunity } = useCommunityCrud();

  const onSubmit = React.useCallback(async ({ name, description, handle, communityPicture = '' }: CommunityFormData) => {
    const payload = { name, description, communityPicture, handle };

    try {
      const response = await createCommunity(payload);
      onSuccess(response.data);

      return response;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [createCommunity, onSuccess]);

  return (
    <CommunityForm
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.create.buttonCreate}
    />
  );
};
