import React from 'react';
import { addHours, isAfter } from 'date-fns';
import { Box, Container, Grid, Typography } from '@mui/material';

import { DialogContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';

import { useQueryState } from '@cvt/hooks/useQueryState';

import { useUsers } from '@modules/Users/hooks/useUsers';

import { UserLargeCard, UserLargeCardSkeleton } from '../components/UserLargeCard';

interface Props extends Partial<Users.ListParams> {
}

export const RecentlyConnectedUsers: React.FC<Props> = (params) => {

  const { openDialog } = React.useContext(DialogContext);
  const router = useRouter();

  const [dateLte] = useQueryState('dateLte');
  const [dateGte] = useQueryState('dateGte');
  const [locationId] = useQueryState('locationId');

  const { status, users } = useUsers({
    dateGte: dateGte ? new Date(dateGte) : undefined,
    dateLte: dateLte ? new Date(dateLte) : undefined,
    location: locationId,
    limit: 100,
  });

  const _users = React.useMemo(() => {
    return users.filter(user => user.connection ? isAfter(user.connection.updatedAt, addHours(new Date(), -1)) : false);
  }, [users]);

  React.useEffect(() => {
    if (status === 'success' && _users.length === 0) {
      router.dashboard.go();
    }
  }, [status, _users, router]);

  if (status === 'loading') {
    return <UserLargeCardSkeleton/>;
  }

  if (status === 'success' && !_users.length) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Box mb={3}>
          <Typography variant="h1">Get in contact</Typography>
          <Typography variant="body2">You already swiped right, how about messaging them?</Typography>
        </Box>
        <Grid container spacing={2} height="100%">
          {_users.map((user) => (
            <Grid item xs={6} key={user.id} >
              <UserLargeCard 
                user={user}
                height={250}
                onClick={() => openDialog('userChat', { userId: user.id })}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};
