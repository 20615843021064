import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Badge } from '@capawesome/capacitor-badge';

import { UserContext } from '@modules/Users/contexts';

import { isNative } from '@shared/helpers/environment';

import { cacheKeys } from '../config';
import { notificationsClient } from '../client/notificationsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useNotifications = (params: Notifications.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { unreadMessages } = React.useContext(UserContext);

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getNotifications, params],
    () => notificationsClient.getNotifications(params),
    {
      enabled: options.enabled,
      // refetch every minute
      refetchInterval: 60000,
      onSuccess(data) {
        if (isNative()) {
          const notReadCount = data.data.results.filter(notification => !notification.readAt).length;
          // This flow should be moved out as it is mixing notifications and messages from talkJS to update the native app badge count
          Badge.set({ count: notReadCount + unreadMessages.direct.length + unreadMessages.communities.length });
        }
      },
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    notifications: data?.results || [],
  };
};
