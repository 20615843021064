import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { DialogContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';

import { useContact } from '../hooks/useContact';
import { ViewContact } from '../partials/ViewContact';
import { useContactInvitation } from '../hooks/useContactInvitation';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    contactId: number;
  }
}

export const ViewContactDialog: React.FC<Props> = ({ open, onClose, data }) => {

  const { openDialog } = React.useContext(DialogContext);
  const dictionary = useDictionary();

  const { contact, status } = useContact({
    id: data?.contactId as number,
  }, {
    enabled: !!data?.contactId,
  });

  const { inviteContactBySms } = useContactInvitation();

  const invite: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (contact) {
      inviteContactBySms(
        contact,
      );
    }
  }, [contact, inviteContactBySms]);

  if (!data?.contactId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.contacts.view.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          {(status === 'loading' || !contact) &&  <BodyLoading height={300}/>}
          {status === 'success' && contact && (
            <React.Fragment>
              <ViewContact {...contact} />
              <Box pt={2}>
                <Stack spacing={1}>
                  {isNative() && (
                    <Button fullWidth onClick={invite}>
                      {dictionary.contacts.view.buttonInvite}
                    </Button>
                  )}
                  <Button fullWidth variant="text" color="primary" onClick={() => openDialog('editContact', { contactId: contact.id })}>
                    {dictionary.contacts.view.buttonEdit}
                  </Button>
                </Stack>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
