import React from 'react';
import { Box, Button, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Block, Diversity1, Groups, Public } from '@mui/icons-material';

import { useConnectionChange } from '../hooks/useConnectionChange';


export type Props = {
  user: Users.User;
  canToggle?: boolean;
  flip?: boolean;
};

export const ConnectionButtonSelect: React.FC<Props> = ({ user, canToggle = true, flip = false }) => {

  const [open, setOpen] = React.useState(!canToggle);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const { onConnectionTypeChange, onConnectionStatusChange } = useConnectionChange({
    user,
  });

  const _onConnectionTypeChange = React.useCallback(async (value: string) => {
    setIsUpdating(true);
    await onConnectionTypeChange(value);
    setIsUpdating(false);
  }, [onConnectionTypeChange]);

  const _onConnectionStatusChange = React.useCallback(async (value: Connections.ConnectionStatus) => {
    setIsUpdating(true);
    await onConnectionStatusChange(value);
    setIsUpdating(false);
  }, [onConnectionStatusChange]);

  const onToggle: React.MouseEventHandler<HTMLElement> = React.useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    if (canToggle) {
      setOpen(open => !open);
    }
    return false;
  }, [canToggle]);

  const isDisabled = isUpdating || ['pending_initiator', 'pending-receiver'].includes(user.connection?.status || '');

  return (
    <Stack direction="row" alignItems="center" spacing={1} flexShrink={0}>
      {user.connection?.status === 'pending_initiator' && !flip && (
        <Typography variant="body2" color="secondary.light" noWrap>Invitation sent</Typography>
      )}
      {user.connection?.status !== 'pending_receiver' && (
        <Box>
          {open && (
            <Box>
              <ToggleButtonGroup
                value={user.connection?.type || 'public'}
                onClick={onToggle}
                onChange={(e, value) => value !== null && _onConnectionTypeChange(value)}
                fullWidth
                exclusive
                disabled={isDisabled}
                color="secondary"
                size="small"
                sx={{ flex: 0 }}
              >
                <ToggleButton value="close">
                  <Diversity1/>
                </ToggleButton>
                <ToggleButton value="general">
                  <Groups/>
                </ToggleButton>
                <ToggleButton value="public">
                  <Public/>
                </ToggleButton>
                {user.connection?.type === 'block' && (
                  <ToggleButton value="block">
                    <Block/>
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </Box>
          )}
          {!open && (
            <ToggleButton selected color="secondary" value={user.connection?.type || 'public'} onClick={onToggle} disabled={isDisabled} size="small">
              {!user.connection && <Public/>}
              {user.connection?.type === 'general' && <Groups/>}
              {user.connection?.type === 'close' && <Diversity1/>}
              {user.connection?.type === 'block' && <Block/>}
            </ToggleButton>
          )}
        </Box>
      )}
      {user.connection?.status === 'pending_initiator' && flip && (
        <Typography variant="body2" color="secondary.light" noWrap>Invitation sent</Typography>
      )}
      {user.connection?.status === 'pending_receiver' && (
        <Button onClick={() => _onConnectionStatusChange('active')} disabled={isUpdating}>
          Accept connection
        </Button>
      )}
    </Stack>
  );
};
