import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isAfter } from 'date-fns';
import { FormHelperText, Grid, TextField } from '@mui/material';
import { DateTimeRangePicker, DateTimeRangePickerProps } from '@mui/x-date-pickers-pro/DateTimeRangePicker';
import { LoadingButton } from '@mui/lab';

import { LocalizationContext } from '@cvt/contexts';

import { SearchAddressAutocomplete, SearchAddressAutocompleteProps } from '@modules/Locations/components/SearchAddressAutocomplete';

export type FormCrud = Omit<Events.Crud, 'location' | 'arrivalDateTime' | 'departureDateTime'> & {
  dates: [Date, Date];
  location: any;
};

export type Fields = keyof (FormCrud & {
  submit: boolean;
});


export interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: Events.Crud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
  fieldProps?: Partial<{
    location: Partial<SearchAddressAutocompleteProps>;
    dates: Partial<DateTimeRangePickerProps<Date>>;
  }>;
  validateFields?: Partial<{
    name: (name: string) => string | boolean;
  }>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  location: null,
  name: '',
  description: '',
};

export const EventForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields, validateFields, fieldProps }) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { handleSubmit, control, formState: { isSubmitting, isDirty }, reset } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest({
        name: data.name,
        description: data.description,
        location: {
          title: data.location.name,
          latitude: data.location.location.lat,
          longitude: data.location.location.lng,
        },
        startDatetime: data.dates[0],
        endDatetime: data.dates[1],
      });

      reset({
        name: data.name,
        description: data.description,
        dates: [data.dates[0], data.dates[1]],
        location: {
          id: defaultValues?.location?.id || '',
          name: data.location.name,
          location: {
            lat: data.location.location.lat,
            lng: data.location.location.lng,
          },
        },
      });
    } catch (err) {
      console.error(err);
    }
  }, [defaultValues?.location?.id, onSubmitRequest, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {isFieldVisible('name') && (
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                validate: value => validateFields?.name ? validateFields.name(value) : true,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.event.fieldName}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('name')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('location') && (
          <Grid item xs={12}>
            <Controller
              name="location"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
              }}
              render={({ field , fieldState }) => (
                <SearchAddressAutocomplete
                  {...fieldProps?.location}
                  {...field}
                  onChange={location => {
                    field.onChange(location);
                    if (fieldProps?.location?.onChange) {
                      fieldProps?.location?.onChange(location);
                    }
                  }}
                  label={dictionary.forms.event.fieldLocation}
                  TextFieldProps={{
                    ...fieldProps?.location?.TextFieldProps,
                    placeholder: 'e.g. Los Angeles',
                    error: fieldProps?.location?.TextFieldProps?.error || !!fieldState.error,
                    helperText: fieldProps?.location?.TextFieldProps?.helperText || fieldState.error?.message,
                  }}
                  disabled={isFieldDisabled('location')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('dates') && (
          <Grid item xs={12}>
            <Controller
              name="dates"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                validate: dates => {
                  if (dates[0] && dates[1]) {
                    if (isAfter(dates[0], dates[1])) {
                      return 'Departure date is after arrival date';
                    }
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <DateTimeRangePicker
                    {...fieldProps?.dates}
                    {...field}
                    disablePast
                    // @ts-ignore
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      if (fieldProps?.dates?.onChange) {
                        // @ts-ignore PickerChangeHandlerContext ?
                        fieldProps?.dates?.onChange(value);
                      }
                    }}
                    localeText={{
                      start: dictionary.forms.event.fieldStartDate,
                      end: dictionary.forms.event.fieldEndDate,
                    }}
                    // Doesn't work. always sets end date first, buggy behavior
                    // slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                      ...fieldProps?.dates?.slotProps,
                      tabs: {
                        hidden: true,
                      },
                      textField: {
                        error: !!fieldState.error,
                        disabled: isFieldDisabled('dates'),
                        size: 'small',
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                  />
                  {fieldState.error && (
                    <FormHelperText error>{fieldState.error.message}</FormHelperText>
                  )}
                </React.Fragment>
              )}
            />
          </Grid>
        )}
        {isFieldVisible('description') && (
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  label={dictionary.forms.event.fieldDescription}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('description')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('submit') && (
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
