import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

export type Params = {
  communityId: string;
  id: string;
};

type Options = {
  enabled: boolean
  retry: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCommunityChannel = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: channel } = {}, status, error } = useQuery(
    [cacheKeys.getChannel, params.communityId, params.id],
    () => communitiesClient.getChannel(params),
    options,
  );

  return {
    status,
    error,
    channel,
  };
};
