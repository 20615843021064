import ReactPixel, { AdvancedMatching } from 'react-facebook-pixel';
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import * as Sentry from '@sentry/react';
import Hotjar from '@hotjar/browser';
// import { FacebookEvents } from 'capacitor-facebook-events';

// TODO: capacitor-facebook-events conflicts with firebase facebook login package. They depend on different versions of FBSDKCoreKit

import { app } from '@cvt/clients/firebaseClient';
import { cleanObject } from '@cvt/helpers/object';
import { pascalizeString } from '@cvt/helpers/string';
import { isNative } from '@shared/helpers/environment';

const analytics = getAnalytics(app);

const hasGoogleAnalytics = !!import.meta.env.VITE__CVT_FIREBASE_MEASUREMENT_ID;
const hasFacebookPixel = !!import.meta.env.VITE__CVT_FACEBOOK_PIXEL_ID;
const hasHotjar = !!import.meta.env.VITE__CVT_HOTJAR_HJID && !!import.meta.env.VITE__CVT_HOTJAR_HJSV;

if (hasHotjar) {
  Hotjar.init(import.meta.env.VITE__CVT_HOTJAR_HJID, import.meta.env.VITE__CVT_HOTJAR_HJSV);
}

export const trackPageview = () => {
  if (hasGoogleAnalytics) {
    logEvent(analytics, 'page_view', {
      page_path: window.location.pathname,
      page_title: document.title,
      page_location: window.location.href,
    });
  }
  if (hasFacebookPixel) {
    ReactPixel.pageView();
  }
  if (hasHotjar) {
    Hotjar.stateChange(window.location.pathname);
  }
};

export const trackSignUp = () => {
  if (hasGoogleAnalytics) {
    logEvent(analytics, 'sign_up', {
      content_type: 'user',
    });
  }
  if (isNative()) {
    // FacebookEvents.logEvent({
    //   event: 'CompleteRegistration',
    // });
  }
  if (!isNative() && hasFacebookPixel) {
    ReactPixel.track('CompleteRegistration');
  }
  if (hasHotjar) {
    Hotjar.event('sign_up');
  }
};

export const trackLogIn = () => {
  if (hasGoogleAnalytics) {
    logEvent(analytics, 'login', {
      content_type: 'user',
    });
  }
  if (isNative()) {
    // FacebookEvents.logEvent({
    //   event: 'CompleteLogin',
    // });
  }
  if (!isNative() && hasFacebookPixel) {
    ReactPixel.track('CompleteLogin');
  }
  if (hasHotjar) {
    Hotjar.event('login');
  }
};

export const initUserTracking = (user?: Users.User) => {
  if (!!user) {
    Sentry.setUser({ email: user.email });

    if (hasGoogleAnalytics) {
      setUserProperties(analytics, { email: user.email });
      setUserId(analytics, user.id.toString());
    }
    if (Hotjar.isReady()) {
      Hotjar.identify(user.id.toString(), { email: user.email, name: user.displayName });
    }
  }
  if (hasFacebookPixel) {
    const advancedMatching: AdvancedMatching = cleanObject({ em: user?.email }) as AdvancedMatching; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    ReactPixel.init(import.meta.env.VITE__CVT_FACEBOOK_PIXEL_ID, advancedMatching);
  }
};

export const trackEvent = (eventName: string, eventParams: { [key: string]: any } = {}) => {
  if (hasGoogleAnalytics) {
    logEvent(analytics, eventName, eventParams);
  }
  if (isNative()) {
    // FacebookEvents.logEvent({
    //   event: eventName,
    // });
  }
  if (!isNative() && hasFacebookPixel) {
    ReactPixel.track(pascalizeString(eventName));
  }
  if (hasHotjar) {
    Hotjar.event(eventName);
  }
};