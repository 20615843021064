import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isAfter } from 'date-fns';
import { FormHelperText, Grid, TextField } from '@mui/material';
import { DateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro';
import { LoadingButton } from '@mui/lab';

import { LocalizationContext } from '@cvt/contexts';

import { UserContext } from '@modules/Users/contexts';
import { SearchAddressAutocomplete, SearchAddressAutocompleteProps } from '@modules/Locations/components/SearchAddressAutocomplete';

import { getVisibilityLabel } from '@shared/helpers/visibility';
import { VisibilityToggleField } from '@shared/components/VisibilityToggleField';

export type FormCrud = Omit<Trips.Crud, 'location' | 'arrivalDateTime' | 'departureDateTime'> & {
  dates: [Date, Date];
  location: any;
};

export type Fields = keyof (FormCrud & {
  submit: boolean;
});


export interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: Trips.Crud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
  fieldProps?: Partial<{
    location: Partial<SearchAddressAutocompleteProps>;
    dates: Partial<DateRangePickerProps<Date>>;
  }>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  location: null,
  description: '',
  status: 'confirmed',
  visibility: 'public',
};

export const TripForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields, fieldProps }) => {

  const { user } = React.useContext(UserContext);
  const { dictionary } = React.useContext(LocalizationContext);

  const { handleSubmit, control, formState: { isSubmitting, isDirty }, setValue, reset } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  React.useEffect(() => {
    // Select confirmed status and enable the submit button
    if (defaultValues.status === 'from_gmail') {
      setValue('status', 'confirmed', { shouldDirty: true });
    }
  }, [defaultValues.status, setValue]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest({
        status: 'confirmed',
        description: data.description,
        location: {
          title: data.location.name,
          latitude: data.location.location.lat,
          longitude: data.location.location.lng,
        },
        arrivalDatetime: data.dates[0],
        departureDatetime: data.dates[1],
        visibility: data.visibility,
        visibilityExclusions: [],
      });

      reset({
        status: 'confirmed',
        description: data.description,
        dates: [data.dates[0], data.dates[1]],
        location: {
          id: defaultValues?.location?.id || '',
          name: data.location.name,
          location: {
            lat: data.location.location.lat,
            lng: data.location.location.lng,
          },
        },
        visibility: data.visibility,
        visibilityExclusions: [],
      });
    } catch (err) {
      console.error(err);
    }
  }, [defaultValues?.location?.id, onSubmitRequest, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {isFieldVisible('location') && (
          <Grid item xs={12}>
            <Controller
              name="location"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                validate: value => {
                  if (value && value.location?.lat === user?.baseLocation?.latitude && value.location?.lng === user?.baseLocation?.longitude) {
                    return dictionary.forms.validations.locationSameAsBase;
                  }
                  return true;
                },
              }}
              render={({ field , fieldState }) => (
                <SearchAddressAutocomplete
                  {...fieldProps?.location}
                  {...field}
                  onChange={location => {
                    field.onChange(location);
                    if (fieldProps?.location?.onChange) {
                      fieldProps?.location?.onChange(location);
                    }
                  }}
                  label={dictionary.forms.trip.fieldLocation}
                  TextFieldProps={{
                    ...fieldProps?.location?.TextFieldProps,
                    placeholder: 'e.g. Los Angeles',
                    error: fieldProps?.location?.TextFieldProps?.error || !!fieldState.error,
                    helperText: fieldProps?.location?.TextFieldProps?.helperText || fieldState.error?.message,
                  }}
                  disabled={isFieldDisabled('location')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('dates') && (
          <Grid item xs={12}>
            <Controller
              name="dates"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                validate: dates => {
                  if (dates[0] && dates[1]) {
                    if (isAfter(dates[0], dates[1])) {
                      return 'Departure date is after arrival date';
                    }
                  }
                  return true;
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <DateRangePicker
                    {...fieldProps?.dates}
                    {...field}
                    // @ts-ignore
                    disablePast
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      if (fieldProps?.dates?.onChange) {
                        // @ts-ignore PickerChangeHandlerContext ?
                        fieldProps?.dates?.onChange(value);
                      }
                    }}
                    localeText={{
                      start: dictionary.forms.trip.fieldStartDate,
                      end: dictionary.forms.trip.fieldEndDate,
                    }}
                    // Doesn't work. always sets end date first, buggy behavior
                    // slots={{ field: SingleInputDateRangeField }}
                    slotProps={{
                      ...fieldProps?.dates?.slotProps,
                      textField: {
                        // Needs it because mui showed 'MMMM DD, YYY' format
                        placeholder: 'MM DD, YYYY',
                        error: !!fieldState.error,
                        disabled: isFieldDisabled('dates'),
                        size: 'small',
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    }}
                    format="MMM dd, yyyy"
                  />
                  {fieldState.error && (
                    <FormHelperText error>{fieldState.error.message}</FormHelperText>
                  )}
                </React.Fragment>
              )}
            />
          </Grid>
        )}
        {isFieldVisible('visibility') && (
          <Grid item xs={12}>
            <Controller
              name="visibility"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <VisibilityToggleField
                  label={getVisibilityLabel(dictionary, field.value)}
                  value={field.value}
                  onChange={field.onChange}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('description') && (
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  label={dictionary.forms.trip.fieldDescription}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('description')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('submit') && (
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
