import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';

import { mapEventCrudData, mapEventData, mapEventsData } from './eventClient.formatter';

const eventsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getEvent = (params: { id: number | string }): Promise<AxiosResponse<Events.Event>> => {
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Events.EventApi>) => ({
    ...data,
    data: mapEventData(data.data),
  }));
};

const getEvents = (params: Events.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Events.Event>>> => {
  const now = new Date().toISOString();
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/`,
      method: 'GET',
      params: {
        date_gte: now,
        ...snakizeObject(params),
      },
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Events.EventApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: mapEventsData(data.data.results),
    },
  }));
};

const getUserEvents = (): Promise<AxiosResponse<Events.Event[]>> => {
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/user-events/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Events.EventApi[]>) => ({
    ...data,
    data: mapEventsData(data.data),
  }));
};

const createEvent = (data: Events.Create): Promise<AxiosResponse<Events.Event>> => {
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/`,
      method: 'POST',
      data: mapEventCrudData(data),
    },
  }).then((data: AxiosResponse<Events.EventApi>) => ({
    ...data,
    data: mapEventData(data.data),
  }));
};

const editEvent = (data: Events.Edit): Promise<AxiosResponse<Events.Event>> => {
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/${data.id}/`,
      method: 'PUT',
      data: mapEventCrudData(data),
    },
  }).then((data: AxiosResponse<Events.EventApi>) => ({
    ...data,
    data: mapEventData(data.data),
  }));
};

const deleteEvent = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${eventsApiBaseUrl}/events/${params.id}/`,
      method: 'DELETE',
    },
  });
};

export const eventsClient = {
  getEvent,
  getEvents,
  getUserEvents,
  createEvent,
  editEvent,
  deleteEvent,
};
