import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { integrationsClient } from '../client/integrationsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useUserIntegrations = (params: Integrations.GetUserIntegrationsParams, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error, isFetching } = useQuery(
    [cacheKeys.getUserIntegrations, params.userId],
    () => integrationsClient.getUserIntegrations(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    integrations: data,
    isFetching,
  };
};
