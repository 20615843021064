import { Theme } from '@mui/material';

const Backdrop = (theme: Theme) => {
  return {
    MuiBackdrop: {
    },
  };
};

export default Backdrop;
