import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { eventsClient } from '../client/eventClient';

export type Params = {
  id: number;
};

type Options = {
  enabled: boolean
  retry: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useEvent = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: event } = {}, status, error } = useQuery(
    [cacheKeys.getEvent, params],
    () => eventsClient.getEvent(params),
    options,
  );

  return {
    status,
    error,
    event,
  };
};
