import { Dictionary } from '@shared/dictionary';

export const getVisibilityLabel = (dictionary: Dictionary, value: Users.AttributeVisibility | Trips.TripVisibility | Users.ProfileVisibility) => {
  const MAP = {
    'public': 'Visible to everyone',
    'general-network': 'Visible only to people you know',
    'close-network': 'Visible only to people you are close to',
    'hidden': 'Visible only to you',
  };

  return MAP[value];
};
