import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { isNative } from '@shared/helpers/environment';

import { CreateChat } from '../partials/CreateChat';

type Props = {
  open: boolean;
  onClose: () => void;
}

export const CreateChatDialog: React.FC<Props> = ({ open, onClose }) => {

  const dictionary = useDictionary();

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))',
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.messaging.create.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {open && ( // Used to clear form when closing dialog
          <Box pt={1}>
            <CreateChat
              onSuccess={onClose}
            />
          </Box>
        )}
      </DialogContent>
    </SwipeableDrawer>
  );
};
