import React from 'react';

import { useConnectionCrud } from './useConnectionCrud';


interface Params {
  user?: Users.User;
}

export const useConnectionChange = ({ user }: Params) => {

  const { createConnection, editConnection, deleteConnection } = useConnectionCrud();

  const connectionType = React.useMemo(() => user?.connection?.type || 'public', [user?.connection]);

  const onConnectionTypeChange = React.useCallback((value: string, connectionUser?: Users.User) => {
    const currentUser = user || connectionUser;

    if (connectionType === value || !currentUser || currentUser?.connection?.status === 'pending_initiator') {
      return Promise.resolve();
    }

    if (value === 'public') {
      if (!!currentUser?.connection) {
        return deleteConnection({
          ...currentUser.connection,
          receiver: currentUser.id,
        });
      } else {
        return Promise.resolve();
      }
    } else {
      if (!!currentUser?.connection) {
        return editConnection({
          ...currentUser.connection,
          type: value as Connections.ConnectionType,
          receiver: currentUser?.id,
        });
      } else {
        return createConnection({
          receiver: currentUser?.id,
          type: value as Connections.ConnectionType,
        });
      }
    }

  }, [user, connectionType, createConnection, editConnection, deleteConnection]);

  const onConnectionStatusChange = React.useCallback((value: Connections.ConnectionStatus) => {
    if (user?.connection?.status === value) {
      return Promise.resolve();
    }

    if (!!user?.connection) {
      const { createdAt, updatedAt, ...rest } = user?.connection;
      return editConnection({
        ...rest,
        status: value,
        receiver: user?.id,
      });
    }

    return Promise.resolve();
  }, [user, editConnection]);

  return {
    onConnectionTypeChange,
    onConnectionStatusChange,
  };
};
