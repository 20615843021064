import { Dictionary } from '@shared/dictionary';

export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const phoneRegex = new RegExp(/^\+?[1]?[-\s.]*\d{1,3}[-\s.]*(\(\d{1,3}\))?[-\s.]*\d{1,4}[-\s.]*\d{1,4}[-\s.]*\d{1,9}$/);

export const passwordRegex = new RegExp(/^(?=.*\d).{6,20}$/);

export const isEmailValid = (email: string) => emailRegex.test(email);

export const isValidUrl = new RegExp(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w.-]*)*\/?$/);

export const validateUsername = (value: string = '', dictionary: Dictionary) => {
  if (!value) {
    return true;
  }

  // Check for spaces
  if (value.includes(' ')) {
    return dictionary.forms.validations.usernameNoSpaces;
  }

  // Check if it starts with a letter
  if (!/^[a-zA-Z]/.test(value)) {
    return dictionary.forms.validations.usernameStartWithLetter;
  }

  // Check for non-alphanumeric characters except for underscore
  if (!/^[a-zA-Z0-9_]+$/.test(value)) {
    return dictionary.forms.validations.invalidUserName;
  }

  return true;
};
