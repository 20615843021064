import React from 'react';
import noop from 'lodash/noop';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';

import { useChatCrud } from '../hooks/useChatCrud';
import { ChatForm, FormCrud as ChatFormData } from './ChatForm';

export interface Props {
  onSuccess?: (community: Messaging.Chat) => void;
}
export const CreateChat: React.FC<Props> = ({ onSuccess = noop }) => {

  const { dictionary } = React.useContext(LocalizationContext);
  const router = useRouter();
  const { createChat } = useChatCrud();

  const onSubmit = React.useCallback(async ({ users }: ChatFormData) => {
    try {
      const response = await createChat({
        users: users.map(user => user.id),
      });
      onSuccess(response.data);
      router.inbox.view(response.data.id).go();

      return response;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [createChat, onSuccess, router]);

  return (
    <ChatForm
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.messaging.create.buttonCreate}
    />
  );
};
