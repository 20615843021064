import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { cacheKeys } from '../config';
import { contactsClient } from '../client/contactClient';

export const useContactCrud = () => {

  const dictionary = useDictionary();
  const { triggerFeedback, genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const createContact = useMutation(contactsClient.createContact, {
    mutationKey: [cacheKeys.createContact],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getContacts]);
      triggerFeedback({
        severity: 'success',
        message: dictionary.feedback.changesSaved,
      });
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const bulkCreateContact = useMutation(contactsClient.bulkCreateContact, {
    mutationKey: [cacheKeys.bulkCreateContact],
    onSuccess: () => {
      queryClient.invalidateQueries([cacheKeys.getContacts]);
    },
    onError: () => {
      // TODO: Uncoment error handling
      // genericErrorFeedback();
    },
  });

  const editContact = useMutation(({ id, ...data }: Contacts.Edit) => contactsClient.editContact({ id, ...data }), {
    mutationKey: [cacheKeys.editContact],
    onSuccess: (data) => {
      queryClient.invalidateQueries([cacheKeys.getContacts]);
      queryClient.invalidateQueries([cacheKeys.getContact, {
        id: data.data.id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const deleteContact = useMutation((id: number) => contactsClient.deleteContact({ id }), {
    mutationKey: [cacheKeys.deleteContact],
    onSuccess: (data, id) => {
      queryClient.invalidateQueries([cacheKeys.getContacts]);
      queryClient.removeQueries([cacheKeys.getContact, {
        id,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    createContact: createContact.mutateAsync,
    bulkCreateContact: bulkCreateContact.mutateAsync,
    editContact: editContact.mutateAsync,
    deleteContact: deleteContact.mutateAsync,
  };
};
