import React from 'react';
import noop from 'lodash/noop';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';

import { useMemberCrud } from '../hooks/useMemberCrud';
import { CommunityMembersForm, FormCrud as CommunityMembersFormData } from './CommunityMembersForm';

export interface Props {
  community: Omit<Communities.Community, 'users'> & {
    users: Communities.ExtendedCommunityUser[];
  };
  onSuccess?: () => void;
}
export const InviteCommunityMembers: React.FC<Props> = ({ community, onSuccess = noop }) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { inviteMember } = useMemberCrud();

  const onSubmit = React.useCallback(async ({ users }: CommunityMembersFormData) => {
    try {

      const promises: Promise<unknown>[] = [];

      users.forEach(user => {
        const isAlreadyInCommunity = community.users.some(it => it.id === user.id);

        if (!isAlreadyInCommunity) {
          promises.push(inviteMember({
            communityId: community.id.toString(),
            handle: community.handle,
            userId: user.id.toString(),
          }));
        }
      });

      await Promise.all(promises);

      onSuccess();
      return true;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [community.handle, community.id, community.users, inviteMember, onSuccess]);

  return (
    <CommunityMembersForm
      defaultValues={{
        users: community.users,
      }}
      fieldProps={{
        users: {
          filters: {
            limit: 6,
          },
        },
      }}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.members.invite.button}
    />
  );
};
