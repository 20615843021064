import React from 'react';
import { Link } from 'react-router-dom';
import noop from 'lodash/noop';
import {
  Box, Button,
  DialogContent,
  DialogTitle,
  IconButton, Paper,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { ArrowForward, Close } from '@mui/icons-material';

import { DialogContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { useRouter } from '@cvt/hooks/useRouter';
import { isNative } from '@shared/helpers/environment';

import { useUserIntegrations } from '@modules/Integrations/hooks/useUserIntegrations';
import { UserContext } from '@modules/Users/contexts';

import { EditTrip } from '../partials/EditTrip';
import { useTrip } from '../hooks/useTrip';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    tripId: number;
  }
}

export const EditTripDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const { asyncConfirmation } = React.useContext(DialogContext);
  const { user } = React.useContext(UserContext);
  const dictionary = useDictionary();
  const router = useRouter();

  const { trip, status } = useTrip({
    id: data?.tripId as number,
  }, {
    enabled: !!data?.tripId,
  });

  const { integrations } = useUserIntegrations({
    userId: user?.id || '',
  }, {
    enabled: !!user?.id,
  });
  
  const onEditRequest = React.useCallback(async (data: Trips.Crud) => {
    const notify = await asyncConfirmation({ title: dictionary.trips.edit.notifyDialogTitle, content: dictionary.trips.edit.notifyDialogDescription, cancelLabel: dictionary.trips.edit.notifyCancelLabel,  confirmLabel: dictionary.trips.edit.notifyConfirmLabel });

    return {
      ...data,
      notify,
    };
  }, [dictionary, asyncConfirmation]);

  const onSuccess = () => {
    onClose();
  };

  if (!data?.tripId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: `calc(100% - env(safe-area-inset-top) - ${isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(3)})`,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.trips.edit.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!integrations?.google && (
          <Box width="100%" component={Paper} bgcolor="background.default" py={1} pl={2} pr={1} mb={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Typography variant="h5" component="h1">Add trips automatically</Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                  color="secondary"
                  variant="contained"
                  component={Link}
                  to={router.integrations.path}
                  endIcon={<ArrowForward/>}
                >
                  Connect
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
        <Box pt={1}>
          {[status].includes('loading') &&  <BodyLoading height={300}/>}
          {[status].every(value => value === 'success') && trip && (
            <EditTrip
              trip={trip}
              onEditRequest={onEditRequest}
              onSuccess={onSuccess}
            />
          )}
        </Box>
      </DialogContent>
    </SwipeableDrawer>
  );
};
