import React, { PropsWithChildren } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Container } from '@mui/material';
import remove from 'lodash/remove';
import concat from 'lodash/concat';

import { DialogContext } from '@cvt/contexts';
import { useQueryState } from '@cvt/hooks/useQueryState';
import { useRouter } from '@cvt/hooks/useRouter';

import { useNotifications } from '@modules/Notifications/hooks/useNotifications';
import { getProximityAlertList, NOTIFICATION_TYPE } from '@modules/Notifications/helpers/notification';
import { useNotificationCrud } from '@modules/Notifications/hooks/useNotificationCrud';
import { cacheKeys } from '@modules/Notifications/config';

import { SwipableCardStack } from '../components/SwipableCardStack';
import { SwipableCard } from '../components/SwipableCard';
import { AlertLargeCard } from '../components/AlertLargeCard';

interface Props {
}

export const SwipableAlerts: React.FC<PropsWithChildren<Props>> = () => {
  const queryClient = useQueryClient();

  const router = useRouter();

  const { openDialog } = React.useContext(DialogContext);

  const { deleteNotification } = useNotificationCrud();
  const { notifications, status } = useNotifications({ limit: 500 });

  const [alertId] = useQueryState('alertId');

  const getUserId = React.useCallback((notification: Notifications.Notification) => {
    switch (notification.type) {
    case NOTIFICATION_TYPE.ConnectionBackInTown:
    case NOTIFICATION_TYPE.PeerInLocationSoon:
      return notification.meta.user;
    case NOTIFICATION_TYPE.NewConfirmedTrip:
      return notification.meta.relatedUser;
    default:
      return null;
    }
  }, []);

  const onReadAlert = React.useCallback(async (alert: Notifications.Notification, resolve?: () => void) => {
    await deleteNotification(alert.id);
    queryClient.invalidateQueries([cacheKeys.getNotifications]);
    resolve && resolve();
  }, [queryClient, deleteNotification]);

  const onOpenChat = React.useCallback(async (notification: Notifications.Notification, resolve: () => void) => {
    const userId = getUserId(notification);

    if (userId) {
      openDialog('userChat', { userId: userId, onComplete: () => onReadAlert(notification, resolve), replace: true });
    }
  }, [getUserId, onReadAlert, openDialog]);

  const alerts: Notifications.Notification[] = React.useMemo(() => {
    const proximityAlerts = getProximityAlertList(notifications);

    if (!alertId) {
      return proximityAlerts;
    }

    // Remove the alert with the matching alertId
    const matchedAlert = remove(proximityAlerts, alert => alert.id.toString() === alertId);

    // Concatenate the matched alert at the beginning
    return concat(matchedAlert, proximityAlerts);
  }, [notifications, alertId]);

  if (status !== 'success') {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%', overflow: 'hidden' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <SwipableCardStack onFinish={() => router.dashboard.go()}>
          {alerts.map(alert => (
            <SwipableCard
              key={alert.id}
              onSwipeRight={() => {
                return new Promise<void>((resolve) => {
                  onOpenChat(alert, resolve);
                });
              }}
              onSwipeLeft={() => onReadAlert(alert)}
              onClick={() => openDialog('viewUser', { userId: getUserId(alert) })}
            >
              <AlertLargeCard notification={alert}/>
            </SwipableCard>
          ))}
        </SwipableCardStack>
      </Box>
    </Container>
  );
};
