import isEmpty from 'lodash/isEmpty';
import { getFormDataFromObject } from '@cvt/helpers/form';
import { camelizeObject, snakizeObject } from '@cvt/helpers/object';
import { mapTripsData } from '@modules/Trips/client/tripClient.formatter';
import { getPictures } from '@shared/helpers/media';
import { isToday } from 'date-fns';

export const mapUserData = (data: Users.UserApi): Users.User => {
  // @ts-ignore
  const { company, ...userData } = data;
  const user = camelizeObject<Users.User>(userData);
  const profilePictures = getPictures(user.profilePicture);

  let trips: Trips.Trip[] = [];

  let todayLocation = user.baseLocation;

  if (data.trips) {
    if (Array.isArray(data.trips)) {
      trips = mapTripsData(data.trips);
    } else if (!isEmpty(data.trips)) {
      trips = mapTripsData([data.trips]);
    }
  }

  const trip = trips[0] || null;

  if (trip && isToday(trip.arrivalDatetime)) {
    todayLocation = trip.location;
  }

  return {
    ...user,
    displayName: `${user.firstName} ${user.lastName}`,
    profilePicture: profilePictures?.[100],
    profilePictureSizes: profilePictures,
    trips: trips,
    trip: trip,
    todayLocation,
  };
};

export const mapUserCrudData = ({ profilePicture, ...data }: Partial<Users.Crud>): FormData => {
  const apiData: Users.CrudApi = snakizeObject(data);

  const formData = getFormDataFromObject({
    ...apiData,
    profile_picture: typeof profilePicture === 'string' ? undefined : profilePicture,
  });

  return formData;
};
