import { isWithinInterval, sub, addDays, isAfter } from 'date-fns';

export const dateInCurrentTimezone = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const isCreatedRecently = (date: Date) => {
  const now = new Date();
  const oneDayAgo = sub(now, { days: 1 });

  return isWithinInterval(date, {
    start: oneDayAgo,
    end: now,
  });
};

export const getDateDotColor = (arrivalDatetime: Date, departureDatetime: Date) => {
  const today = dateInCurrentTimezone(new Date());
  const sevenDaysDate = addDays(today, 7);
  const fourteenDaysDate = addDays(today, 14);
  const normalizedArrivalDate = dateInCurrentTimezone(arrivalDatetime);
  const normalizeDepartureDate = dateInCurrentTimezone(departureDatetime);

  if (isWithinInterval(normalizedArrivalDate, { start: today, end: sevenDaysDate }) || isWithinInterval(normalizeDepartureDate, { start: today, end: sevenDaysDate })) {
    return 'error';
  }

  if (isWithinInterval(normalizedArrivalDate, { start: sevenDaysDate, end: fourteenDaysDate }) || isWithinInterval(normalizeDepartureDate, { start: sevenDaysDate, end: fourteenDaysDate })) {
    return 'warning';
  }

  if (isAfter(normalizedArrivalDate, fourteenDaysDate) || isAfter(normalizeDepartureDate, fourteenDaysDate)) {
    return 'success';
  }
};
