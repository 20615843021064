import React from 'react';
import {
  Avatar,
  List,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { LocationCity } from '@mui/icons-material';

import { DialogContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';

import { useSearchUsers } from '@modules/Users/hooks/useSearchUsers';
import { useTrips } from '@modules/Trips/hooks/useTrips';
import { UserContext } from '@modules/Users/contexts';
import { useContacts } from '@modules/Contacts/hooks/useContacts';
import { useAddressSearch } from '@modules/Locations/hooks/useAddressSearch';
import { useCommunities } from '@modules/Communities/hooks/useCommunities';
import { UserGroupAvatar } from '@modules/Users/components/UserGroupAvatar';

type Props = {
  search: string;
  onOpenResult?: () => void;
}

export const TopSearchResults: React.FC<Props> = ({ search, onOpenResult }) => {

  const { openDialog } = React.useContext(DialogContext);
  const { user } = React.useContext(UserContext);
  const dictionary = useDictionary();
  const router = useRouter();

  const { status: usersStatus, users } = useSearchUsers({
    search,
    limit: 3,
  });

  const { status: tripStatus, trips } = useTrips({
    search,
    user: user?.id,
    limit: 3,
  });

  const { status: contactsStatus, contacts } = useContacts({
    search,
    user: user?.id,
    limit: 3,
    ordering: 'first_name',
  });

  const { status: communitiesStatus, communities } = useCommunities({
    name: search,
    limit: 3,
  });

  const { status: locationStatus, results: locations } = useAddressSearch(search);

  const isLoading = [usersStatus, communitiesStatus, tripStatus, contactsStatus, locationStatus].some(status => status === 'loading');

  const _openDialog = React.useCallback((dialog: CVT.Dialogs.Dialog, options: CVT.Dialogs.Options) => {
    onOpenResult && onOpenResult();
    openDialog(dialog, options);
  }, [onOpenResult, openDialog]);

  return (
    <React.Fragment>
      {isLoading ? (
        <BodyLoading height="70vh" />
      ) : (
        <React.Fragment>
          {users.length === 0 && trips.length === 0 && contacts.length === 0 && locations.length === 0 && (
            <MessageFeedbackView height="100px" message="No results" />
          )}
          {users.length > 0 && (
            <List
              subheader={(
                <Typography variant="body2" color="secondary.light">{dictionary.search.users}</Typography>
              )}
            >
              {users?.map(user => (
                <ListItemButton disableGutters component="li" key={user.id} onClick={() => [onOpenResult && onOpenResult(), router.users.view(user.id).go()]}>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar src={user.profilePictureSizes?.[30]} alt={user.displayName} sx={{ width: 30, height: 30 }} />
                      <Typography variant="body1" component="h1">{user.displayName}</Typography>
                    </Stack>
                    <Typography variant="body2" component="span" color="secondary.light" noWrap>{user.baseLocation?.title}</Typography>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          )}
          {communities.length > 0 && (
            <List
              subheader={(
                <Typography variant="body2" color="secondary.light">{dictionary.search.communities}</Typography>
              )}
            >
              {communities?.map(community => (
                <ListItemButton disableGutters component="li" key={community.id} onClick={() => [onOpenResult && onOpenResult(), router.communities.view(community.handle || community.id).go()]}>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <UserGroupAvatar users={community.users.filter(user => user.status === 'active')} />
                      <Typography variant="body1" component="h1">{community.name}</Typography>
                    </Stack>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          )}
          {trips.length > 0 && (
            <List
              subheader={(
                <Typography variant="body2" color="secondary.light">{dictionary.trips.list.title}</Typography>
              )}
            >
              {trips?.map(trip => (
                <ListItemButton disableGutters component="li" key={trip.id} onClick={() => _openDialog('editTrip', { tripId: trip.id })}>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" component="h1">{trip.location.title}</Typography>
                    </Stack>
                    <Typography variant="body2" component="span" color="secondary.light" noWrap>{trip.description}</Typography>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          )}
          {contacts.length > 0 && (
            <List
              subheader={(
                <Typography variant="body2" color="secondary.light">{dictionary.contacts.list.title}</Typography>
              )}
            >
              {contacts?.map(contact => (
                <ListItemButton disableGutters component="li" key={contact.id} onClick={() => _openDialog('viewContact', { contactId: contact.id })}>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="body1" component="h1">{contact.displayName}</Typography>
                    </Stack>
                    <Typography variant="body2" component="span" color="secondary.light" noWrap>{contact.location?.title}</Typography>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          )}
          {locations.length > 0 && (
            <List
              subheader={(
                <Typography variant="body2" color="secondary.light">{dictionary.search.locations}</Typography>
              )}
            >
              {locations?.slice(0, 3).map((location: any) => (
                <ListItemButton disableGutters component="li" key={location.id} onClick={() => [onOpenResult && onOpenResult(), router.home.go({ lat: location.location.lat, lng: location.location.lng })]}>
                  <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar alt={location.name} sx={{ width: 30, height: 30 }}>
                        <LocationCity/>
                      </Avatar>
                      <Typography variant="body1" component="h1">{location.name}</Typography>
                    </Stack>
                    <Typography variant="body2" component="span" color="secondary.light" noWrap>{location.state}</Typography>
                  </Stack>
                </ListItemButton>
              ))}
            </List>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
