import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

export const mapEventsData = (data: Events.EventApi[]): Events.Event[] => {
  return data.map(mapEventData);
};

export const mapEventData = (data: Events.EventApi): Events.Event => {
  const startDatetime = utcToZonedTime(data.start_datetime, data.event_tz);
  const endDatetime = utcToZonedTime(data.end_datetime, data.event_tz);
  return {
    ...camelizeObject<Events.Event>(data),
    startDatetime,
    endDatetime,
  };
};

export const mapEventCrudData = (data: Events.Crud): Events.CrudApi => ({
  ...snakizeObject(data),
  start_datetime: format(data.startDatetime, 'yyyy-MM-dd\'T\'HH:mm:ss'),
  end_datetime: format(data.endDatetime, 'yyyy-MM-dd\'T\'HH:mm:ss'),
});
