import React from 'react';
import { useDebounce } from 'react-use';
import {
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { isNative } from '@shared/helpers/environment';
import { TopSearchResults } from '@shared/views/Search/partials/TopSearchResults';

type Props = {
  open: boolean;
  onClose: () => void;
}

export const SearchDialog: React.FC<Props> = ({ open, onClose }) => {

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [shouldClose, setShouldClose] = React.useState(false);

  const shouldShowSearch = React.useMemo(() => search?.length > 2, [search]);

  useDebounce(
    () => setSearch(value),
    400,
    [value],
  );

  useDebounce(
    () => {
      if (shouldClose) {
        setShouldClose(false);
        onClose();
      }
    },
    100,
    [shouldClose],
  );

  React.useEffect(() => {
    if (open) {
      inputRef.current?.focus();
    } else {
      setValue('');
    }
  }, [open]);

  return (
    <Drawer
      keepMounted={true}
      transitionDuration={0}
      anchor="top"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          top: isNative() ? 'env(safe-area-inset-top)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <TextField
          fullWidth
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          inputProps={{
            ref: inputRef,
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="close"
                onClick={onClose}
                color="secondary"
              >
                <Close/>
              </IconButton>
            ),
          }}
        />
      </DialogTitle>
      {shouldShowSearch && (
        <DialogContent>
          <TopSearchResults search={search} onOpenResult={() => setShouldClose(true)} />
        </DialogContent>
      )}
    </Drawer>
  );
};
