import React, { PropsWithChildren } from 'react';
import { Box, Container } from '@mui/material';
import remove from 'lodash/remove';
import concat from 'lodash/concat';

import { useQueryState } from '@cvt/hooks/useQueryState';
import { DialogContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';

import { useTrips } from '@modules/Trips/hooks/useTrips';

import { TripLargeCard } from '../components/TripLargeCard';
import { SwipableCard } from '../components/SwipableCard';
import { SwipableCardStack } from '../components/SwipableCardStack';

interface Props extends Partial<Trips.ListParams> {
}

export const SwipableTrips: React.FC<PropsWithChildren<Props>> = (params) => {
  const { openDialog } = React.useContext(DialogContext);
  
  const router = useRouter();

  const [ownTrips] = useQueryState('ownTrips');
  const [user] = useQueryState('user');
  const [tripId] = useQueryState('tripId');

  const { status, trips } = useTrips({
    limit: 500,
    ownTrips: Boolean(ownTrips),
    user: user ? parseInt(user, 10) : undefined,
    ...params,
  });

  const filteredTrips: Trips.Trip[] = React.useMemo(() => {
    if (!tripId) {
      return trips;
    }
    const currTrips = [...trips];

    // Remove the trip with the matching tripId
    const matchedTrip = remove(currTrips, trip => trip.id.toString() === tripId);

    // Concatenate the matched trip at the beginning
    return concat(matchedTrip, currTrips);
  }, [trips, tripId]);

  if (status !== 'success') {
    return null;
  }

  return (
    <Container maxWidth="sm" sx={{ height: '100%', overflow: 'hidden' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <SwipableCardStack onFinish={() => router.dashboard.go()}>
          {filteredTrips.map(trip => (
            <SwipableCard
              key={trip.id}
              onClick={() => openDialog('editTrip', { tripId: trip.id })}
              onSwipeRight={() => {
                return new Promise((resolve) => {
                  openDialog('tripNotificationEdit', { tripId: trip.id.toString(), onComplete: resolve, replace: true });
                });
              }}
            >
              <TripLargeCard trip={trip} />
            </SwipableCard>
          ))}
        </SwipableCardStack>
      </Box>
    </Container>
  );
};
