import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';

const notificationsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getUserIntegrations = (params: Integrations.GetUserIntegrationsParams): Promise<AxiosResponse<Integrations.UserIntegrations>> => {
  return request({
    options: {
      url: `${notificationsApiBaseUrl}/users/${params.userId}/integrations/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Integrations.UserIntegrationsApi>) => ({
    ...data,
    data: {
      google: data.data.google.granted_scopes.length > 0,
    },
  }));
};

export const integrationsClient = {
  getUserIntegrations,
};
