import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';

import { useCommunity } from '../hooks/useCommunity';
import { useMembers } from '../hooks/useMembers';
import { InviteCommunityChannelMembers } from '../partials/InviteCommunityChannelMembers';
import { useCommunityChannel } from '../hooks/useCommunityChannel';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    communityId: string;
    channelId: string;
  }
}

export const InviteCommunityChannelMembersDialog: React.FC<Props> = ({ open, onClose, data }) => {

  const dictionary = useDictionary();

  const { community, status: communityStatus } = useCommunity({
    id: data?.communityId as string,
  }, {
    enabled: !!data?.communityId,
  });

  const { members, status: membersStatus } = useMembers({
    communityId: data?.communityId as string,
  }, {
    enabled: !!data?.communityId,
  });

  const { channel, status: channelStatus } = useCommunityChannel({
    id: data?.channelId as string,
    communityId: data?.communityId as string,
  }, {
    enabled: !!data?.communityId && !!data?.channelId,
  });

  if (!data?.communityId || !data?.channelId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top))',
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">{dictionary.communities.members.invite.title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {([communityStatus, membersStatus, channelStatus].includes('loading') || !community || !channel) &&  <BodyLoading height={300}/>}
        {[communityStatus, membersStatus, channelStatus].every(value => value === 'success') && community && channel && (
          <InviteCommunityChannelMembers
            community={{
              ...community,
              users: members,
            }}
            channel={channel}
            onSuccess={onClose}
          />
        )}
      </DialogContent>
    </SwipeableDrawer>
  );
};
