import React from 'react';
import { Box, Stack } from '@mui/material';

import { Authenticated } from '@modules/Auth/components';

import { LayoutContext } from '@shared/contexts/LayoutContext/LayoutContext';
import { isNative } from '@shared/helpers/environment';

import { Header } from './Header';
import { NavigationSidebar } from './NavigationSidebar';
import { MobileNavigation } from './BottomNavigation';


export const MainLayout: React.FC<React.PropsWithChildren> = ({ children }) => {

  const { navigationOpen, setNavigationOpen } = React.useContext(LayoutContext);

  const openNavigation = () => {
    setNavigationOpen(true);
  };

  const closeNavigation = () => {
    setNavigationOpen(false);
  };
  
  return (
    <Box height="100vh" sx={{ paddingTop: isNative() ? 'env(safe-area-inset-top)' : 0 }}>
      <Stack direction="row" height="100%">
        <Authenticated>
          <NavigationSidebar open={navigationOpen} onOpenRequest={openNavigation} onCloseRequest={closeNavigation} />
        </Authenticated>
        <Stack component="main" display="flex" width="100%" height="100%" direction="column">
          <Header />
          <Box height="100%" overflow="auto">
            {children}
          </Box>
          <Authenticated>
            <MobileNavigation/>
          </Authenticated>
        </Stack>
      </Stack>
    </Box>
  );
};
