export const cacheKeys = {
  getUser: 'getUser',
  getUsers: 'getUsers',
  getSearchUsers: 'getSearchUsers',
  getInvite: 'getInvite',
  createUser: 'createUser',
  editUser: 'editUser',
  deleteUser: 'deleteUser',
  inviteUser: 'inviteUser',
};
