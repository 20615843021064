import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
} from '@mui/material';
import { Circle } from '@mui/icons-material';

import { darkBlue } from '@shared/theme/palette';
import { getDateDotColor } from '@shared/helpers/date';

import { formatTripDates } from '@modules/Trips/helpers/date';
import { UserAvatar } from '@modules/Users/components/UserAvatar';

interface Props extends BoxProps {
  user: Users.User;
  hideShadow?: boolean;
  isFront?: boolean;
  locationId?: string;
}

export const UserCard: React.FC<Props> = ({ user, hideShadow = false, isFront = false, locationId, ...props }) => {

  const isTripHere = React.useMemo(() => {
    if (user.trip) {
      return user.trip.location.id.toString() === locationId;
    }
    return false;
  }, [user.trip, locationId]);

  const circleColor = React.useMemo(() => {
    return user.trip ? getDateDotColor(user.trip.arrivalDatetime, user.trip.departureDatetime) : undefined;
  }, [user.trip]);

  return (
    <Box
      {...props}
      width="100%"
      border={1}
      bgcolor="secondary.main"
      borderColor="secondary.dark"
      sx={theme => ({
        p: 1.5,
        borderRadius: 3,
        boxShadow: hideShadow ? undefined : theme.shadows[6],
        color: theme.palette.mode === 'light' ? darkBlue : theme.palette.text.secondary,
        aspectRatio: '4 / 2',
        display: 'flex',
      })}
    >
      {isFront ? (
        <Stack width="100%" direction="column">
          <Stack direction="row" alignItems="center" spacing={1} height="100%" pt={1.5}>
            {user && (
              <UserAvatar user={user} sx={{ width: 80, height: 80 }} />
            )}
            <Stack overflow="hidden">
              <Typography variant="h1" fontWeight={500} fontFamily="Roboto" letterSpacing={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {user?.displayName}
              </Typography>
            </Stack>
          </Stack>
          {user.trip?.arrivalDatetime && user.trip?.departureDatetime && (
            <React.Fragment>
              {isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  <Typography variant="body3" fontWeight={500} color="primary.light">
                    {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
              {!isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  <Typography variant="body3" fontWeight={500} color="primary.light">
                    Leaving {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
            </React.Fragment>
          )}
        </Stack>
      ) : (
        <Stack width="100%" mt="auto" direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" spacing={1}>
            {user && (
              <UserAvatar user={user} sx={{ width: 28, height: 28 }} />
            )}
            <Stack overflow="hidden">
              <Typography variant="body1" fontWeight={500} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {user?.displayName}
              </Typography>
            </Stack>
          </Stack>
          {user.trip?.arrivalDatetime && user.trip?.departureDatetime && (
            <React.Fragment>
              {isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  <Typography variant="body3" fontWeight={500} color="primary.light">
                    {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
              {!isTripHere && (
                <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
                  <Typography variant="body3" fontWeight={500} color="primary.light">
                    Leaving {formatTripDates(user.trip?.arrivalDatetime, user.trip?.departureDatetime)}
                  </Typography>
                  {circleColor && (
                    <Circle fontSize="inherit" color={circleColor}/>
                  )}
                </Stack>
              )}
            </React.Fragment>
          )}
        </Stack>
      )}
    </Box>
  );
};

export const UserCardSkeleton: React.FC = () => {
  return (
    <Box
      width="100%"
      border={1}
      bgcolor="secondary.main"
      borderColor="secondary.dark"
      sx={theme => ({
        p: 1.5,
        borderRadius: 3,
        color: theme.palette.mode === 'light' ? darkBlue : theme.palette.text.secondary,
        boxShadow: theme.shadows[6],
        aspectRatio: '4 / 2',
        display: 'flex',
      })}
    />
  );
};
