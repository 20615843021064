import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const Logo: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 728 282">
      <path d="M727.666 270.17V275H703.949V270.17H727.666ZM705.148 230.211V275H699.211V230.211H705.148ZM724.528 249.468V254.297H703.949V249.468H724.528ZM727.358 230.211V235.071H703.949V230.211H727.358Z" />
      <path d="M655.98 230.211H670.808C674.171 230.211 677.011 230.724 679.329 231.749C681.667 232.774 683.44 234.292 684.65 236.302C685.881 238.291 686.496 240.742 686.496 243.654C686.496 245.705 686.076 247.581 685.235 249.283C684.415 250.965 683.225 252.4 681.667 253.59C680.128 254.759 678.283 255.63 676.129 256.205L674.468 256.851H660.533L660.472 252.021H670.992C673.125 252.021 674.899 251.652 676.314 250.914C677.729 250.155 678.795 249.14 679.513 247.868C680.231 246.597 680.59 245.192 680.59 243.654C680.59 241.931 680.251 240.424 679.575 239.132C678.898 237.84 677.832 236.845 676.375 236.148C674.94 235.43 673.084 235.071 670.808 235.071H661.917V275H655.98V230.211ZM682.159 275L671.269 254.697L677.452 254.667L688.496 274.631V275H682.159Z" />
      <path d="M644.218 270.17V275H620.501V270.17H644.218ZM621.701 230.211V275H615.764V230.211H621.701ZM641.081 249.468V254.297H620.501V249.468H641.081ZM643.911 230.211V235.071H620.501V230.211H643.911Z" />
      <path d="M595.634 249.468V254.297H571.394V249.468H595.634ZM572.317 230.211V275H566.38V230.211H572.317ZM600.802 230.211V275H594.896V230.211H600.802Z" />
      <path d="M540.929 230.211V275H535.084V230.211H540.929ZM555.326 230.211V235.071H520.719V230.211H555.326Z" />
      <path d="M37.4576 15.9785H1.0154C1.00695 79.1609 1.00058 142.343 0.978516 205.526C0.978516 205.526 0.978275 213.5 2.54513 226.233C5.96198 254 28.695 274.349 63.3198 280.573C75.5 282 81.3126 280.937 90.1464 280.554C138.127 275.029 156.452 238.282 153.981 198.777H136.095H117.479C117.479 201.718 117.514 204.03 117.474 206.342C117.052 230.304 104.542 244.33 81.8293 246.325C58.0525 248.413 42.2037 237.053 38.3627 215.004C37.7375 211.415 37.5387 207.713 37.5294 204.063C37.4608 177.066 37.4901 150.069 37.4886 123.072L37.4886 117.762H142.918V83.7171H37.4576V15.9785Z" />
      <path d="M206.742 0.20384C218.958 -0.0820707 230.947 -0.0178257 243.392 0.0989633C243.848 37.6282 243.848 75.1049 243.848 112.949C244.482 112.596 244.951 112.495 245.133 112.21C261.038 87.2696 284.339 77.7197 313.171 78.7131C356.767 80.215 389.606 110.123 394.944 154.027C395.926 162.104 396.419 170.285 396.465 178.423C396.638 209.418 396.536 240.415 396.537 271.411C396.537 272.723 396.537 274.035 396.537 275.638C384.122 275.638 372.055 275.638 359.482 275.638C359.482 273.828 359.482 272.038 359.482 270.247C359.483 240.084 359.819 209.915 359.299 179.761C359.124 169.576 358.091 159.032 355.166 149.341C348.268 126.486 331.268 115.368 308.078 113.339C284.187 111.248 261.901 119.771 250.841 142.172C245.576 152.834 243.825 164.262 243.834 176.052C243.859 208.048 243.838 240.044 243.835 272.04C243.835 273.174 243.835 274.308 243.835 275.683C231.383 275.683 219.28 275.683 206.515 275.683C206.515 184.022 206.515 92.2882 206.742 0.20384Z" />
      <path d="M514.921 115.682C498.007 121.258 490.512 133.791 488.097 150.05C487.293 155.46 487.065 160.996 487.051 166.476C486.96 201.135 487.004 235.795 487.003 270.455C487.003 272.098 487.003 273.741 487.003 275.665C474.663 275.665 462.597 275.665 450.193 275.665C450.13 274.264 450.008 272.822 450.008 271.38C449.995 234.054 449.952 196.728 450.017 159.402C450.04 146.305 452.302 133.569 457.534 121.509C466 101.992 480.751 89.2577 500.93 83.1281C521.136 76.9902 541.74 76.7149 562.027 82.4057C592.825 91.0451 608.983 112.735 613.928 143.516C615.029 150.372 615.5 156.5 615.767 164.74C603.298 164.74 591.231 164.74 579 164.74C578.406 159.125 578.233 153.322 577.122 147.704C572.817 125.95 559.281 114.125 536.993 113.291C529.8 113.022 522.172 113.291 514.921 115.682Z" />
    </SvgIcon>
  );
};
