import * as React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Toolbar } from '@mui/material';

import { useRouter } from '@cvt/hooks/useRouter';

import { Logo } from '@shared/components/Logo';
import { SettingsContext } from '@shared/contexts';


export const SimpleHeader: React.FC = () => {

  const { drawerWidth, sidebar } = React.useContext(SettingsContext);

  const router = useRouter();

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="transparent"
    >
      <Toolbar>
        <Box component={Link} to={router.home.path} lineHeight={1} width="100%" textAlign="center" sx={{ pl:  { sm: sidebar === 'open' ? `${drawerWidth}px` : '55px' }, transition: '0.4s padding-left ease-in-out' }}>
          <Logo sx={{ width: 'auto', height: 24 }}/>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
