import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { PictureUploader } from '@shared/components/PictureUploader';
import { communityHandleRegex } from '../helpers/validation';
import { communitiesClient } from '../client/communitiesClient';

export type FormCrud = Communities.Crud;

type Fields = keyof (FormCrud & {
  submit: boolean;
});


export interface Props {
  defaultValues?: Partial<FormCrud>;
  onSubmitRequest: (values: FormCrud) => void;
  onSubmitButtonText: string;
  disabledFields?: Array<Fields>;
  hiddenFields?: Array<Fields>;
}

const DEFAULT_VALUES: Partial<FormCrud> = {
  name: '',
  description: '',
};


export const CommunityForm: React.FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText,  disabledFields, hiddenFields }) => {
  const dictionary = useDictionary();

  const { handleSubmit, control, formState: { isSubmitting, isDirty } } = useForm<FormCrud>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldDisabled = React.useCallback((field: Fields) => {
    if (!disabledFields) {
      return false;
    }
    return disabledFields.indexOf(field) !== -1;
  }, [disabledFields]);

  const isFieldVisible = React.useCallback((field: Fields) => {
    if (!hiddenFields) {
      return true;
    }
    return hiddenFields.indexOf(field) === -1;
  }, [hiddenFields]);

  const onSubmit = React.useCallback(async (data: FormCrud) => {
    try {
      await onSubmitRequest(data);
    } catch (err) {
      console.error(err);
    }
  }, [onSubmitRequest]);

  const validateHandle = React.useCallback(async (value: string) => {
    if (!communityHandleRegex.test(value)) {
      return dictionary.forms.validations.invalidCommunityHandle;
    }

    try {
      const { data: community } = await communitiesClient.getCommunity({ id: value });
      if (community.handle !== defaultValues.handle) {
        return dictionary.forms.validations.communityHandleTaken;
      }

      return true;
    } catch (e) {
      return true;
    }
  }, [defaultValues, dictionary]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
      <Grid height="100%" container spacing={3}>
        {isFieldVisible('communityPicture') && (
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Controller
              name="communityPicture"
              control={control}
              render={({ field }) => (
                <Box display="flex" justifyContent="center">
                  <PictureUploader
                    {...field}
                    disabled={isFieldDisabled('communityPicture')}
                    label={dictionary.forms.community.fieldPicture}
                  />
                </Box>
              )}
            />
          </Grid>
        )}
        {isFieldVisible('name') && (
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.community.fieldName}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('name')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('handle') && (
          <Grid item xs={12}>
            <Controller
              name="handle"
              control={control}
              rules={{
                validate: validateHandle,
                required: dictionary.forms.validations.required,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.community.fieldHandle}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('handle')}
                  size="small"
                  onChange={(e) => field.onChange(e.target.value.toLowerCase())}
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('description') && (
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  multiline
                  rows={3}
                  label={dictionary.forms.community.fieldDescription}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  disabled={isFieldDisabled('description')}
                  size="small"
                />
              )}
            />
          </Grid>
        )}
        {isFieldVisible('submit') && (
          <Grid mt="auto" item xs={12}>
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={isFieldDisabled('submit') || !isDirty}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    </form>
  );
};
