import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

import { useRouter } from '@cvt/hooks/useRouter';

import { useUsers } from '@modules/Users/hooks/useUsers';

import { UserCard, UserCardSkeleton } from '../components/UserCard';
import { StackedCards } from './StackedCards';
import backgroundLight from './usersLight.svg';
import backgroundDark from './usersDark.svg';

interface Props extends BoxProps {
  params?: Partial<Users.ListParams>;
  onClick?: (params: any) => void;
}

export const StackedUsers: React.FC<Props> = ({ params, onClick, ...props }) => {

  const router = useRouter();

  const { users, status } = useUsers({
    limit: 500,
    ...params,
  });

  if (status === 'loading') {
    return (
      <StackedCards>
        <UserCardSkeleton/>
        <UserCardSkeleton/>
        <UserCardSkeleton/>
      </StackedCards>
    );
  }

  if (status === 'success' && !users.length) {
    return (
      <Box
        width="100%"
        border={1}
        bgcolor="secondary.main"
        borderColor="secondary.dark"
        sx={theme => ({
          zIndex: users.length + 1,
          p: 2,
          borderRadius: 3,
          boxShadow: theme.shadows[6],
          aspectRatio: '4 / 2',
          backgroundImage: `url(${theme.palette.mode === 'light' ? backgroundDark : backgroundLight})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        })}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          router.spheres.tab('contacts').go();
          return false;
        }}
      >
        <Typography variant="h1" fontWeight={500} color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>No One Around</Typography>
        <Typography variant="body2" color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>Invite your network</Typography>
      </Box>
    );
  }

  return (
    <Box position="relative" {...props}>
      <StackedCards>
        <Box
          position="absolute"
          top={0}
          width="100%"
          border={1}
          bgcolor="secondary.main"
          borderColor="secondary.dark"
          sx={theme => ({
            zIndex: users.length + 1,
            p: 2,
            borderRadius: 3,
            boxShadow: theme.shadows[6],
            aspectRatio: '4 / 2',
            backgroundImage: `url(${theme.palette.mode === 'light' ? backgroundDark : backgroundLight})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          })}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => onClick && onClick({})}
        >
          <Typography variant="h1" fontWeight={500} color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>{users.length} People Around</Typography>
        </Box>
        {users.map((user) => (
          <UserCard
            key={user.id}
            user={user}
            locationId={params?.location?.toString()}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClick && onClick({ userId: user.id });
              return null;
            }}
          />
        ))}
      </StackedCards>
    </Box>
  );
};
