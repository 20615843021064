import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { locationClient } from '../clients/locationClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useIpGeoLocation = (options: Partial<Options> = defaultOptions) => {
  const { data: { data: location } = {}, status, error } = useQuery(
    [cacheKeys.getIpLocation],
    () => locationClient.getIpAddressGeoLocation(),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    location,
  };
};
