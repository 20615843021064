import React, { FC } from 'react';
// TODO: Remove react-spinners
import { BounceLoader } from 'react-spinners';
import { Grid, useTheme } from '@mui/material';

type Props = {
  size?: number;
  height?: any;
}

export const BodyLoading: FC<Props> = ({ size = 100, height = 'calc(100vh - 150px)' }) => {
  const theme = useTheme();

  return (
    <Grid
      container
      height={height}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <BounceLoader color={theme.palette.primary.light} size={size}/>
    </Grid>
  );
};
