import { initializeApp, FirebaseOptions } from 'firebase/app';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithRedirect,
  signInWithPopup,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  sendPasswordResetEmail,
  AuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
  initializeAuth,
  indexedDBLocalPersistence,
  signInWithCredential,
  updateProfile,
  signInWithPhoneNumber,
  linkWithPhoneNumber,
  updatePhoneNumber,
  PhoneAuthProvider,
} from 'firebase/auth';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { isNative } from '@shared/helpers/environment';

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE__CVT_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE__CVT_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE__CVT_FIREBASE_DB_URL,
  projectId: import.meta.env.VITE__CVT_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE__CVT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE__CVT_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE__CVT_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE__CVT_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

const whichAuth = () => {
  if (isNative()) {
    return initializeAuth(app, {
      persistence: indexedDBLocalPersistence,
    });
  }

  return getAuth(app);
};

export const firebaseClient = {
  getAuth: whichAuth,
  auth: {
    createUserWithEmailAndPassword: (email: string, password: string) => createUserWithEmailAndPassword(whichAuth(), email, password),
    signInWithNative: async (provider: AuthProvider) => {
      if (provider instanceof GoogleAuthProvider) {
        const result = await FirebaseAuthentication.signInWithGoogle();
        const credential = GoogleAuthProvider.credential(result.credential?.idToken, result.credential?.accessToken);
        return signInWithCredential(whichAuth(), credential);
      }
      if (provider instanceof OAuthProvider && provider.providerId === 'apple.com') {
        const result = await FirebaseAuthentication.signInWithApple({
          skipNativeAuth: true,
        });

        if (result.credential) {
          const credential = provider.credential({
            idToken: result.credential.idToken,
            rawNonce: result.credential.nonce,
            accessToken: result.credential.accessToken,
          });
        
          const userCredential = await signInWithCredential(whichAuth(), credential);

          if (result.user) {
            await updateProfile(userCredential.user, { displayName: result.user?.displayName });
          }
      
          return userCredential;
        }

        return Promise.reject('Something went wrong');
      }
      if (provider instanceof FacebookAuthProvider) {
        try {
          const result = await FirebaseAuthentication.signInWithFacebook();
          if (result.credential?.accessToken) {
            const credential = FacebookAuthProvider.credential(result.credential.accessToken);
            return signInWithCredential(whichAuth(), credential);
          }
          return Promise.reject('Something went wrong');
        } catch(err) {
          return Promise.reject('Something went wrong');
        }
      }
      return Promise.reject('Provider not supported');
    },
    signInWithRedirect: (provider: AuthProvider) => signInWithRedirect(whichAuth(), provider),
    signInWithPopup: (provider: AuthProvider) => signInWithPopup(whichAuth(), provider),
    signInWithEmailAndPassword: (email: string, password: string) => signInWithEmailAndPassword(whichAuth(), email, password),
    signInWithCustomToken: (token: string) => signInWithCustomToken(whichAuth(), token),
    sendPasswordResetEmail: (email: string) => sendPasswordResetEmail(whichAuth(), email),
    signInWithPhoneNumber: (number: string) => signInWithPhoneNumber(whichAuth(), number, window.recaptchaVerifier),
    // @ts-ignore
    linkWithPhoneNumber: (number: string) => linkWithPhoneNumber(whichAuth().currentUser, number, window.recaptchaVerifier),
    verifyPhoneNumber: (number: string) => new PhoneAuthProvider(whichAuth()).verifyPhoneNumber(number, window.recaptchaVerifier),
    updateLinkedPhoneNumber: (verificationId: string, verificationCode: string) => {
      const phoneCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
      // @ts-ignore
      return updatePhoneNumber(whichAuth().currentUser, phoneCredential);
    },
    signOut: () => signOut(whichAuth()),
    OAuthProvider,
    GoogleAuthProvider,
    TwitterAuthProvider,
    FacebookAuthProvider,
  },
};