import { Theme } from '@mui/material';

import config from '../../config';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    transparent: true;
  }
}

const Paper = (theme: Theme) => {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.default,
        },
      },
      defaultProps: {
        elevation: config.theme.elevation,
      },
      variants: [{
        props: { variant: 'transparent' },
        style: {
          backgroundColor: 'transparent',
        },
      }],
    },
  };
};

export default Paper;
