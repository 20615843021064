import React from 'react';
import { Badge, Box, ImageList, ImageListItem } from '@mui/material';

import { SettingsContext } from '@shared/contexts';

interface Props {
  users: Pick<Users.User, 'profilePicture' | 'profilePictureSizes' | 'displayName' | 'id'>[],
  count?: number;
  size?: number;
}

export const UserGroupAvatar: React.FC<Props> = ({ count, users, size = 35 }) => {
  const { mode } = React.useContext(SettingsContext);
  const imageUsers = React.useMemo(() => {
    const calculateGrid = (total: number, idx: number) => {
      let cols = 2;
      let rows = 2;
      if (total === 2) {
        cols = 1;
      }
      if (total > 2) {
        cols = 1;
        rows = 1;
      }
      if (total === 3 && idx === 2) {
        cols = 2;
      }
      return {
        cols,
        rows,
      };
    };

    const list = users.filter(user => !!user.profilePicture).slice(0, 4);
    return list.map((user, idx) => ({
      ...calculateGrid(list.length, idx),
      user,
    }));
  }, [users]);

  const badgeSize = size * .6;

  return (
    <Badge badgeContent={imageUsers.length > 0 ? count : 0} color={mode === 'dark' ? 'primary' : 'secondary'} sx={{ '& .MuiBadge-badge': { top: '50%', translateY: '-50%', width: badgeSize, minWidth: badgeSize, height: badgeSize, borderRadius: '50%' } }}>
      <Box width={size} height={size} borderRadius="50%" overflow="hidden" bgcolor={theme =>
        imageUsers.length > 0 ?
          theme.palette.mode === 'light' ? 'common.white' : theme.palette.grey[300]
          :
          theme.palette.mode === 'light' ? 'secondary.main' : theme.palette.primary.light
      }>
        {imageUsers.length > 0 && (
          <ImageList cols={imageUsers.length > 0 ? 2 : 0} rowHeight={size / 2} gap={1} variant="quilted" sx={{ m: 0 }}>
            {imageUsers.map(item => (
              <ImageListItem key={item.user.id} cols={item.cols} rows={item.rows}>
                <img
                  src={item.user.profilePictureSizes?.[30]}
                  alt={item.user.displayName}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        )}
      </Box>
    </Badge>
  );
};
