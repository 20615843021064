import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCommunities = (params: Communities.GetListParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getCommunities, params],
    () => communitiesClient.getCommunities({
      ...params,
    }),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    communities: data?.results || [],
  };
};
