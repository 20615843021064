import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { AuthContext } from '@modules/Auth/contexts';

import { cacheKeys } from '../config';
import { connectionsClient } from '../client/connectionClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useConnections = (params: Connections.GetListParams = {}, options: Partial<Options> = defaultOptions) => {

  const { isLoggedIn } = React.useContext(AuthContext);

  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.getConnections, params],
    () => connectionsClient.getConnections({ ...params, limit: 5000 }),
    {
      enabled: isLoggedIn && options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    connections: data?.results || [],
  };
};
