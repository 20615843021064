import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { contactsClient } from '../client/contactClient';

export type Params = {
  id: number;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useContact = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: contact } = {}, status, error } = useQuery(
    [cacheKeys.getContact, params],
    () => contactsClient.getContact(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    contact,
  };
};
