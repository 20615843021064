import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  Stack,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';


import { isNative } from '@shared/helpers/environment';


type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    onSuccess: () => void;
  }
}

export const ConfimDeletionDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const [value, setValue] = React.useState('');

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">Are you sure?</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography mb={2}>
          This action <Typography component="span" fontWeight={500}>CANNOT</Typography> be undone. This will permanently delete your profile, trips, contacts and network connections.
        </Typography>
        <Box mb={2}>
          <InputLabel shrink={false} sx={{ fontWeight: 500 }}>Please type <strong>DELETE</strong> to confirm</InputLabel>
          <TextField  value={value} onChange={e => setValue(e.target.value)} />
        </Box>
        <Button
          fullWidth
          variant="contained"
          color="error"
          size="large"
          disabled={value.toLocaleLowerCase() !== 'delete'}
          onClick={() => {
            data?.onSuccess();
            onClose();
          }}
        >
          I understand, delete my account
        </Button>
      </DialogContent>
    </SwipeableDrawer>
  );
};
