import React from 'react';
import { useDebounce } from 'react-use';
import { Box, DialogContent, DialogTitle, Divider, Drawer, IconButton, InputAdornment, InputLabel, List, ListItemButton, Stack, TextField, TextFieldProps, Typography, Avatar } from '@mui/material';
import { Close, Delete, Search } from '@mui/icons-material';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { ParticipantsList } from './ParticipantsList';
import { ContactRow, ParticipantRowSkeleton } from './ParticipantRow';

export type Props<T extends Contacts.Contact> = Omit<TextFieldProps, 'value' |'onChange'> & {
  contacts: T[];
  onChange: (contacts: T[]) => void;
  defaultContacts?: T[];
  filters?: Contacts.GetListParams;
  disableActions?: boolean;
};

export const ParticipantsField = <T extends Contacts.Contact> ({ contacts = [], onChange, defaultContacts = [], label, filters, disableActions, ...props }: Props<T>) => {
  const dictionary = useDictionary();

  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [search, setSearch] = React.useState('');
  const { location, ...restFilters } = filters || {};
  
  useDebounce(
    () => setSearch(inputValue),
    400,
    [inputValue],
  );

  const chosenContacts = React.useMemo(() => {
    return contacts.filter(contact => !defaultContacts.some(it => it.id === contact.id));
  }, [contacts, defaultContacts]);

  const toggleContact = React.useCallback((contact: Contacts.Contact, selected: boolean) => {
    let list: T[] = [];

    if (selected) {
      if (!contacts.some(member => member.id === contact.id)) {
        list = [...contacts, { ...contact } as unknown as T];
      }
    } else {
      list = contacts.filter(member => member.id !== contact.id);
    }
    onChange(list);
  }, [contacts, onChange]);

  React.useEffect(() => {
    const selectedCount = contacts.length;
    if (selectedCount === 0) {
      setOpen(false);
    }
  }, [contacts]);

  return (
    <React.Fragment>
      <InputLabel>{label}</InputLabel>
      <TextField
        {...props}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        autoComplete="off"
        helperText="Select contacts that match your destination, or search for others you know will be there."
        InputProps={{
          ...props?.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              {chosenContacts.length > 0 && (
                <IconButton size="small" onClick={() => setOpen(true)}>
                  <Avatar sx={{ width: 30, height: 30, bgcolor: 'secondary.main', fontSize: 'medium' }}>
                    {chosenContacts.length}
                  </Avatar>
                </IconButton>
              )}
              <Search color="inherit" sx={{ ml: 0.5 }} />
            </InputAdornment>
          ),
        }}
      />
      <Box minHeight={80} pt={1}>
        <ParticipantsList
          renderContact={contact => (
            <ContactRow
              contact={contact}
              selected={contacts.some(member => member.id === contact.id)}
              onChange={selected => toggleContact(contact, selected)}
            />
          )}
          renderSkeleton={() => <ParticipantRowSkeleton />}
          filters={{
            search,
            ...restFilters,
            location: search ? undefined : location,
          }}
          slotProps={{
            list: {
              disablePadding: true,
            },
            listItem: {
              disableGutters: true,
            },
          }}
        />
      </Box>
      <Drawer
        keepMounted={false}
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: theme => ({
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            maxWidth: theme.breakpoints.values.sm,
            maxHeight: '43vh',
            margin: 'auto',
          }),
        }}
      >
        <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">{dictionary.communities.create.selectedMembersTitle}</Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            <Close/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box pb="env(safe-area-inset-bottom)">
            <List disablePadding>
              {chosenContacts.map((contact) => (
                <React.Fragment key={contact.id}>
                  <ListItemButton disableGutters onClick={() => toggleContact(contact, false)}>
                    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body1" component="span">{contact.displayName}</Typography>
                      </Stack>
                      <Delete />
                    </Stack>
                  </ListItemButton>
                  <Divider component="li"/>
                </React.Fragment>
              ))}
            </List>
          </Box>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
};
