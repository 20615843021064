import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';

import { mapChatData } from './messagingClient.formatter';

const messagingApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getChat = (params: { id: number | string }): Promise<AxiosResponse<Messaging.Chat>> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<Messaging.ChatApi>) => ({
    ...data,
    data: mapChatData(data.data),
  }));
};

const getChats = (params: Messaging.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Messaging.Chat>>> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<Messaging.ChatApi[]>) => ({
    ...data,
    data: {
      count: data.data.length,
      results: data.data.map(mapChatData),
      next: null,
      previous: null,
    },
  }));
};

const createChat = (data: Messaging.Create): Promise<AxiosResponse<Messaging.Chat>> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/`,
      method: 'POST',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Messaging.ChatApi>) => ({
    ...data,
    data: mapChatData(data.data),
  }));
};

const editChat = ({ id, ...data }: Messaging.Edit): Promise<AxiosResponse<Messaging.Chat>> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/${data}/`,
      method: 'PUT',
      data,
    },
  }).then((data: AxiosResponse<Messaging.ChatApi>) => ({
    ...data,
    data: mapChatData(data.data),
  }));
};

const deleteChat = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const archiveChat = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/${params.id}/archive/`,
      method: 'POST',
    },
  });
};

const unarchiveChat = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${messagingApiBaseUrl}/messaging/${params.id}/unarchive/`,
      method: 'POST',
    },
  });
};

export const messagingClient = {
  getChat,
  getChats,
  createChat,
  editChat,
  deleteChat,
  archiveChat,
  unarchiveChat,
};
