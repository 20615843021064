import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { tripsClient } from '../client/tripClient';

export type Params = {
  id: number;
};

type Options = {
  enabled: boolean
  retry: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useTrip = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: trip } = {}, status, error } = useQuery(
    [cacheKeys.getTrip, params],
    () => tripsClient.getTrip(params),
    options,
  );

  return {
    status,
    error,
    trip,
  };
};
