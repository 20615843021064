import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { cleanObject, snakizeObject } from '@cvt/helpers/object';

import { mapContactCrudData, mapContactData } from './contactClient.formatter';

const contactsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getContact = (params: { id: number | string }): Promise<AxiosResponse<Contacts.Contact>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/${params.id}/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<Contacts.ContactApi>) => ({
    ...data,
    data: mapContactData(data.data),
  }));
};

const getContacts = (params: Contacts.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Contacts.Contact>>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Contacts.ContactApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapContactData),
    },
  }));
};

const createContact = (data: Contacts.Create): Promise<AxiosResponse<Contacts.Contact>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/`,
      method: 'POST',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Contacts.ContactApi>) => ({
    ...data,
    data: mapContactData(data.data),
  }));
};

const bulkCreateContact = (data: Contacts.Create[]): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/bulk_create/`,
      method: 'POST',
      data: data.map(contact => snakizeObject(cleanObject(contact))),
    },
  });
};

const editContact = (data: Contacts.Edit): Promise<AxiosResponse<Contacts.Contact>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/${data.id}/`,
      method: 'PUT',
      data: mapContactCrudData(data),
    },
  }).then((data: AxiosResponse<Contacts.ContactApi>) => ({
    ...data,
    data: mapContactData(data.data),
  }));
};

const deleteContact = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const bulkInviteContact = (): Promise<AxiosResponse<void>> => {
  return request({
    options: {
      url: `${contactsApiBaseUrl}/contacts/bulk-invite/`,
      method: 'POST',
    },
  });
};

export const contactsClient = {
  getContact,
  getContacts,
  createContact,
  bulkCreateContact,
  editContact,
  deleteContact,
  bulkInviteContact,
};
