import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  MenuList,
  MenuItem,
} from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import { DialogContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { IconButtonDropdown, IconButtonDropdownProps } from '@cvt/components/ButtonDropdown';

import { cacheKeys } from '../config';
import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { CommunityContext } from '../contexts/CommunityContext';

interface Props extends Communities.Channels.Channel {
  IconButtonProps?: Omit<IconButtonDropdownProps, 'children' | 'content'>;
  amIApprovedMember: boolean;
}

export const CommunityChannelActions: React.FC<Props> = ({ IconButtonProps, amIApprovedMember,  ...channel }) => {
  const router = useRouter();
  const dictionary = useDictionary();
  const queryClient = useQueryClient();

  const { community, amIAdmin } = React.useContext(CommunityContext);
  
  const { asyncConfirmation, openDialog } = React.useContext(DialogContext);

  const { deleteChannel, leaveChannel, toggleChannelNotifications, editChannel } = useCommunityCrud();

  const onSuccessEditEvent = React.useCallback(() => {
    if (community.handle) {
      queryClient.invalidateQueries([cacheKeys.getChannel, community.handle, channel.id.toString()]);
    }
    queryClient.invalidateQueries([cacheKeys.getChannel, community.id.toString(), channel.id.toString()]);
  }, [channel, community, queryClient]);

  const onDelete = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.communities.channels.edit.deleteConfirmation });
    
    if (!channel || !userConfirmed) {
      return false;
    }

    return deleteChannel({ id: channel.id, communityId: community.id.toString(), handle: community.handle }).then(() => router.communities.view(community.handle || community.id).go());
  }, [asyncConfirmation, channel, community.id, community.handle, deleteChannel, dictionary, router.communities]);

  const onLeave = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.communities.channels.edit.leaveConfirmation, content: ' ' });

    if (!channel || !userConfirmed) {
      return false;
    }

    return leaveChannel({ channelId: channel.id, communityId: community.id.toString(), handle: community.handle });
  }, [asyncConfirmation, channel, community, dictionary, leaveChannel]);

  const onCreateEvent = React.useCallback(async () => {
    openDialog('createEvent', { onClose: (event: Events.Event) => {
      editChannel({
        ...channel,
        communityId: community.id.toString(),
        event: event.id,
        handle: community.handle,
      });
    } });
  }, [channel, community, editChannel, openDialog]);

  const onEditEvent = React.useCallback(async () => {
    openDialog('editEvent', { event: channel.event, onClose: onSuccessEditEvent });
  }, [channel, onSuccessEditEvent, openDialog]);

  const isEventCreated = React.useMemo(() => !!channel.event?.id,[channel]);

  return (
    <IconButtonDropdown
      placement="bottom-end"
      {...IconButtonProps}
      buttonProps={{
        size: 'small',
        color: 'secondary',
        ...IconButtonProps?.buttonProps,
      }}
      content={({ closeDropdown }) => (
        <MenuList>
          <MenuItem
            key="toggleNotif"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              closeDropdown();
              toggleChannelNotifications({ channelId: channel.id, communityId: community.id.toString(), handle: community.handle });
            }}
          >
            {channel.muted ? dictionary.communities.channels.edit.unmuteChannel : dictionary.communities.channels.edit.muteChannel }
          </MenuItem>
          {amIAdmin && (
            <MenuItem
              key="edit"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeDropdown();
                openDialog('inviteCommunityChannelMembers', { communityId: community.id, channelId: channel.id, replace: true });
              }}
            >
              {dictionary.communities.channels.edit.invite}
            </MenuItem>
          )}
          {amIAdmin && (
            <MenuItem
              key="edit"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeDropdown();
                openDialog('editCommunityChannel', { communityId: community.id, channelId: channel.id, replace: true });
              }}
            >
              {dictionary.communities.channels.edit.edit}
            </MenuItem>
          )}
          {amIAdmin && (
            <MenuItem
              key="delete"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onDelete();
              }}
            >
              {dictionary.communities.channels.edit.delete}
            </MenuItem>
          )}
          {amIApprovedMember && !amIAdmin && (
            <MenuItem
              key="leave"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onLeave();
              }}
            >
              {dictionary.communities.channels.edit.leave}
            </MenuItem>
          )}
          {!isEventCreated && amIAdmin && (
            <MenuItem
              key="create event"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeDropdown();
                onCreateEvent();
              }}
            >
              {dictionary.communities.channels.edit.createEvent}
            </MenuItem>
          )}
          {isEventCreated && amIAdmin && (
            <MenuItem
              key="create event"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                closeDropdown();
                onEditEvent();
              }}
            >
              {dictionary.communities.channels.edit.editEvent}
            </MenuItem>
          )}
        </MenuList>
      )}
    >
      <MoreHoriz />
    </IconButtonDropdown>
  );
};