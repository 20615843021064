import { Theme } from '@mui/material';

const Card = (theme: Theme) => {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
};

export default Card;
