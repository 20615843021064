import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { locationClient } from '../clients/locationClient';

export type Params = {
  id: number | string;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useLocation = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: location } = {}, status, error } = useQuery(
    [cacheKeys.getLocation, params],
    () => locationClient.getLocation(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    location,
  };
};
