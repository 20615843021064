import { Theme } from '@mui/material';

const Switch = (theme: Theme) => {
  return {
    MuiSwitch: {
      variants: [{
        props: { color: 'secondary' },
        style: {
          '& .Mui-checked .MuiSwitch-thumb': {},
          '& .Mui-checked + .MuiSwitch-track': {},
        },
      }],
    },
  };
};

export default Switch;
