import React from 'react';
import { Divider, List, ListItem, ListItemProps, ListProps, Stack } from '@mui/material';

import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';

import { useSearchUsers } from '@modules/Users/hooks/useSearchUsers';


export interface Props {
  renderUser: (user: Users.User) => React.ReactNode;
  renderSkeleton?: () => React.ReactNode;
  filters?: Users.GetListParams & Contacts.GetListParams & {
    network?: string | string[];
    exclude?: number[];
  };
  slotProps?: {
    list?: ListProps;
    listItem?: ListItemProps;
  },
  showDivider?: boolean;
}

export const ParticipantsList: React.FC<Props> = ({ filters = {}, renderUser, renderSkeleton, slotProps, showDivider = false }) => {

  const { network, exclude = [], ...restFilters } = filters;

  const { users, status: usersStatus } = useSearchUsers(restFilters);


  const filteredUsers = React.useMemo(() => {
    return users
      .filter(user => !exclude.includes(user.id))
      .filter(user => {
        if (network) {
          if (user.connection) {
            return network?.includes(user.connection.type);
          }
          return network?.includes('public');
        }
        return true;
      });
  }, [users, network, exclude]);

  if ([usersStatus].includes('loading') || !users) {
    if (renderSkeleton) {
      return (
        <React.Fragment>
          <List {...slotProps?.list}>
            {Array.from({ length: 3 }).map((v, idx) => (
              <React.Fragment key={idx}>
                {showDivider && (<Divider component="li"/>)}
                <ListItem {...slotProps?.listItem}>{renderSkeleton()}</ListItem>
              </React.Fragment>
            ))}
          </List>
        </React.Fragment>
      );
    }

    return <BodyLoading height="100%"/>;
  }

  return (
    <React.Fragment>
      {filteredUsers.length === 0 ? (
        <MessageFeedbackView height="100%" message="No users" />
      ) : (
        <Stack spacing={1}>
          <List {...slotProps?.list}>
            {filteredUsers.map(user => (
              <React.Fragment key={user.id}>
                {showDivider && (<Divider component="li"/>)}
                <ListItem {...slotProps?.listItem}>{renderUser(user)}</ListItem>
              </React.Fragment>
            ))}
          </List>
        </Stack>
      )}
    </React.Fragment>
  );
};
