import React from 'react';
import QRCode from 'react-qr-code';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';


import { isNative } from '@shared/helpers/environment';
import { copyToClipboard } from '@shared/helpers/navigator';
import { FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';



type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    path: string;
    onSuccess: () => void;
  }
}

export const UserQrCodeDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const { triggerFeedback } = React.useContext(FeedbackContext);
  const dictionary = useDictionary();

  const handleCopyToClipboard = async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
    
    onClose();
  };

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      sx={theme => ({
        zIndex: theme.zIndex.modal + 1,
      })}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
          backgroundImage: 'none',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">Scan QR Code</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack mb={2} spacing={3} direction="column" justifyContent="center" alignItems="center">
          <QRCode
            value={import.meta.env.VITE__CVT_REACT_APP_BASE_URL + data?.path}
          />
          <Button color="secondary" size="small" onClick={() => handleCopyToClipboard(import.meta.env.VITE__CVT_REACT_APP_BASE_URL + data?.path, dictionary.users.profile.clipboardSaved('Link'))}>
            Copy to clipboard
          </Button>
        </Stack>
      </DialogContent>
    </SwipeableDrawer>
  );
};
