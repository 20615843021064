import { Theme } from '@mui/material';

import { isNative } from '@shared/helpers/environment';

const AppBar = (theme: Theme) => {
  return {
    MuiAppBar: {
      styleOverrides: {
        root: {
          paddingTop: isNative() ? 'env(safe-area-inset-top)' : 0,
        },
        colorPrimary: {
          backgroundColor: theme.palette.background.default,
        },
      },
    },
  };
};

export default AppBar;
