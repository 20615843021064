import React from 'react';
import noop from 'lodash/noop';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';
import { useRouter } from '@cvt/hooks/useRouter';

import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { CommunityForm, Props as CommunityFormProps } from './CommunityForm';

export interface Props {
  community: Communities.Community;
  onSuccess?: (community: Communities.Community) => void;
  hiddenFields?: CommunityFormProps['hiddenFields'];
}
export const EditCommunity: React.FC<Props> = ({ community, onSuccess = noop, hiddenFields = [] }) => {
  const { dictionary } = React.useContext(LocalizationContext);
  const { editCommunity } = useCommunityCrud();
  const router = useRouter();

  const onSubmit = React.useCallback(async (data: Communities.Crud) => {
    try {
      const response = await editCommunity({
        id: community.id,
        ...data,
      });

      // If Handle changed - change community details page URL
      if (community.handle !== data.handle) {
        router.communities.view(data.handle).details.go();
      }

      onSuccess(response.data);

      return true;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [community, editCommunity, onSuccess, router]);

  return (
    <CommunityForm
      defaultValues={community}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.edit.buttonEdit}
      hiddenFields={hiddenFields}
    />
  );
};
