import React from 'react';

import { UserContext } from '@modules/Users/contexts';

interface Props {
  community: Omit<Communities.Community, 'users'> & {
    users: Communities.ExtendedCommunityUser[];
  };
  amIMember: boolean;
  amIAdmin: boolean;
  amIOwner: boolean;
  amIInvited: boolean;
  amIPendingAcceptanceMember: boolean;
}

export const defaultContext: Props = {
  community: undefined as unknown as Props['community'],
  amIMember: false,
  amIAdmin: false,
  amIOwner: false,
  amIInvited: false,
  amIPendingAcceptanceMember: false,
};

export const CommunityContext = React.createContext(defaultContext);

export const CommunityProvider: React.FC<React.PropsWithChildren<Props['community']>> = ({ children, ...community }) => {

  const { user: me } = React.useContext(UserContext);

  const members = React.useMemo(() => {
    return community.users.filter(user => user.status === 'active');
  }, [community.users]);

  const amIMember = React.useMemo(() => {
    const user = members.find(user => user.id === me?.id);
    if (user) {
      return true;
    }
    return false;
  }, [me, members]);

  const amIAdmin = React.useMemo(() => {
    const user = members.find(user => user.id === me?.id);
    if (user) {
      return user.type.includes('admin');
    }
    return false;
  }, [me, members]);

  const amIOwner = React.useMemo(() => {
    const user = members.find(user => user.id === me?.id);
    if (user) {
      return user.isOwner;
    }
    return false;
  }, [me, members]);

  const amIInvited = React.useMemo(() => {
    const user = community.users.find(user => user.id === me?.id);
    if (user) {
      return user.status === 'invited';
    }
    return false;
  }, [me, community.users]);

  const amIPendingAcceptanceMember = React.useMemo(() => {
    const user = community.users.find(user => user.id === me?.id);

    return user?.status === 'pending_acceptance';
  }, [community.users, me?.id]);

  return (
    <CommunityContext.Provider
      value={{
        community,
        amIMember,
        amIAdmin,
        amIOwner,
        amIInvited,
        amIPendingAcceptanceMember,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export const CommunityConsumer = CommunityContext.Consumer;
