export const cacheKeys = {
  getCommunity: 'getCommunity',
  getCommunities: 'getCommunities',
  createCommunity: 'createCommunity',
  editCommunity: 'editCommunity',
  deleteCommunity: 'deleteCommunity',
  joinCommunity: 'joinCommunity',
  getCommunityJoinRequests: 'getCommunityJoinRequests',
  leaveCommunity: 'leaveCommunity',
  getMembers: 'getMembers',
  inviteCommunityMember: 'inviteCommunityMember',
  removeCommunityMember: 'removeCommunityMember',
  getCommunityInvitation: 'getCommunityInvitation',
  getChannels: 'getChannels',
  getChannel: 'getChannel',
  joinChannel: 'joinChannel',
  leaveChannel: 'leaveChannel',
  createChannel: 'createChannel',
  deleteChannel: 'deleteChannel',
  promoteCommunityMemberToAdmin: 'promoteCommunityMemberToAdmin',
  demoteCommunityMemberFromAdmin: 'demoteCommunityMemberFromAdmin',
  editChannel: 'editChannel',
  acceptChannelMemberRequest: 'acceptChannelMemberRequest',
  refuseChannelMemberRequest: 'refuseChannelMemberRequest',
  removeChannelMember: 'removeChannelMember',
  approveCommunityJoinRequest: 'approveCommunityJoinRequest',
  refuseCommunityJoinRequest: 'refuseCommunityJoinRequest',
  inviteChannelMember: 'inviteChannelMember',
  toggleChannelNotifications: 'toggleChannelNotifications',
};
