import React from 'react';
import noop from 'lodash/noop';

import { useDictionary } from '@cvt/hooks/useDictionary';

import { trackEvent } from '@shared/tracking';
import { useEventCrud } from '../hooks/useEventCrud';
import { EventForm } from './EventForm';

export interface Props {
  onSuccess?: (event: Events.Event) => void;
  validateFields?: Partial<{
    name: (name: string) => string | boolean;
  }>;
}

export const CreateEvent: React.FC<Props> = ({ onSuccess = noop, validateFields }) => {
  const dictionary = useDictionary();

  const { createEvent } = useEventCrud();

  const onSubmit = React.useCallback(async (event: Events.Crud) => {
    trackEvent('event-creation');

    return createEvent({
      ...event,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [createEvent, onSuccess]);

  return (
    <EventForm
      validateFields={validateFields}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.events.create.buttonCreate}
    />
  );
};
