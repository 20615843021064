import React from 'react';
import noop from 'lodash/noop';
import {
  Box,
  SwipeableDrawer,
} from '@mui/material';

import { ViewCommunityChannel as ViewCommunityChannelView } from '@modules/Communities/views/ViewCommunityChannel';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    communityId: string;
    channelId: string;
    onComplete?: () => void;
  }
}

export const EventDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const _onClose = React.useCallback(() => {
    onClose();
    data?.onComplete && data.onComplete();
  }, [onClose, data]);

  if (!data?.communityId || !data.channelId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={_onClose}
      PaperProps={{
        sx: theme => ({
          height: '100%',
          pb: 'env(safe-area-inset-bottom)',
          maxHeight: 'calc(100% - env(safe-area-inset-top))',
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <Box
        position="absolute"
        top={8}
        left="50%"
        width={40}
        height={3}
        borderRadius={1}
        bgcolor="common.white"
        sx={{
          transform: 'translate(-50%)',
        }}
      />
      <ViewCommunityChannelView hideNavigationBack={true} communityId={data.communityId} id={data.channelId} />
    </SwipeableDrawer>
  );
};
