import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { FeedbackContext } from '@cvt/contexts';

import { cacheKeys } from '../config';
import { communitiesClient } from '../client/communitiesClient';

export const useChannelMemberCrud = () => {
  const { genericErrorFeedback } = React.useContext(FeedbackContext);
  const queryClient = useQueryClient();

  const acceptChannelMemberRequest = useMutation(communitiesClient.acceptChannelMemberRequest, {
    mutationKey: [cacheKeys.acceptChannelMemberRequest],
    onSuccess: (_, { communityId, channelId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, handle, channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, communityId, channelId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const refuseChannelMemberRequest = useMutation(communitiesClient.refuseChannelMemberRequest, {
    mutationKey: [cacheKeys.refuseChannelMemberRequest],
    onSuccess: (_, { communityId, channelId, handle }) => {
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, handle, channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, communityId, channelId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const removeChannelMember = useMutation(communitiesClient.removeChannelMember, {
    mutationKey: [cacheKeys.removeChannelMember],
    onSuccess: (_, { communityId, channelId, handle }) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, handle, channelId]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, communityId, channelId]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const inviteChannelMember = useMutation(communitiesClient.inviteChannelMember, {
    mutationKey: [cacheKeys.inviteChannelMember],
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries([cacheKeys.getChannels]);
      if (variables.handle) {
        queryClient.invalidateQueries([cacheKeys.getChannel, variables.handle, variables.channelId]);
        queryClient.invalidateQueries([cacheKeys.getMembers, {
          communityId: variables.handle,
        }]);
      }
      queryClient.invalidateQueries([cacheKeys.getChannel, variables.communityId, variables.channelId]);
      queryClient.invalidateQueries([cacheKeys.getMembers, {
        communityId: variables.communityId,
      }]);
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  return {
    acceptChannelMemberRequest: acceptChannelMemberRequest.mutateAsync,
    refuseChannelMemberRequest: refuseChannelMemberRequest.mutateAsync,
    removeChannelMember: removeChannelMember.mutateAsync,
    inviteChannelMember: inviteChannelMember.mutateAsync,
  };
};
