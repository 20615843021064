import React from 'react';
import { useBoolean } from 'react-use';

import { PermissionContext } from '@cvt/contexts';

import { UserContext } from '@modules/Users/contexts';
import { useMobileContactsImport } from '@modules/Contacts/hooks/useMobileContactsImport';

import { ContextProps } from './types';

export const defaultContext: ContextProps = {
  importContacts: () => Promise.resolve(undefined),
};


export const ContactsContext = React.createContext(defaultContext);

export const ContactsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {

  const { user } = React.useContext(UserContext);
  const { permissions } = React.useContext(PermissionContext);
  const { importContacts } = useMobileContactsImport();
  const [triggered, toggle] = useBoolean(false);

  React.useEffect(() => {
    // Only run in background if logged in, has permission and already completed onboarding
    if (!triggered && !!user?.id && user?.onboarded && permissions.contacts.import) {
      toggle(true);
      importContacts(false);
    }
  }, [importContacts, permissions.contacts.import, user, triggered, toggle]);

  return (
    <ContactsContext.Provider
      value={{
        importContacts,
      }}
    >
      {children}
    </ContactsContext.Provider>
  );
};

export const ContactsConsumer = ContactsContext.Consumer;
