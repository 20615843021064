import { format, getMonth, getYear } from 'date-fns';

export const formatTripDate = (date: Date, showMonth: boolean = true, showYear: boolean = false) => {
  if (showYear) {
    return format(date, 'MMM dd, yyy');
  }

  if (showMonth) {
    return format(date, 'MMM dd');
  }
  
  return format(date, 'dd');
};

export const formatTripDates = (arrival: Date, departure: Date) => {
  const today = new Date();
  const arrivalMonth = getMonth(arrival);
  const departureMonth = getMonth(departure);
  const currYear = getYear(today);
  const arrivalYear = getYear(arrival);
  const departureYear = getYear(departure);

  return `${formatTripDate(arrival, true, arrivalYear !== departureYear)} - ${formatTripDate(departure, arrivalMonth !== departureMonth, currYear !== departureYear)}`;
};