export const NOTIFICATION_TYPE = {
  NewConnection: 'new_connection',
  ConnectionAccepted: 'connection_accepted',
  NewConfirmedTrip: 'new_confirmed_trip',
  ConfirmedTrip: 'confirmed_trip',
  NewPlannedTrip: 'new_planned_trip',
  PlannedTrip: 'planned_trip',
  EditedTrip: 'edited_trip',
  NewImportedTrip: 'new_imported_trip',
  ContactJoined: 'contact_joined',
  NewCommunityInvitation: 'new_community_invitation',
  ConnectionBackInTown: 'connection_back_in_town',
  ContactConnected: 'contact_connected',
  PrivateChannelJoinRequest: 'private_channel_join_request',
  PrivateChannelRequestApproved: 'private_channel_request_approved',
  CommunityJoinRequest: 'community_join_request',
  CommunityJoinRequestApproved: 'community_join_request_approved',
  CommunityJoinRequestDenied: 'community_join_request_denied',
  AddedToChannel: 'added_to_channel',
  PeerInLocationSoon: 'peer_in_location_soon',
} as const;

export const getProximityAlertList = (notifications: Notifications.Notification[]) => {
  return notifications.filter((notification) =>
    notification.type === NOTIFICATION_TYPE.PeerInLocationSoon ||
    notification.type === NOTIFICATION_TYPE.ConnectionBackInTown ||
    notification.type === NOTIFICATION_TYPE.NewConfirmedTrip,
  );
};