import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { isIOS, isAndroid } from 'react-device-detect';
import noop from 'lodash/noop';
import {
  Avatar,
  Button,
  DialogContent,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';

import { isBeta, isNative } from '@shared/helpers/environment';
import { getBrowserLogo } from '@shared/helpers/browser';
import { getMobileStoreUrl } from '@shared/helpers/mobile';

import AppIcon from  '@shared/assets/AppIcon.png';


type Props = {
  open: boolean;
  onClose: () => void;
}

const openNativeUrl = async () => {
  const androidAppPath = `app://app.thr-app.com:/${document.location.pathname}`;
  const iosAppPath = `com.there.app:/${document.location.pathname}`;

  if (isIOS) {
    window.location.href = iosAppPath;
  }
  if (isAndroid) {
    window.location.href = androidAppPath;
  }

  setTimeout(() => {
    const href = getMobileStoreUrl();
    window.location.href = href;
  }, 2500); // Adjust delay as needed
};

export const MobileAppDialog: React.FC<Props> = ({ open, onClose }) => {

  const router = useRouter();
  const location = useLocation();
  const dictionary = useDictionary();

  const browserLogo = getBrowserLogo();

  const shouldHideDialog = React.useMemo(() => {
    if (isBeta) {
      return true;
    }
    const paths = [
      router.invitation.path,
      router.integrations.path,
      router.invitation.invite().path,
      router.integrations.success().path,
      router.invitation.community().path,
      router.communities.view().channel().auth.path,
    ];

    return paths.some(path => matchPath(path, location.pathname));
  }, [router, location]);

  if (shouldHideDialog) {
    return null;
  }

  return (
    <Drawer
      disableEscapeKeyDown
      keepMounted={false}
      anchor="bottom"
      open={open}
      onClose={noop}
      sx={theme => ({
        zIndex: theme.zIndex.modal + 1,
      })}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="center">
        <Typography variant="h1">{dictionary.dialogs.nativePrompt.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <List disablePadding>
          <ListItem
            disableGutters
            disablePadding
            secondaryAction={
              <Button sx={{ minWidth: 90 }} onClick={openNativeUrl}>
                {dictionary.dialogs.nativePrompt.buttonOpen}
              </Button>
            }
          >
            <ListItemAvatar>
              <Avatar variant="rounded" src={AppIcon} sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.100', width: 48, height: 48 }} />
            </ListItemAvatar>
            <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>{dictionary.dialogs.nativePrompt.app}</ListItemText>
          </ListItem>
          <ListItem
            disableGutters
            secondaryAction={
              <Button sx={{ minWidth: 90 }} variant="contained" color="secondary" onClick={onClose}>
                {dictionary.dialogs.nativePrompt.buttonContinue}
              </Button>
            }
          >
            <ListItemAvatar>
              <Avatar variant="rounded" src={browserLogo} sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.100', width: 48, height: 48, padding: 1, backgroundColor: 'common.white' }} />
            </ListItemAvatar>
            <ListItemText primaryTypographyProps={{ fontWeight: 500 }}>{dictionary.dialogs.nativePrompt.browser}</ListItemText>
          </ListItem>
        </List>
      </DialogContent>
    </Drawer>
  );
};