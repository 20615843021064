import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
} from '@mui/material';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    src: string;
  }
}

export const ViewPictureDialog: React.FC<Props> = ({ open, onClose, data }) => {

  return (
    <Dialog
      keepMounted={false}
      transitionDuration={0}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          borderRadius: '50%',
          backgroundColor: 'transparent',
          maxWidth: theme.breakpoints.values.sm,
          margin: 2,
        }),
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: 'blur(3px)',
          },
        },
      }}
      hideBackdrop={false}
    >
      <DialogContent sx={{ padding: 0 }}>
        <Box display="flex" justifyContent="center" borderRadius="50%" overflow="hidden" sx={{ width: 500, maxWidth: '100%', aspectRatio: '1/1' }}>
          <img
            src={data?.src}
            style={{
              objectFit: 'cover',
              height: '100%',
              width: '100%',
            }}
            alt="Profile"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
