import { Theme } from '@mui/material';

const Dialog = (theme: Theme) => {
  return {
    MuiDialog: {
      defaultProps: {
        hideBackdrop: true,
        PaperProps: {
          sx: {
            backgroundImage: 'none',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'none',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        },
      },
    },
  };
};

export default Dialog;
