import React, { PropsWithChildren } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';

import { useRouter } from '@cvt/hooks/useRouter';
import { DialogContext } from '@cvt/contexts';

import { UserContext } from '@modules/Users/contexts';
import { useContacts } from '@modules/Contacts/hooks/useContacts';

import { SwipableCardStack } from '../Home/components/SwipableCardStack';
import { SwipableCard } from '../Home/components/SwipableCard';
import { ContactLargeCard } from './ContactLargeCard';

interface Props {
}

export const SwipableContacts: React.FC<PropsWithChildren<Props>> = () => {
  const { user: me } = React.useContext(UserContext);
  const { openDialog } = React.useContext(DialogContext);

  const [count, setCount] = React.useState(0);

  const router = useRouter();

  const { contacts, status } = useContacts({
    user: me?.id,
    limit: 10000,
    ordering: 'first_name',
  });


  if (status !== 'success') {
    return null;
  }
  
  return (
    <Container maxWidth="sm" sx={{ height: '100%', overflow: 'hidden' }}>
      <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
        <Stack height="100%" spacing={2}>
          <Box textAlign="center">
            <Typography variant="h1">Contacts</Typography>
            <Typography variant="body1">{count} left</Typography>
          </Box>
          <SwipableCardStack setCount={setCount} onFinish={() => router.dashboard.users.recent.go()}>
            {contacts.map(contact => (
              <SwipableCard
                key={contact.id}
                onClick={() => openDialog('editContact', { contactId: contact.id })}
              >
                <ContactLargeCard contact={contact}/>
              </SwipableCard>
            ))}
          </SwipableCardStack>
        </Stack>
      </Box>
    </Container>
  );
};
