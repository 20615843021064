import React from 'react';

import { useQueryState } from '@cvt/hooks/useQueryState';
import { addQuery } from '@cvt/helpers/query';

const useNetworkSearchParams = () => {
  const [network] = useQueryState<string | string[]>('network');

  return React.useMemo(() => {
    const search = addQuery('', { network });

    return search ? `?${search}` : '';
  }, [network]);
};

export default useNetworkSearchParams;
