import { Theme } from '@mui/material';

const Form = (theme: Theme) => {
  return {
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiToggleButton-secondary': {
            border: '1px solid rgba(255, 255, 255, 0.23)',
          },
        },
      },
    },
  };
};

export default Form;
