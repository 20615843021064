import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';

export const ViewContact: React.FC<Contacts.Contact> = contact => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          {contact.firstName && (
            <Stack>
              <Typography variant="body2" fontWeight={300} color="secondary.light">First name</Typography>
              <Typography variant="body1" fontWeight={500}>{contact.firstName}</Typography>
            </Stack>
          )}
          {contact.lastName && (
            <Stack>
              <Typography variant="body2" fontWeight={300} color="secondary.light">Last name</Typography>
              <Typography variant="body1" fontWeight={500}>{contact.lastName}</Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          {contact.email && (
            <Stack>
              <Typography variant="body2" fontWeight={300} color="secondary.light">Email</Typography>
              <Typography variant="body1" fontWeight={500}>{contact.email}</Typography>
            </Stack>
          )}
          {(contact.cel || contact.tel) && (
            <Stack>
              <Typography variant="body2" fontWeight={300} color="secondary.light">Phone</Typography>
              <Typography variant="body1" fontWeight={500}>{contact.cel || contact.tel}</Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between">
          {contact.location && (
            <Stack>
              <Typography variant="body2" fontWeight={300} color="secondary.light">Location</Typography>
              <Typography variant="body1" fontWeight={500}>{contact.location.title}</Typography>
            </Stack>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
