import React from 'react';
import { useMutation } from '@tanstack/react-query';

import { DialogContext, FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';

import { contactsClient } from '../client/contactClient';
import { cacheKeys } from '../config';
import { useContactMessage } from '../hooks/useContactMessage';
import { useContactCrud } from './useContactCrud';

export const useContactInvitation = () => {

  const dictionary = useDictionary();
  const { asyncConfirmation } = React.useContext(DialogContext);
  const { triggerFeedback, genericErrorFeedback } = React.useContext(FeedbackContext);
  const { editContact } = useContactCrud();
  const { messageContactBySms } = useContactMessage();
  
  const inviteContactsByBulk = useMutation(contactsClient.bulkInviteContact, {
    mutationKey: [cacheKeys.bulkInviteContact],
    onSuccess: () => {
      triggerFeedback({
        severity: 'success',
        message: dictionary.feedback.changesSaved,
      });
    },
    onError: () => {
      genericErrorFeedback();
    },
  });

  const inviteContactBySms = React.useCallback(async (contact: Contacts.Contact) => {
    const userConfirmed = await asyncConfirmation({
      title: dictionary.contacts.invite.inviteConfirmation(contact.displayName),
      content: dictionary.contacts.invite.inviteConfirmationMessage,
      confirmLabel: dictionary.contacts.invite.inviteMessage,
    });

    if (!userConfirmed) {
      return false;
    }

    await messageContactBySms(contact, `I'm using Thr. and added you to my network. It shows me when I overlap with my friends in a city I'm traveling to so that we can meet up. You should check it out too - ${import.meta.env.VITE__CVT_REACT_APP_BASE_URL}/invitation/${contact.invitationUuid}`);

    editContact({
      ...contact,
      invitedAt: new Date(),
    });

    triggerFeedback({
      severity: 'success',
      message: dictionary.feedback.changesSaved,
    });
  }, [asyncConfirmation, dictionary, editContact, messageContactBySms, triggerFeedback]);

  return {
    inviteContactBySms,
    inviteContactsByBulk: inviteContactsByBulk.mutateAsync,
  };
};
