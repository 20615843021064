import React, { FC, useMemo } from 'react';
import { Avatar, Badge, Box, FormHelperText } from '@mui/material';
import { Image, Edit } from '@mui/icons-material';

import { imageMimes } from '@cvt/helpers/file';
import { FileUploader } from '@cvt/components/FileUploader';

export interface Props {
  label: string;
  value?: string | File;
  disabled?: boolean;
  onChange: (file: File) => void;
  error?: boolean;
  helperText?: string;
  defaultValue?: string;
}

export const PictureUploader: FC<Props> = React.forwardRef(({ value, defaultValue, label, disabled, onChange, error, helperText }, ref: React.Ref<HTMLInputElement>) => {
  const photoUrl = useMemo(() => {
    if (!value) {
      return defaultValue;
    }

    return typeof value === 'string' ? value : URL.createObjectURL(value);
  }, [value, defaultValue]);

  return (
    <FileUploader
      {...ref}
      name="button-picture"
      label={label}
      disabled={disabled}
      inputProps={{
        accept: [...imageMimes].join(','),
        multiple: false,
      }}
      buttonRender={() => (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={!disabled ? (
              <Avatar
                sx={theme => ({
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                  border: `2px solid ${theme.palette.background.paper}`,
                  padding: '3px',
                })}
              >
                <Edit style={{ width: '100%', height: '100%' }}/>
              </Avatar>
            ) : null}
          >
            <Avatar
              alt="Profile picture"
              src={photoUrl}
              sx={{
                width: 130,
                height: 130,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: error ? 'error.main' : 'transparent',
                cursor: disabled ? 'default': 'pointer',
                '&:hover': {
                  borderColor: disabled ? 'transparent' : 'grey.500',
                },
              }}
            >
              <Image/>
            </Avatar>
          </Badge>
          {helperText && (
            <FormHelperText error={error}>{helperText}</FormHelperText>
          )}
        </Box>
      )}
      onChange={files => onChange(files[0])}
    />
  );
});