
import { isIOS, isAndroid } from 'react-device-detect';

export const IOS_STORE_URL = import.meta.env.VITE__CVT_APP_APPLE_STORE_URL;
export const PLAY_STORE_URL = import.meta.env.VITE__CVT_APP_GOOGLE_PLAY_URL;

export const getMobileStoreUrl = () => {
  if (isIOS) {
    return IOS_STORE_URL;
  }
  if (isAndroid) {
    return PLAY_STORE_URL;
  }
  return IOS_STORE_URL;
};
