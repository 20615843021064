import React from 'react';
import {
  Typography,
  Box,
  BoxProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@mui/material';

import { blue } from '@shared/theme/palette';
import { EmailTwoTone, PhoneTwoTone, SmartphoneTwoTone } from '@mui/icons-material';

interface Props extends BoxProps {
  contact: Contacts.Contact;
}

export const ContactLargeCard: React.FC<Props> = ({ contact, ...props }) => {

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      borderRadius={4}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: blue,
        overflow: 'hidden',
      }}
      {...props}
    >
      {contact.image && (
        <Avatar src={contact.image} sx={{ width: 90, height: 90 }} />
      )}
      <Box width="100%" p={2} textAlign="center">
        <Typography variant="h1" fontWeight={600}>
          {contact.displayName}
        </Typography>
        {contact.location && (
          <Typography variant="body1" fontWeight={500}>
            {contact.location.title}
          </Typography>
        )}
      </Box>
      <Box width="100%" bgcolor="secondary.main">
        <List>
          {contact.cel && (
            <ListItemButton>
              <ListItemIcon>
                <SmartphoneTwoTone />
              </ListItemIcon>
              <ListItemText
                primary={contact.cel}
                secondary="Mobile Phone"
              />
            </ListItemButton>
          )}
          {contact.tel && (
            <ListItemButton>
              <ListItemIcon>
                <PhoneTwoTone />
              </ListItemIcon>
              <ListItemText
                primary={contact.tel}
                secondary="Phone"
              />
            </ListItemButton>
          )}
          {contact.email && (
            <ListItemButton>
              <ListItemIcon>
                <EmailTwoTone />
              </ListItemIcon>
              <ListItemText
                primary={contact.email}
                secondary="Email"
              />
            </ListItemButton>
          )}
        </List>
      </Box>
    </Box>
  );
};

export const ContactLargeCardSkeleton: React.FC = () => {
  return null;
};