import React from 'react';
import noop from 'lodash/noop';

import { LocalizationContext } from '@cvt/contexts';

import { useContactCrud } from '../hooks/useContactCrud';
import { ContactForm } from './ContactForm';

export interface Props {
  onSuccess?: (contact: Contacts.Contact) => void;
}
export const CreateContact: React.FC<Props> = ({ onSuccess = noop }) => {

  const { dictionary } = React.useContext(LocalizationContext);

  const { createContact } = useContactCrud();

  const onSubmit = React.useCallback((data: Contacts.Crud) => {
    return createContact(data).then(data => {
      onSuccess(data.data);
    });
  }, [createContact, onSuccess]);

  return (
    <ContactForm
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.contacts.create.buttonCreate}
    />
  );
};
