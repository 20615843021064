import React from 'react';
import noop from 'lodash/noop';
import * as Sentry from '@sentry/react';

import { LocalizationContext } from '@cvt/contexts';

import { useChannelMemberCrud } from '../hooks/useChannelMemberCrud';
import { CommunityMembersForm, FormCrud as CommunityMembersFormData } from './CommunityMembersForm';

export interface Props {
  community: Omit<Communities.Community, 'users'> & {
    users: Communities.ExtendedCommunityUser[];
  };
  channel: Communities.Channels.Channel;
  onSuccess?: () => void;
}
export const InviteCommunityChannelMembers: React.FC<Props> = ({ community, channel, onSuccess = noop }) => {
  const { dictionary } = React.useContext(LocalizationContext);

  const { inviteChannelMember } = useChannelMemberCrud();

  const onSubmit = React.useCallback(async ({ users }: CommunityMembersFormData) => {
    try {

      const promises: Promise<unknown>[] = [];

      users.forEach(user => {
        const isAlreadyInChannel = channel.users.some(it => it.id === user.id);

        if (!isAlreadyInChannel) {
          promises.push(inviteChannelMember({
            userId: user.id,
            channelId: channel.id,
            handle: community.handle,
            communityId: community.id.toString(),
          }));
        }
      });

      await Promise.all(promises);

      onSuccess();
      return true;
    } catch(err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [onSuccess, channel, inviteChannelMember, community]);

  return (
    <CommunityMembersForm
      defaultValues={{
        users: channel.users,
      }}
      fieldProps={{
        users: {
          filters: {
            communityId: community.id.toString(),
          },
        },
      }}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.communities.members.invite.button}
    />
  );
};
