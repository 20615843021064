import { AxiosResponse } from 'axios';

import { request } from '@cvt/clients/baseClient';
import { snakizeObject } from '@cvt/helpers/object';
import { mapNotificationCrudData, mapNotificationData } from './notifications.formatter';

const notificationsApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getNotifications = (params: Notifications.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Notifications.Notification>>> => {
  return request({
    options: {
      url: `${notificationsApiBaseUrl}/notifications/`,
      method: 'GET',
      params: snakizeObject(params),
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Notifications.NotificationApi>>) => ({
    ...data,
    data: {
      ...data.data,
      // Right now we hide notifications based on read at being assingned.
      results: data.data.results.map(mapNotificationData),
    },
  }));
};

const editNotification = (data: Notifications.Edit): Promise<AxiosResponse<Notifications.Notification>> => {
  return request({
    options: {
      url: `${notificationsApiBaseUrl}/notifications/${data.id}/`,
      method: 'PUT',
      data: mapNotificationCrudData(data),
    },
  }).then((data: AxiosResponse<Notifications.NotificationApi>) => ({
    ...data,
    data: mapNotificationData(data.data),
  }));
};

const deleteNotification = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${notificationsApiBaseUrl}/notifications/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const clearNotifications = (): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${notificationsApiBaseUrl}/notifications/clear-all/`,
      method: 'POST',
    },
  });
};

export const notificationsClient = {
  getNotifications,
  editNotification,
  deleteNotification,
  clearNotifications,
};
